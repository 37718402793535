const en = {
  ngon_ngu_tieng_viet: "Vietnamese",
  ngon_ngu_tieng_anh: "English",

  // validate
  validate_required: "${label} is required",
  validate_type_email: "${label} is an invalid email",
  validate_type_number: "${label} is an invalid number",
  validate_number_range: "${label} is not between ${min} and ${max}",
  validate_type_pattern: "${label} is invalid",
  validate_number_max: "${label} maximum charater is ${max}",

  common_ngay: "Day",
  common_thang: "Month",
  common_nam: "Year",
  common_tuan: "Week",
  data_Tiep_tan: "Reception",
  data_Nguoi_lay_mau: "Sampler",

  menu_Bang_dieu_khien: "Dasdboard",
  menu_quan_ly_he_thong: "menu_quan_ly_he_thong",
  //menu_quan_ly_he_thong: "menu_quan_ly_he_thong",
  menu_Quan_ly_tiep_don: "Reception Manager",
  menu_Quan_ly_he_thong: "System Management",
  menu_Quan_ly_danh_muc: "Categories Management",
  menu_Quan_ly_nguoi_dung: "User Management",
  menu_Thong_tin_cskcb: "Hospital Information",
  menu_Quan_ly_vien_phi: "Hospital Fee Management",
  menu_Quan_ly_kham_benh: "Examination Management",
  menu_Quan_ly_chan_doan_hinh_anh_ris: "Management of diagnostic imaging (RIS/PACS)",
  menu_Quan_ly_bhyt: "Health Insurance ManagementT",
  menu_Quan_ly_duoc_ngoai_tru: "Pharmacy Management",
  menu_Kho_thuoc: "Medicine Storage",
  menu_Kho_duoc_ngoai_tru: "Warehouse",
  menu_Quan_ly_xep_hang_qms: "Queue Management QMS",
  menu_Quan_ly_dich_vu_can_thanh_toan: "Must-paid Service",
  menu_Quan_ly_so_hoa_don: "Invoice Book",
  menu_Quan_ly_xet_nghiem_lis: "LIS Test Management",
  menu_Lich_lam_viec: "Schedule",
  menu_Quan_ly_benh_nhan: "Patient Management",
  menu_quan_ly_phong_kham_ve_tinh:"Satellite clinic management",
  menu_Bao_cao: "Report",
  menu_Phieu_xuat_nhap: "Import/export ticket",
  app_loi_ket_noi: "Connection error!!!",
  menu_Ho_tro_khach_hang: "Customer support",
  menu_Ban_thuoc: "Drug sale",
  nhom_nguoi_dung:"Users Group",

  // Danh sách danh mục (quản lý danh mục)
  cate_DM: "Category",
  cate_Danh_muc: "Category",
  cate_benh_vien: "hospital",
  cate_khoa: "department",
  cate_phong: "room",
  cate_dich_vu_ki_thuat: "technical services",
  cate_nhom_dv_ki_thuat: "group technical service",
  cate_loai_dv_ki_thuat: "type of technical service",
  cate_nhom_hoa_don: "invoice group",
  cate_thuoc: "drug",
  cate_nha_thuoc: "drugstore",
  cate_hang_san_xuat: "manufacturer",
  cate_duong_dung: "route of use",
  cate_kho_tu_truc: "warehouse",
  cate_hoat_chat: "active ingredients",
  cate_tinh: "province",
  cate_huyen: "district",
  cate_xa: "wards",
  cate_nghe_nghiep: "job",
  cate_dan_toc: "ethnic",
  cate_quoc_gia: "nation",
  cate_icd10: "ICD-10",
  cate_nhom_icd10: "group ICD-10",
  cate_don_vi_tinh: "calculation unit",
  cate_so_hoa_don: "Receipt list",
  cate_giuong: "bed",
  cate_dv_giuong: "service bed",
  cate_cach_thuc_PTTT: "surgical procedure",
  cate_phuong_phap_PTTT: "surgical methods",
  cate_pp_vo_cam: "emotionless method",
  cate_xuat_khac: "other import",
  cate_nguon: "source",
  cate_kho_thuoc: "warhouse drug",
  cate_doi_tuong: "object",
  cate_nguon_khach: "client resource",
  cate_cach_dung: "use",
  cate_ql_pk_ve_tinh:"satellite clinic manager",
  title_ql_pk_ve_tinh:"Satellite clinic manager",
  phong_kham_ve_tinh:"Satellite clinic",
  // Danh sách trường thông tin (bao gồm: tên cột của table, label của form)
  field_ma_doi_tuong: "Object code",
  field_ma_nhom_doi_tuong: "Object group code",
  field_Ma_cskcb: "Code hospital",
  field_Ten_benh_vien: "Hospital name",
  field_Tuyen: "Line",
  field_Hang: "Rate",
  field_Dia_chi: "Address",
  field_Ma_khoa: "Faculty code",
  field_Ten_khoa: "Faculty name",
  field_Ma_khoa_byt: "Ministry of Health's Faculty Code",
  field_Truong_khoa: "Dean",
  field_Loai_khoa: "Faculty type",
  field_Ma_phong: "Room code",
  field_Ten_phong: "Room name",
  field_Khoa: "Department",
  field_Loai_phong: "Type of room",
  field_Phong_chi_dinh: "Designated room",
  field_Stt: "Number",
  field_Ma_dich_vu: "Service code",
  field_Ma_dich_vu_full: "Service code",
  field_Loai_chi_so: 'Index type',
  field_Ten_dich_vu: "Service name",
  field_Ten_dich_vu_theo_byt: "Ministry of Health's Service Code",
  field_Don_vi_tinh: "Unit",
  field_Thong_tin_co_so: "Hospital info",
  field_Thong_tin_co_ban: "Basic informations",
  field_Ten_cskcb: "Hospital name",
  field_Tinh_thanh: "Province/City",
  field_Quan_huyen: "District",
  field_Xa_phuong: "Ward",
  field_Dia_chi_chi_tiet: "Detailed address",
  field_Ghi_chu: "Note",
  field_Hien_thi: "Lock",
  field_Thao_tac: "Action",
  field_Nhom_dich_vu: "Group service",
  field_Loai_dich_vu: "Type of service",
  field_Nhom_bhyt: "Group health insurrance",
  field_nhom_hoa_don: "Invoice group",
  field_Gia: "Price",
  field_Gia_thu_phi: "Toll price",
  field_Gia_bhyt: "Insurrance price",
  field_Gia_dich_vu: "Requirement price",
  field_Gia_nuoc_ngoai: "Foreign price",
  field_Thong_tin_khac: "Other information",
  field_Ti_le_dung_tuyen: "Right line rate",
  field_Ti_le_trai_tuyen: "Off-line rate",
  field_So_ngay_tai_chi_dinh: "Number of days of reassignment",
  field_Phong_thuc_hien: "Implementation room",
  field_Phong_lay_mau: "Sampling room",
  field_chon_loai_thu_thuat: "Procedure type",
  field_Chi_so_xet_nghiem: "Test Index",
  field_Don_vi_chi_so: "Index unit",
  field_Giai_ket_qua_chi_so: "Solve the index result",
  field_Cho_phep_mien_giam: "Allowances for exemptions",
  field_Cho_sua_gia_khi_chi_dinh: "For price correction when specifying",
  field_Ma_nhom: "Group code",
  field_Ten_nhom: "Group name",
  field_Id_loai_dv_ki_thuat: "ID type of technical service",
  field_Nhom_dv_ki_thuat: "Group technical service",
  field_Ten_loai_dv_ki_thuat: "Technical service type name",
  field_Id_nhom: "ID group",
  field_Ma_hang_san_xuat: "Manufacturer code",
  field_Ten_hang_san_xuat: "Manufacturer's name",
  field_Ma_duong_dung: "Route code",
  field_Ma_duong_dung_theo_byt: "Ministry of Health's Usage code",
  field_ten_duong_dung: "Route name",
  field_Ma_kho: "Warehouse code",
  field_Ten_kho: "Warehouse name",
  field_Thu_kho: "Stocker",
  field_Loai_kho: "Warehouse type",
  field_Kieu_kho: " Warehouse type",
  field_Ma_tinh: "Province code",
  field_Ma_tinh_byt: "Ministry of Health's Province Code",
  field_Ten_tinh: "Name of province",
  field_Ten_Tinh_thanh: "Province",
  field_Id_huyen: "District code",
  field_Ma_huyen_byt: "Ministry of Health's District Code",
  field_Ten_huyen: "District name",
  field_Ten_Quan_huyen: "District",
  field_Ma_xa: "Ward code",
  field_Ma_xa_byt: "Ministry of Health's Ward Code",
  field_Ten_xa: "Ward name",
  field_Ten_Xa_phuong: "Ward",
  field_Ma_nghe_nghiep: "Occupation code",
  field_Ten_nghe_nghiep: "Occupation name",
  field_Ma_dan_toc: "Ethnic Code",
  field_Ten_dan_toc: "Ethnic name",
  field_Ma_icd: "Code ICD",
  field_Ten_icd: "Name ICD",
  field_Nhom_icd: "Group ICD",
  field_Loai_icd: "Type ICD",
  field_Ma_nhom_icd: "Group code ICD",
  field_Ten_nhom_icd: "Gruop name ICD",
  field_Ma_quoc_gia: "Country code",
  field_Ten_quoc_gia: "Country name",
  field_Ten_goi_khac: "Other name",
  field_Ten_viet_tat: "Abbreviations",
  field_Benh_nhan: "Patients",
  field_Dich_vu_su_dung: "Service use",
  field_Thanh_toan: "Payment",
  field_Da_TT: "Paid",
  field_Trang_thai: "Status",
  field_Thong_tin_hanh_chinh: "Administrative information",
  field_Tim_kiem_benh_nhan: "Patient search",
  field_Tim_kiem_thuoc: "Medicine search",
  field_Doi_tuong_benh_nhan: "Patient object",
  field_Ten_doi_tuong_nhom_nguoi_dung: 'User object group name',
  field_Uu_tien: "Prioritize",
  field_Ma_benh_nhan: "Patient ID",
  field_Ma_BN: "Patient ID",
  field_Ten_benh_nhan: "Patient name",
  field_So_dien_thoai: "Phone",
  field_Email: "Email",
  field_Gioi_tinh: "Gender",
  field_Ngay_sinh: "Date of birth",
  field_THX: "P-D-W",
  field_Nghe_nghiep: "Job",
  field_Dan_toc: "Ethnic",
  field_Quoc_gia: "Nation",
  field_Thong_tin_bhyt: "Health insurance information",
  field_TE_co_the_bhyt: "TE has a health insurance card",
  field_The_bhyt: "Health insurance card",
  field_Han_the_tu: "Card expiry from",
  field_Han_the_den: "Card expiry from to",
  field_Ngay_mien_cung_chi_tra: "Free day",
  field_Ngay_dong_han_du_5_nam: "Closing date is full 5 years",
  field_Noi_song: "Habitat",
  field_Thong_tin_kham: "Examination information",
  field_Yeu_cau_kham: "Request an examination",
  field_Chon_phong_kham: "Choose a clinic",
  field_Ma_cccd: "Citizen identification",
  field_So_phong: "Room number",
  field_Benh_nhan_cho_kham: "Patient waiting for examination",
  field_In_phieu_kham: "Print examination card",
  field_doi_tuong_BHYT: "Medical Insurance",
  field_doi_tuong_yeu_cau: "Require",
  field_doi_tuong_vien_phi: "Hospital Fee",
  field_doi_tuong_nuoc_ngoai: "Foreign",
  field_doi_tuong_mien_giam: "Exemptions",
  field_ton_kho: "Stock",
  field_gia_ban: "Selling price",
  field_gia_nhap: "Import price",
  field_tinh_nang: "Feature",
  field_ma_ly_do: "Reason ID",
  field_ly_do: "Reason",
  field_ma_nguon: "Source ID",
  field_ma_nguon_khach: "Client resource",
  field_ten_nguon_khach: "Client resource name",
  field_nhom_dt_nguoi_dung: "User object group",
  field_nhom_dt_nguoi_dung_full: "User object group",
  field_ten_nguon: "Source name",
  field_so_dang_ky_thuoc: "Drug registration number",
  field_gio_bat_dau: "Start time",
  field_gio_ket_thuc: "End time",
  field_thoi_gian_hoat_dong: "Room's working time",
  field_thoi_gian: "Time",
  field_chua_cai_dat_thoi_gian: "Working time not configured",
  field_gia_vien_phi: "hospital_fees",
  ten_doi_tuong: "Object name",
  thu_tu: "Order",
  field_loai_doi_tuong: "Object type",
  field_nguon: "Source",
  // Từ khóa thông dụng (tên button, chức năng phổ biến, tiêu đề dùng nhiều, ...)
  common_trong: "of",
  common_Huy: "Cancel",
  common_Thoat: "Exit",
  common_Luu: "Save",
  common_Luu_va_In: "Save and Print",
  common_In: "Print",
  common_Nhap: "Enter",
  common_Chon: "Select",
  common_Bat: "On",
  common_Xoa: "Delete",
  common_huy_don: "Cancel",
  common_Chinh_sua: "Edit",
  common_Them_moi: "Add",
  common_Tim_kiem: "Search",
  common_Tim_kiem_theo_loai: "Search by type",
  common_Nhap_csv: "Import spreadsheet",
  common_Xuat_csv: "Export spreadsheet",
  common_Tat: "Off",
  common_Xem_truoc: "Preview",
  common_Tai_mau: "Download templates",
  common_Tiep_tuc: "Continue",
  common_Dang_kham: "Examining",
  common_Kham_xong: "Done",
  common_Dang_xuat: "Logout",
  common_Canh_bao: "Warning",
  common_Xuat_file: "Export file",
  common_ok: "Ok",
  common_bo_loc: "Filter",
  common_dong: "Close",
  common_tai_lai: "Reload",
  // Giới tính
  gioi_tinh_Nam: "Male",
  gioi_tinh_Nu: "Female",
  gioi_tinh_Khac: "Other",

  // Khác...
  noi_dung_keo_tha_upload_file: "Click or drop the file here!",

  // Loại khoa
  loai_khoa_kham_benh: "Physical exam",
  loai_khoa_noi_khoa: "Medical",
  loai_khoa_duoc: "Pharmacy",
  loai_khoa_tai_chinh: "Finance",
  loai_khoa_xet_nghiem: "Analysis",
  loai_khoa_cdha: "CDHA",
  loai_khoa_khac: "Other",

  // label
  label_Da_tiep_nhan: "Received",
  label_quan_ly_lich_hen: "Upcoming appointment",
  label_Tiep_don: "Reception ",
  label_Tiep_don_kham_chua_benh: "Patient admission",
  label_So_hoa_don: "Receipt package",
  label_them_so_hoa_don: "Add receipt package",
  label_sua_so_hoa_don: "Edit receipt package",
  label_thong_tin_chi_tiet: "Detail information",
  label_phieu_xuat_nhap: "Import/Export ticket",

  // thông tin cơ sở khám chữa bệnh
  thong_tin_cskcb: "Information about medical facilities",
  luu_thay_doi: "Save changes",
  field_ten_CSKCB: "Hospital name",
  field_don_vi_quan_ly: "Management unit",
  field_hang_benh_vien: "Hospital grade",
  field_giam_doc_benh_vien: "Hospital director",
  them_hinh_anh: "More photos",
  field_ten_nguoi_dung: "User name",
  field_phan_quyen: "Decentralization",
  txt_da_chon: "Select",
  field_ma_cc_hanh_nghe: "Code of practice certificate",
  field_thong_tin_cong_viec: "Job Information",
  field_thong_tin_cong_tac: "Work Information",
  field_hoc_ham: "Academic rank",
  field_chuc_danh: "Title",
  field_ho: "First name",
  field_ten_ten_dem: "Last name",
  field_mat_khau: "Password",
  field_ten_danh_nhap: "Username",
  title_them_moi_nguoi_dung: "Add user",
  title_thong_tin_tai_khoan: "Infomation account",
  title_chinh_sua_nguoi_dung: "Edit user",
  title_tong_gia: "Total price",
  button_nhap_BHYT: "Enter Insurance",
  button_kiem_tra_BHYT: "Check Insurance",
  field_dia_chi_the: "Address card",

  /// thuốc
  field_ma_thuoc: "Drug code",
  field_ten_thuoc: "Drug name",
  field_lo_thuoc: "Batch",
  field_ma_thuoc_bhyt: "Ministry of Health's Drug Code",
  field_stt_bhyt: "Order Number Insurance",
  field_Ten_thuoc_bhyt: "Insurance Drug Name",
  field_ten_hoat_chat: "Active Ingredient Name",
  field_don_vi_tinh: "Unit",
  field_nong_do: "Concentration",
  field_the_tich: "Volume",
  field_ham_luong: "Content",
  field_ma_hoat_chat: "Active Ingredient Code",
  field_nuoc_san_xuat: "Made in",
  field_hang_san_xuat: "Made by",
  field_quy_cach_dong_goi: "Packing Instruction",
  field_ATC_code: "ATC Code",
  field_thuoc_khang_sinh: "Medicine",
  field_che_pham_y_hoc_co_truyen: "Traditional Medicine Product",
  field_cho_phep_mien_giam: "Exemptions Allowed",
  field_thuoc_tan_duoc: "Is New Drug",
  field_thuoc_ke_don: "Is Prescribed Drug",
  field_vi_thuoc_y_hoc: "Is Traditional Medicine",
  field_thuc_pham_chuc_nang: "Is Functional Food",
  field_tam_dung_nhap_NCC: "Pause supplier import",
  field_ma_hoat_chat_bhyt: "Ministry of Health's Active Ingredient",
  field_ngay_het_han: "Expiration date",
  field_loai_thuoc: "Medicine type",
  field_nhom_thuoc: "Medicine group",
  field_tat_ca: "All",
  field_tat_ca_nhom: "All group",
  field_da_het_han: "Expired",
  field_chua_het_han: "Unexpired",
  field_ma_vach: "Barcode",
  field_nhom_chi_phi: "Cost group",
  field_chung_tu: "Document",
  field_phuong_thuc: "Method",
  field_don_gia: "Unit price",
  field_ton_cuoi: "Ending stocks",
  field_doi_tac: "Partner",
  field_so_lo: "Lot number",
  field_han_su_dung: "Expiration date",
  field_con_hang: "In stock",
  field_het_hang: "Out of stock",
  field_ten_nhom_thuoc: "Medicine group name",
  field_nhom_cha: "Parent group",
  field_nhom_cls: "subclinical group",

  field_ma_lo: "lot code",
  field_them_lo: "add lot",
  field_chi_tiet_phieu_nhap: "Import ticket details",
  field_chi_tiet_phieu_xuat: "Export ticket details",
  field_chi_tiet_phieu_ban_thuoc: "Drug sales slip details",
  field_Ten_nhom_thuoc: "Drug class name",
  field_tao_phieu_nhap: "Create import ticket",
  field_chon_phieu_mau: "choose form entry form",
  field_chon_loai_phieu_mau: " choose sample ticket",
  field_ten_khach_hang: "Customer name",
  field_nguoi_ban_thuoc: "Medicine seller",

  //sổ hóa đơn
  field_shd_loai_so: "Loại sổ",
  field_shd_ten_so: "Tên sổ",
  field_shd_ky_hieu: "Ký hiệu",
  field_shd_tong_so: "Tổng số phiếu",
  field_shd_ma_so: "Mã sổ",

  // LIS
  field_ma_thiet_bi: "Mã thiết bị",
  field_ten_thiet_bi: "Tên thiết bị",
  field_ghi_chu: "Ghi chú",

  // message errorr :
  nhap_dung_dinh_dang_email: "Please enter the correct email format",
  nhap_email: "Please enter your email",
  nhap_dung_dinh_dang_sdt: "Please enter the correct phone number format",
  nhap_sdt: "Please enter your phone",
  vui_long_nhap: "Please enter !",
  please_chon: "Please choose !",
  vui_long_chon: "Please choose a performance room!",
  vui_long_nhap_du_ky_tu: "Please enter correctly!",
  vui_long_nhap_dung_dinh_dang: "Please enter correctly!",
  error_trung_so_dien_thoai: "Same phone number",
  error_trung_so_user_name: "Same username",
  error_loi_ton_tai: "Data already exists!",
  error_loi_khong_nhap_gia_tri: "Data are still empty!",
  error_gia_tri_khong_hop_le: "Invalid data!",
  error_khong_trung_khop: "Data mismatch!",
  error_dich_vu_da_thanh_toan: "Can't cancel paid services",
  error_vien_phi_thieu_phieu: "Total count is lower than created receipt count ( at least ",
  error_thoi_gian_khong_hop_le: "Invalid time",

  cap_nhat_thanh_cong: "Update successful",
  cap_nhat_that_bai: "Update failed !",
  them_moi_thanh_cong: "Successfully added",
  them_moi_that_bai: "Add new failure !",
  thao_tac_thanh_cong: "Successful manipulation",
  thao_tac_that_bai: "Failed action !",

  common_Hom_nay: "Today",
  common_Hom_qua: "Yesterday",
  Cho_kham: "Waiting",
  CLS: "Subclinical",
  common_Hoan_thanh: "Complete",
  field_Tuoi: "Age",
  common_Ap_dung: "Apply",
  Thong_tin_benh_nhan: "Patient Information",
  Lich_su_kham_benh: "Medical History",
  Chi_dinh_CLS: "Subclinical Indications",
  Ke_don_thuoc_tu_nguyen: "Voluntary Prescription",
  Don_thuoc_bhyt: "Medical Insurance Prescription",
  field_Ngay_kham: "Examination Day",
  field_Chan_doan_benh_chinh: "Main Disease Diagnosis",
  xac_nhan_lich_hen: "Appointment confirmation",
  xac_nhan_tiep_don: "Reception confirmation",
  xac_nhan_va_in_phieu_kham: "Confirm and Print the examination card",
  field_Chan_doan_benh_phu_kem_theo: "Diagnosis of comorbidities",
  field_Bac_si: "Doctor",
  field_Bac_si_kham: "Examine Doctor",
  field_Khoa_dieu_tri: "Faculty treatment",
  Don_thuoc: "Prescription",
  Ket_qua_CLS: "Subclinical Result",
  field_CMND_CCCD: "Identity Card",

  field_Chi_so_sinh_ton: "Survival stats",
  field_Mach: "Blood vessel",
  field_Lan_phut: "(time/min)",
  field_Do_C: "(≈)",
  field_Nhiet_do: "Temp",
  field_Nhip_tho: "Breathing",
  field_Nhip_phut: "(breathing/min)",
  field_Huyet_ap: "Blood pressure",
  field_Ly_do_kham: "Exam reason",
  field_Qua_trinh_benh_ly: "Pathological process",
  field_Tien_su_ban_than: "Personal history",
  field_Tien_su_gia_dinh: "Family history",
  field_Kham_lam_sang: "Clinical examination",
  field_Ma_benh_chinh: "Main disease code",
  field_Ma_benh_kem_theo: "Accompanying disease code",
  field_Chan_doan_lam_sang: "Clinical diagnosis",
  field_Chan_doan_xac_dinh: "Implementing the quadrants",
  field_Cach_giai_quyet: "Solution",
  field_Ket_qua_dieu_tri: "Treatment results",
  field_Xu_tri: "To solve",
  field_Tai_kham: "Re-examination",
  field_Tai_kham_sau: "Re-examination after",
  Ket_thuc_cuoc_kham: "Finish examination",
  field_Chon_ngay_ap_dung: "Choose apply date",
  field_ngay_kham: "Date of examination",

  Ke_don_thuoc: "Prescribe Drugs",
  field_Thoi_gian_y_lenh: "Command time",
  field_Them_moi_thuoc: "New medicine",
  field_Kho_thuoc: "Drug store",
  field_Ten_thuoc: "Drug name",
  field_So_luong: "Quantity",
  field_Moi_ngay: "Per day",
  field_Chia_lam: "Divide into",
  field_Cach_dung: "Usage",
  field_Ma_thuoc: "Drug ID",
  field_Hoat_chat: "Active ingredient",
  field_Don_vi: "Unit",
  field_In_phieu: "Print",
  field_Lan: "Time(s)",
  field_Ma_don_thuoc: "Prescription ID",
  field_Kho_nhan_thuoc: "Drug receiving store",
  Ds_dich_vu_cls: "Subclinical service list",
  field_Dich_vu_chi_dinh: "Designated service",
  field_Ma_phieu_chi_dinh: "Designated code",
  field_Bac_si_chi_dinh: "Designated doctor",
  Chi_dinh_can_lam_sang: "Subclinical Indications",
  chi_tiet_phieu_chi_dinh_can_lam_sang: "Subclinical form detail",
  tao_phieu_chi_dinh_can_lam_sang: "Create subclinical form",
  danh_sach_dich_vu_da_chon: "List of selected services",
  field_Them_moi_can_lam_sang: "New subclinical",
  field_Chi_tiet_phieu_cls: "Subclinicals",
  txt_So_luong: "Quantity",
  txt_nhap_loi_dan: "Advice for patient",

  field_Ket_qua: "Result",

  field_KQKB_khoi_benh: "Cured",
  field_KQKB_do: "Better",
  field_KQKB_khong_thay_doi: "Unchanged",
  field_KQKB_nang: "Severe",
  field_KQKB_tu_vong: "Dead",

  field_cach_xu_tri_ra_vien: "Discharged",
  field_cach_xu_tri_chuyen_vien: "Referral",
  field_cach_xu_tri_tron_vien: "Escaped",
  field_cach_xu_tri_xin_ra_vien: "Request discharge",

  currency_viet_nam: "vnd",
  field_Tong_gia: "Total price",
  field_Tong_gia_tien: "Total price",
  error_Dich_vu_da_ton_tai: "Service(s) existed in current list!",
  fallback_Phong_thuc_hien_trong: "(No data)",
  status_Chua_thanh_toan: "Unpaid",
  status_Da_thanh_toan: "Paid",
  tip_trang_sau: "Previous",
  tip_trang_ke_tiep: "Next",
  status_Cho_ket_qua: "Waiting",
  status_Da_huy: "Canceled",
  status_Tat_ca: "All",
  status_Cho_xac_nhan: "Wait confirm",
  field_Tong_thanh_toan: "Total checkout",
  field_Da_TH: "Executed",
  // Viện phí

  vien_phi_da_tam_ung: "Has advanced",
  vien_phi_Tam_ung: "Advance",
  vien_phi_dich_vu_can_thanh_toan: "Services to pay for",
  vien_phi_thanh_tien: "Total",
  vien_phi_mien_giam_chi_phi: "Cost reduction",
  vien_phi_tong_chi_phi: "Total cost",
  vien_phi_mien_giam: "Exemptions",
  vien_phi_tong_thanh_toan: "Total",
  vien_phi_tong_tien_benh_nhan_dua: "Money given by patient",
  vien_phi_tien_tam_ung: "Prepayment",
  vien_phi_tien_thua: "Excess cash",
  vien_phi_so_hoa_don: "Invoice book",
  vien_phi_thanh_toan: "Payment ",
  vien_phi_Tao_tam_ung: "Create advance ",
  vien_phi_Hoan_ung: "Reimbursement",
  vien_phi_Tao: "Create",
  vien_phi_Don_gia: "Unit price",
  vien_phi_warning_khong_the_bo_chon_tat_ca: "Unable to unselect all",
  status_Dang_kham: "Examining",
  status_Hoan_thanh: "Completed",
  vien_phi_BN_phai_tra: "Patient must pay",
  vien_phi_tien_hoan_ung: "Reimbursement",
  vien_phi_Hoan_tien: "Refund",
  noti_vien_phi_ly_do_hoan_tien: "Please enter payback reason",
  noti_so_hoa_don: "Please select Invoice book",

  field_Yeu_cau_hoi_chan: "Request consultation",
  field_Thong_tin_nguoi_su_dung: "User Information",
  field_Chu_ky_so: "Digital Signatures",
  field_Anh_chu_ky_scan: "Image signature scan",
  field_Khoa_lam_viec: "Work Speciality",
  field_Phong_lam_viec: "Work Room",
  field_Ket_qua_tim_kiem: "Search Result",
  field_Kham_benh: "Medical Exam",
  field_Dich_vu: "Service",
  field_Lua_chon_dich_vu: "Select service",
  field_Nhom_chi_phi_bhyt: "Fee Group Insurance",
  field_Nhom_danh_muc: "Category Group",

  Ban_chua_chon_benh_nhan_nao:
    "You have not selected any patients. Please select one patient to start the medical examination.",

  data_Tuyen_trung_uong: "Central line",
  data_Tuyen_tinh: "Provincial line",
  data_Tuyen_huyen: "District line",
  data_Tuyen_xa: "Ward line",
  data_Khong_phan_tuyen: "Unknown line",
  data_Hang_dac_biet: "Special class",
  data_Hang: "Class",
  data_Khong_xep_hang: "Unknown Class",
  data_Kham_benh: "Medical Exam",
  data_Kham_cls: "Preclinical examination",
  data_Noi_khoa: "Medical",
  data_Duoc: "Drug",
  data_Tai_chinh: "Finance",
  data_Xet_nghiem: "Test",
  data_CDHA: "Image Diagnosis",
  data_Khac: "Other",
  data_Vien_phi: "Hospital Fee",
  data_Bhyt: "Health Insurance",
  data_Lay_mau: "Sample",
  data_Nha_thuoc: "Drug store",
  data_Kho_bhyt: "Insurance Storage",
  data_Ngay: "Day",
  data_Tuan: "Week",
  data_Thang: "Month",
  data_Nam: "Year",
  data_Kho_thuoc_tu_nguyen: "Voluntary Drug Storage",
  data_Kho_thuoc_bhyt: "Insurance Drug Storage",
  data_Kho_chan: "Even Storage",
  data_Kho_le: "Odd Storage",
  data_Tu_truc: "Drug Cabinet",
  data_Noi_tru: "Inpatient Treatment",
  data_Ngoai_tru: "Outpatient Treatment",
  data_Noi_tru_Ngoai_tru: "In & Outpatient Treatment",
  data_buong_dieu_tri: "Treatment",

  field_Nguoi_tiep_don: "Receptionist",
  data_Giao_su: "Professor",
  data_Pho_giao_su: "Associate Professor",
  data_Tien_si: "Doctor",
  data_Thac_si: "Master",
  data_Bs_chuyen_khoa: "Specialist",
  data_Truong_khoa: "Dean",
  data_Pho_khoa: "Vice Dean",
  data_Pho_giam_doc: "Vice President",
  data_Giam_doc: "President",
  data_Bac_si: "Doctor",
  data_Quyen: "Permission",
  cach_thuc_pttt: "How to PTTT",
  phuong_phap_pttt: "Method of PTTT",

  field_Ma_dvt: "Calculation unit code",
  field_Ten_dvt: "Calculation unit name",

  Cai_dat_llv: "Settings",
  Tuan_nay: "This week",
  data_Thu_2: "Monday",
  data_Thu_3: "Tuesday",
  data_Thu_4: "Wednesday",
  data_Thu_5: "Thursday",
  data_Thu_6: "Friday",
  data_Thu_7: "Saturday",
  data_Chu_nhat: "Sunday",
  field_Ca: "Shift",
  field_Thoi_gian_lam_viec: "Work Time",
  field_Tu: "From",
  field_Den: "To",
  field_Ngay_ap_dung: "Apply at",
  field_Thoi_gian_lam_viec_mac_dinh: "Default Work Time",
  field_Benh_nhan_tiep_theo: "Next Patient",
  field_So_benh_nhan_kham_mac_dinh: "Default number of patients examined per unit of time",
  field_Tai_phong_kham: "At clinic",
  field_phut: "mins",

  field_Ngay_tao: "Created at",
  field_Nguoi_tao: "Created by",
  field_Tong_chi_phi: "Total expense",
  field_Mien_giam: "Exemption",
  field_hinh_thuc_thanh_toan: "Payments type",
  In_hoa_don: "Print receipt",

  field_Ki_hieu: "Sign",
  field_Loai_so: "Book type",
  status_Moi: "New",
  status_Gan_day: "Almost Full",
  status_Da_day: "Full",
  field_So_phieu: "Votes",
  field_Ma_so: "Book code",
  field_Ten_so: "Book name",
  field_Tong_so_phieu: "Total votes",
  field_Nguoi_su_dung: "Users",
  field_So_bat_dau: "From number",
  field_So_ket_thuc: "To number",
  field_Phan_quyen_nguoi_su_dung_so: "Assign user permissions",

  noti_Chi_duoc_upload_anh: "Upload image only",
  noti_Kich_thuoc_anh_be_hon_5mb: "Image size must be smaller than 5MB",
  noti_Kich_thuoc_anh_be_hon_1mb: "Image size must be smaller than 1MB",
  noti_Chi_duoc_upload_excel: "Upload excel only",
  noti_Dang_nhap_thanh_cong: "Login successfully",
  noti_Dang_nhap_that_bai: "Login failed",
  noti_Xoa_thanh_cong: "Delete successfully",
  noti_Xoa_that_bai: "Delete failed",
  noti_Dang_xuat_danh_sach: "Exporting list...",
  noti_Xuat_danh_sach_thanh_cong: "Export successfully",
  noti_Xuat_danh_sach_that_bai: "Export failed",
  noti_Nhap_du_lieu_thanh_cong: "Import successfully",
  noti_Nhap_du_lieu_that_bai: "Import failed",
  noti_Xoa_danh_sach_thanh_cong: "Delete list successfully",
  noti_Xoa_danh_sach_that_bai: "Delete list failed",
  noti_Khoa_danh_sach_thanh_cong: "Update lock list successfully",
  noti_Khoa_danh_sach_that_bai: "Update lock list failed",
  noti_Chua_con_phong_thuc_hien: "The service room has not been selected or the service has no performance room",
  noti_Thuoc_da_ton_tai: "Drug already exists",
  noti_Chua_chon_thoi_gian_y_lenh: "Have not chosen the time of the order or haven't added the medicine yet",
  noti_Chua_chon_ngay_tai_kham: "Have not chosen a follow-up date yet",
  noti_Chua_co_thong_tin_kham: "No medical information yet",
  noti_Chua_chon_phong_thuc_hien: "The service room has not been selected or the service has no performance room",
  noti_Luu_lich_kham_thanh_cong: "Save exam schedule successfully",
  noti_Luu_lich_kham_that_bai: "Save exam schedule failed",
  noti_Chua_chon_benh_nhan_dv_thanh_toan: "Have not selected a patient or service to pay for",
  noti_Khong_the_huy_dich_vu_da_thanh_toan: "Can't cancel paid service",
  noti_Luu_cuoc_hen_thanh_cong: "Save appointment successfully",
  noti_Luu_cuoc_hen_that_bai: "Save appointment failed",
  noti_lay_danh_sach_lich_kham_that_bai: "Get Appoitment list failed",
  noti_vui_long_nhap_du_ngay_thang_nam: "Please enter the full date month year",
  noti_ten_dang_nhap_da_ton_tai: "Username have been exist",
  noti_ma_so_da_ton_tai: "Code have already exist",

  Dang_nhap: "Login",
  Dang_nhap_subtitle: "Login to continue using services \n of H247 Pro",
  field_Ten_dang_nhap: "Username",
  field_Nang_suat_kham: "Examination productivity",
  field_Danh_sach_thuoc: "Drug list",

  field_lich_lam_viec: "schedule",
  error_Chon_toi_thieu_1_ngay: "Please select at least one day",
  error_Chua_co_ca_lam_viec: "Please add at least one shift",

  field_Don_thuoc_tu_nguyen: "Voluntary prescription",
  field_Bac_si_ke_don: "Doctor prescribes",
  button_In_don_thuoc: "Print prescription",
  field_Loi_dan: "Advice",
  cf_Ban_co_chac_chan_xoa: "Are you sure you want to delete?",
  cf_Ban_co_chac_chan_khoa_nguon_khach: "Are you sure you want to lock the client source?",
  cf_xoa_nguon_khach: "After deleting, client source infomation cannot be restored. Do you still want to delete?",
  cf_Ban_co_chac_chan_khoa_nhom_doi_tuong_nguoi_dung: 'After locking user object group, the client resource that belong to user object group will be lock also. Do you still want to lock?',
  noti_Chua_chon_dich_vu_kham_benh: "You have not chosen any medical service yet",
  label_Tao_cuoc_hen_moi: "Patient Appointment",
  error_view_Chua_co_don_thuoc: "You do not have prescription. Please create a new prescription now!",
  error_view_Khong_co_don_thuoc: "No prescription",

  field_chinh_sua_phieu: "note edit",
  field_Doi_tuong: "Object type",

  field_Gio_kham: "Time",
  title_cuoc_hen_sap_toi: "Appointment comming",
  title_Tao_cuoc_hen: "Create Appointment",
  title_Chinh_sua_cuoc_hen: "Edit Appointment",
  title_Chinh_sua_thong_tin_tiep_don: "Edit appointment Infomation",
  title_Xac_nhan: "Confirm",
  title_Gia_dich_vu: "Service price",
  title_phong_khám: "Room",
  noti_Tiep_tuc_khi_chua_ke_don_thuoc: "Prescription has not been done yet. Are you sure you want to continue?",
  title_them_moi_thong_tin_ket_qua_xn_chuan:"New information about standard test results",
  title_ket_qua_xet_nghiem_chuan:"Standard test results",
  dm_ket_qua_xn_chuan: "Standard test results catalog",
  noti_Dang_tai_du_lieu: "Loading data ...",
  noti_Tai_du_lieu_thanh_cong: "Load data successfully",
  noti_Tai_du_lieu_that_bai: "Load data failed",
  noti_Dang_kiem_tra_trang_thai_don_thuoc: "Checking prescriptions status",
  noti_warning_thay_doi_thoi_gian_phong:
    "There's incomplete service in this room in the moment, the booked services will be kept even when working time of this room is changed ",
  noti_warning_thuoc: "",
  noti_warning_thuoc_co_trong_don: " has been listed in prescription. Please check again",
  noti_warning_thuoc_khong_du: " not enough in this medicine storage. Please check again",
  noti_Cuoc_hen_khong_phai_hom_nay:
    "This appointment does not take place today.\n Are you sure you want to confirm this appointment?",
  noti_Dang_Tai_template: "Downloading template...",
  noti_Tai_template_thanh_cong: "Download template successfully",
  noti_Tai_template_that_bai: "Download template failed",
  common_Doi_ngon_ngu: "Change language",
  field_Ma_cuoc_kham: "Exam ID",
  chi_tiet_cuoc_hen: "Appointment detail",
  thong_tin_cuoc_kham: "Appointment info",
  thong_tin_kham_benh: "Exam info",
  thong_tin: "Information",
  the_kho: "Stock card",
  lo_han_su_dung: "Expiry",
  title_them_moi_nhom_thuoc: "Add medicine group",
  title_chinh_sua_nhom_thuoc: "Edit medicine group",

  error_Rang_buoc_ton_tai: "You cannot delete this category. This category is related to other categories.",
  error_Rang_buoc_khoa_ton_tai: "You cannot lock this category. This category is related to other categories.",
  common_Xuat_hang_cho: "Export queue",
  title_Hang_cho_kham_benh: "Exam Queue",
  title_Hang_cho: "Queue",
  ten_ket_qua: "Result name",
  ten_excel: "Excel name",
  don_gia: "Unit price",
  don_vi: "Unit",
  so_lam_tron: "Round number",
  trung_binh_nam: "Male AVG",
  trung_binh_nu: "Female AVG",
  trung_binh_tre_em: "Kid AVG",
  thu_tu_in: "Print order",
  stt_excel: "Excel order",
  ghi_chu: "Note",
  error_Chua_chon_doi_tuong_benh_nhan: "You have not chosen patient object yet",
  error_Ngay_sinh_ko_hop_le: "Invalid date of birth",
  error_Chua_chon_thong_tin_luot_dieu_tri: "Please select full info of treatments",
  error_Khong_chon_ngay_khac_voi_ngay_truoc_do: "Do not select date that is different from the former date",

  field_Trang_thai_thanh_toan: "Payment status",
  field_Trang_thai_cuoc_hen: "Appointment status",
  title_Phieu_thu_da_tao: "Created receipt",
  error_Gia_tri_mien_giam_ko_hop_le: "Invalid exemptions",
  error_Khong_sua_duoc_lich_kham: "Schedule cannot be updated because appointments exist on this day",
  error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham:
    "Can't lock this room due to scheduled appointment booked in this room",

  tab_Phieu_mua_thuoc: "Drug sale paper",
  tab_Lich_su_phieu: "Card history",
  field_Ma_phieu: "Card ID",
  placeholder_Ma_tu_dong_tao: "Auto-generated code",
  placeholder_Lua_chon_don_thuoc_mau: "Select sample prescription",
  common_Thay_the: "Alternate",
  txt_Them_lo: "Add lot",
  txt_Chon_lo: "Select lot",
  noti_lay_chi_tiet_phieu_mau_that_bai: "get sample slip details failed",
  txt_tu_choi: "Denied",

  noti_confirm_Huy_dich_vu: "Are you sure you want to cancel service?",
  field_Ly_do_hoan_tien: "Refund reason",
  field_Tong_tien: "Total",
  warning_url_notfound_title: "Access warning",
  warning_url_notfound:
    "You are accessing the wrong page with your current account, please login again to continue using!",
  warning_change_password: "Password changed, please login again to continue using!",
  field_Ly_do_mien_giam: "Reason for exemption",

  error_body__at_column: "at column",
  error_body__and_duplicate_value: "and duplicate value",

  noti_chua_nhap_lo_thuoc: "you have not entered the drug batch",
  noti_so_luong_thuoc_toi_da: "quantity has reached the maximum",
  noti_lo_khong_con_thuoc: "quantity is out",
  noti_lo_da_ton_tai: "lot already exists",

  //nhà cung cấp
  da_chon: "Selected",
  nha_cung_cap: "Supplier",
  ma_nha_cung_cap: "Supplier ID",
  ten_nha_cung_cap: "Supplier Name",
  dien_thoai: "Mobile phone",
  tong_mua: "Total purchase",
  no_can_tra: "Debt",
  nhom_ncc: "Supplier group",
  tu: "From",
  toi: "To",
  no_hien_tai: "Current debt",
  tat_ca: "All",
  dang_hoat_dong: "Active",
  khong_hoat_dong: "Inactive",
  trang_thai: "Status",
  them_nha_cung_cap: "Add new suppiler",
  thong_tin_co_so: "Basic information",
  dia_chi: "Address",
  cong_ty: "Company",
  ma_so_thue: "Tax ID",
  tao_moi: "Save",
  huy: "Cancel",
  thong_tin_chi_tiet: "Details",
  thong_tin_camel: "INFORMATION",
  lich_su_nhap_tra_hang_camel: "IMPORT/RETURN HISTORY",
  quan_ly_cong_no_camel: "DEBT CONTROL",
  xoa: "Delete",
  luu: "Save",
  nhap_gia_tri: "Enter value",
  gia_tri: "Value",
  cong_no: "Debt",
  dieu_chinh: "Impale",
  xuat_file_cong_no: "Export debt file",
  in_file_cong_no: "Print debt file",
  no_can_tra_hien_tai: "Current liabilities",
  cai_dat_ngay_gio: "Set date and time",
  ngay_dieu_chinh: "Adjustment date",
  gia_tri_no_dieu_chinh: "Adjusted debt value",
  mo_ta: "Description",
  cap_nhat: "Update",
  tien_mat: "Cash",
  chuyen_khoan: "Bank transfer",
  no_sau: "Postpaid debt",
  tao_phieu_chi: "Create payment voucher",
  tao_phieu_chi_va_in: "Create payment voucher & print",
  tra_cho_ncc: "Pay the supplier",
  loai_phieu: "Voucher type",
  hay_chon_ngay: "Please select date",
  txt_luu_phieu_mau: "save sample form",
  txt_ten_phieu_mau: "sample sheet name",
  txt_tien_tra_ncc: "Supplier payment",
  txt_tinh_vao_cong_no: "Counted in debt",
  title_phieu_xuat_ban: "Drug sales slip",
  title_phieu_xuat_tra: "supplier payment slip",
  title_phieu_xuat_huy: "Cancellation slip",
  txt_tong_gia_tri_huy: "Total cancellation value",
  txt_tien_ncc_tra: "Vendor pay",
  txt_ncc_phai_tra: "Supplier must pay",
  da_nhap_hang: "Imported",
  da_tra_hang: "Paid",
  hay_chon_nhom: "Please select group",
  hay_dien_ma: "Please fill in",
  hay_dien_ten: "Please fill in",
  hay_nhap_so_dien_thoai: "Please fill in",
  hay_dien_email: "Please fill in",
  hay_dien_dia_chi: "Please fill in",
  hay_dien_ten_cong_ty: "Please fill in",
  nhap_ten: "Enter name",
  nhap_dia_chi: "Enter address",
  noti_them_thuoc_that_bai: "add new medicine failed",
  /// BẢNG ĐIỀU KHIỂN
  doanh_thu_trung_binh: "Average revenue",
  doanh_thu_trung_binh_moi_cuoc_kham: "Average revenue per visit",
  cuoc_kham: "Appointments",
  trung_binh_cuoc_kham: "Average examination",
  so_voi_ky_truoc: "compared to the previous period",
  tuoi: "age",
  so_benh_nhan_trong_ky: "Number of patients in the period",
  so_benh_nhan_ky_truoc: "Number of patients in the previous period",
  so_luong_benh_nhan_theo_thoi_gian: "Number of patients over time",
  benh_nhan: "Patients",
  tong_quan_benh_nhan: "Patient overview",
  ti_le_benh_nhan_theo_gioi_tinh: "Percentage of patients by sex",
  ti_le_benh_nhan_theo_do_tuoi: "Percentage of patients by age",
  don_vi_trieu_dong: "millions",

  tong_quan_dich_vu: "Total service revenue",
  tong_so_cuoc_kham: "Total number of visits",
  tong_so_benh_nhan: "Total number of patients",
  tong_quan_kinh_doanh: "Business overview",
  tong_quan_tiep_don_kham_chua_benh: "Overview of medical reception",

  field_Gia_nhap: "Import price",
  field_Gia_ban: "Export price",

  noti_tao_moi_benh_nhan_thanh_cong: "Create new patient successfully",
  noti_tao_moi_benh_nhan_that_bai: "Create new patient failed",
  noti_them_vao_hang_cho_thanh_cong: "Add to the drug sale queue successfully",
  noti_them_vao_hang_cho_that_bai: "Add to the drug sale queue failed",
  confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham:
    "This medical examination is currently done. Are you sure you want to continue examing?",
  confirm_Thay_doi_kho_thuoc:
    "Selected medicines will be cleared on changing medicine storage.\nAre you sure to continue?",
  field_Ton: "Backlog",
  chon_phong: "Select room",
  loai: "Type",
  phong: "Room",
  thuoc: "Drug",
  doanh_thu_theo_thoi_gian: "Revenue over time",
  tong_doanh_thu: "Total revenue",
  dich_vu_cls: "Clinical service",
  truc_tiep: "Direct",
  qua_app: "Via App",
  qua_website: "Via Website",
  qua_zalo: "Via Zalo",
  ti_le_cuoc_kham_ung_voi_nguon_tiep_nhan: "Percentage of examinations by receiving means",
  so_cuoc_kham_va_so_dich_vu_thoi_gian_ung_voi_nguon_tiep_nhan:
    "Number of examinations and time services corresponding to the receiving means",
  ky_nay: "This period",
  ky_truoc: "Previous period",

  //NỘI TRÚ
  title_noi_tru: "Boarding",
  dm_Giuong: "List of hospital beds",
  dm_giuong_ma_giuong: "Bed code",
  dm_giuong_ma_giuong_bhyt: "Bed code BHYT",
  dm_giuong_ten_giuong: "Bed name",

  title_Bao_cao_nhanh: "Quick report",
  title_Danh_sach_phieu_thu: "List of receipts",
  title_Thu_phi: "Fee",
  tab_Bao_cao_phieu_thu: "Report receipt",
  tab_Bao_cao_vien_phi: "Hospital fee report",
  tab_Bao_cao_tiep_don: "Reception report",
  tag_Ban_thuoc: "Drug sale",
  button_Cai_dat_lai: "Reset filters",
  button_Xuat_excel: "Export excel",
  button_In_bao_cao: "Print report",
  label_Thoi_gian: "Filter time",
  label_Loai_phieu: "Receipt type",
  option_Tat_ca: "All",
  confirm_xoa_don_thuoc: "Are you sure to delete this prescription ?",

  // Quản lý bệnh nhân
  cai_dat_hien_thi: "Display configuration",
  field_do_tuoi: "Age group",
  field_ngay_kham_gan_nhat: "Most recent examination date",
  field_nho_hon_1_tuoi: "< Age 1",
  field_1_11_tuoi: "Age 1 - 11",
  field_12_17_tuoi: "Age 12 - 17",
  field_18_64_tuoi: "Age 18 - 64",
  field_lon_hon_64_tuoi: "> Age 64",
  bao_hiem_y_te: "Medical Insurance",
  danh_sach_lich_su_kham_benh: "List of medical history",
  field_chuan_doan_benh_chinh: "Diagnosis of main disease",
  field_cskcb: "Hospital",
  field_khoa_theo_byt: "Ministry of Health's Department",
  field_co_so_kham_chua_benh: "Hospital",
  field_ngay_tai_kham: "Re-examination date",
  danh_sach_phieu_thu: "List of receipts",
  field_loai_phieu_thu: "Type of receipt",
  field_phan_loai: "Classified",
  field_hoan_tien: "Refund",
  txt_ngay_gan_nhat: "Recent days",
  txt_thang_gan_nhat: "Recent months",
  txt_tuy_chon: "Custom",

  //Loại thanh toán
  loai_phieu_nhap: "Input slip",
  loai_phieu_xuat: "Export slip",
  loai_phieu_ban_le: "Retail voucher",
  loai_phieu_dieu_chin: "Adjustment slip",
  loai_phieu_thanh_toan: "Payment slip",
  loai_phieu_xuat_huy: "Cancellation ticket",
  loai_phieu_xuat_tra_ncc: "NCC payment slip",
  loai_phieu_xuat_ban: "Receipt for sale",

  noti_chua_co_phieu_ban_thuoc: "You do not have any prescriptions yet. Please create a new one.",
  noti_chua_chon_benh_nhan_ban_thuoc: "You have not selected any prescriptions yet",
  error_Thoi_gian_khong_hop_le: "Invalid datetime",

  //thông tin bệnh nhân, tạo mới bệnh nhân
  tao_moi_benh_nhan: "Create a new patient",
  tab_Phieu_thanh_toan: "Payment slip",
  tab_Phieu_hoan_tien: "Refund Voucher",
  cf_Ban_co_chac_chan_khoa: "Are you sure you want to block these items?",
  cf_Ban_co_chac_chan_mo_khoa: "Are you sure you want to unblock these items?",

  // NỘI TRÚ
  ma_bn: "Patient Code",
  ma_lk: "Code visits ",
  chon_khoa: "Select department",
  loc_BN: "Filter patient",
  in_bao_cao: "Print report",
  dich_vu: "Service",
  thuoc_vat_tu_mau: "Medicines, supplies, blood",
  dieu_duong: "Nursing",
  dieu_tri: "Treatment",
  xet_nghiem: "Test",
  cdha: "IA",
  pttt: "Surgical procedure",
  thuoc_vat_tu: "Medicines, supplies",
  dich_vu_khac: "Other services",
  xu_tri: "To solve",
  ngay_chi_dinh: "Designated date",
  ngay_y_lenh: "Order day",
  noi_chi_dinh: "Order place",
  ngay_ket_qua: "Result date",
  noi_thuc_hien: "Place of execution",
  ten_chi_so: "Index",
  tham_chieu: "Reference",
  ten_PTTT: "Name PTTT",
  loai_PTTT: "Type PTTT",
  ten_thuoc_vat_tu: "Medicines, supplies",
  sl: "Quanity",
  HDSD: "User manual",
  ngay_vao_giuong: "Date of entry",
  ngay_ra_giuong: "Release date",
  chon_hinh_thuc: "Choose form",
  nhap_PTTT: "Enter PTTT",
  bat_dau_luc: "Started at",
  ket_thuc_luc: "Ended at",
  chan_doan_truoc_PT: "Preoperative diagnosis",
  chan_doan_sau_PT: "Diagnosis after surgery",
  cach_thuc_PT: "Surgical method",
  phuong_phap_PT: "Surgical method",
  phuong_phap_vo_cam: "Emotionless method",
  mo_ta_chi_tiet: "Detailed description",
  nhom_mau: "Blood group",
  tai_bien_PTTT: "catastrophe surgical",
  hinh_anh_PTTT: "Image surgical",
  in_tuong_trinh: "Print report",
  tip_in_ban_ghi: "Print record",
  ho_va_ten: "Name",
  ket_luan: "Result",
  chi_tiet: "Detail",
  loai_giuong: "Bed type",
  giuong: "Bed",
  benh_kem_theo: "Including diseases",
  benh_chinh: "Main disease",
  chan_doan: "Diagnose",
  da_thuc_hien: "Executed",
  dang_thuc_hien: "Processing",
  chua_thuc_hien: "Unfulfilled",
  dieu_tri_noi_tru: "Inpatient treatment",
  chuyen_khoa: "Transfer faculty",

  rh_am: "Minus",
  rh_duong: "Positive",
  tren_ban_mo: "On the operating table",
  trong_24h: "In 24h",
  sau_24h: "After 24h",
  do_phau_thuat: "Due to surgery",
  do_thu_thuat: "By tricks",
  do_gay_me: "Due to anesthesia",
  do_nhiem_khuan: "Due to infection",

  //DM Giường
  loai_giuong_thuong: "Normal bed",
  loai_giuong_ghep_2: "Compound bed 2",
  loai_giuong_ghep_3: "Compound bed 3",

  data_Thu_ngan: "Cashier",
  data_Ky_thuat_vien: "Technician",
  data_Dieu_duong: "Nurse",
  data_Thu_kho: "Stocker",
  data_Quan_tri: "Administrator",

  field_Nhom_nhan_vien: "Staff group",
  //dược ngoại trú
  txt_them_thuoc: "Add Drug",
  txt_luu_thuoc: "Save drug",
  txt_chon_kho: "Select repository",
  txt_chon_thuoc: "Select drug",
  txt_ds_thuoc_chi_dinh: "List of prescription drugs",
  txt_thanh_toan: "Payment",
  txt_tong_tien_truoc_vat: "Total amount before VAT",
  txt_tong_tien_vat: "Total VAT",
  txt_tong_tien_sau_vat: "Total amount after VAT",
  txt_chua_ban: "Not for sale",
  txt_da_ban: "Sold",
  txt_duyet_kho: "Accept store",
  txt_ngay_ke_don: "Date",

  txt_luu_mau_don_thuoc: "Save prescription sample",
  txt_ma_mau_don_thuoc: "Code",
  txt_ten_mau_don_thuoc: "Name",
  txt_placeholder_chon_don_thuoc_mau: "Select sample",
  txt_luu_mau_thong_tin: "Save information form",

  field_Gia_thang_du: "Overvalue",

  menu_Nha_cung_cap: "Supplier",
  So_luong_ton_kho: "Inventory number",
  ton_kho: "Inventory",
  Phieu_nhap_kho: "Inventory receiving voucher",
  Phieu_xuat_kho: "Inventory delivery voucher",
  Van_chuyen_kho: "Transfer warehouse",
  The_kho: "Stock card",
  vien_phi_thanh_toan_va_in: "Pay and print",
  vien_phi_Hoan_tien_va_in: "Refund and print",

  Quyen_duyet_phieu: "Authority to approve voucher",
  luu_va_in: "Save and print",
  //alert

  khong_duoc_phep_xoa_lich_kham: "You cannot delete a paid appointment or other pending status",
  khong_duoc_phep_xoa_phieu: "Cannot delete the tiket completed !",
  // date
  thu_2: "Monday",
  thu_3: "Tuesday",
  thu_4: "wednesday",
  thu_5: "Thursday",
  thu_6: "Friday",
  thu_7: "Saturday",
  chu_nhat: "Sunday",
  so_hoa_don_da_day: "The cash register is full",
  title_them_phieu_nhap_ncc: "Add new supplier entry ticket",
  fieldMa_hoa_don: "Bill code",
  placeholder_Tao_tu_dong: "Auto genarate",

  info_Chi_xem_va_khong_duoc_thay_doi: "Read only",

  ma_phieu_bi_trung: "Duplicate Card ID",
  bac_si_chua_co_ma_cchn: "Please update your Practicing certificate",

  nhap_loi_dan: "Input advice",
  ris_nhap_tay: "Input handle RIS",
  da_nghi: "Inactive",
  chua_co_phong_thuc_hien: "No room available",

  lich_su_nhap_thuoc: "Drug import history",
  gia_co_vat: "Price VAT",
  loai_thuoc: "Drug type",
  field_thoi_gian_tao: "Create time",

  txt_them_moi_kho_thuoc: "Add new warehouse",
  kho_chuyen: "Warehouse transfer",
  duyet_khi_ke_thuoc: "Browse when prescribing",
  duyet_khi_chuyen_cac_kho: "Browse when switching repositories",

  // loai kho
  kho_le: "Retail warehouse",
  kho_tong: "Total warehouse",
  nha_thuoc: "Drugstore",
  field_SL: "Quanity",

  benh_nhan_bhyt: "Patient BHYT",
  benh_nhan_noi_tru: "Inpatient",
  benh_nhan_ngoai_tru: "Outpatient",

  nhap_tu_ncc: "Slowly enter the supplier",
  nhap_tu_kho_khac: "Import from another repository",
  xuat_vo_hong: "Break out",
  xuat_het_han_su_dung: "Expired export",
  xuat_khac: "Other export",
  ban_benh_nhan: "Sell to patients",
  tao_phieu: "Create ",
  ma_phieu: "Card ID",
  them: "Add",
  txt_can_tra_ncc: "Need to pay supplier",
  thong_tin_thuoc: "Infomation drug",
  so_benh_nhan: "Patient number",
  thong_tin_kham_nhanh: "Quick examination information",
  thong_tin_dich_vu_cls: "Clinical service information",
  chi_tiet_tiep_don_kham_chua_benh: "Details of medical reception",
  luu_va_in_phieu_kham: "Save and print the examination report",
  luu_va_in_phieu_cls: "Save and print the subclinical sheet",

  field_Nam_sinh: "Birthyear",
  danh_sach_kho_thuoc: "List warehouse",
  chi_tiet_kho: "Deatail",
  bs: "DR.",

  phan_quyen_nguoi_ban: "Authorization of the seller",
  cho_phep_ban_gia_thang_du: "Selling surplus price",
  huy_duyet_phieu: "Cancel confirm ticket",
  huy_phieu: "Cancel ticket",
  confirm_cancel_ticket: "Are you sure you want to cancel your ticket?",
  luu_va_duyet: "Save & Confirm",
  tong_tien: "Total",
  tong_tt: "Total payment",
  duyet: "Confirm",
  da_HT: "Success",
  phieu_nhap: "Receipt",
  phieu_chuyen_kho: "Warehouse transfer",

  phan_loai_xuat_tra_ncc: "Return",
  phan_loai_xuat_vo_hong: "Broken",
  phan_loai_xuat_huy: "Cancellation",
  phan_loai_ban_benh_nhan: "Sell patients",
  phan_loai_xuat_khac: "Other",
  phan_loai_xuat_het_han: "Expired",
  field_Ngay_bat_dau: "Start day",
  field_Ngay_ket_thuc: "End date",
  limit_thoi_gian_1_nam: "Availability has now exceeded 1 year",
  field_Ma_may: "Machine code",

  mau_mo_ta_chan_doan_hinh_anh: "mau_mo_ta_chan_doan_hinh_anh",
  common_Them: "Add",
  nguoi_tao: "Create by",
  them_moi_kho: "Creat warehouse",
  khoa: "Department",
  cho_duyet: "Wait confirm",
  dm_may: "Machine",
  danh_sach_thuoc_chi_dinh: "List drug",
  data_thu_thuat: "Mechanical",

  //// Trả kết quả CDHA
  title_page_tra_kq_cdhd: "Return diagnostic imaging results",
  cho_TH: "Wait",
  dang_TH: "Processing",
  da_TH: "Done",
  ngay_tra_kq: "Results day",
  nguoi_thuc_hien: "Performer",
  nguoi_tra_kq: "Person result",
  chup_anh: "Take photo",
  thoi_gian_tra: "Time",
  in_kq: "Print",
  huy_tra: "Cancel",
  tra_ket_qua: "Return",
  thuc_hien: "Doing",
  da_tra_kq: "Returned",
  da_KQ: "Result",
  xem_KQ: "View result",
  content_huy_tra_phieu: "Are you sure cancel ticket?",
  tra_ket_qua_va_in: "Return and print",
  chon_mau_mo_ta: "choose description template",
  chon_may_thuc_hien: "choose execution machine",
  noti_vui_long_chon_may_thuc_hien: "Please choose execution machine first",

  noti_so_luong_anh_toi_da: "Maximum amount image",
  noti_tra_kq_thanh_cong: "return success",
  noti_tra_kq_that_bai: "return failed",
  title_form_nhap_ket_qua: "Result input form",

  // Support
  gui_cau_hoi: "Submit question",
  nhap_noi_dung: "Input detail",
  gui: "Submit",
  cau_hoi_cua_toi: "My question",
  danh_sach_cau_hoi_thuong_gap: "Frequently asked questions",
  hoi_dap: "Q&A",
  noi_dung_phan_hoi: "Feedback content",
  cong_cu_ho_tro: "Support tools",
  nhap_noi_dung_phan_hoi: "Enter feedback text",
  cham_soc_khach_hang_context: "We'd love to hear your questions and comments. Please let us know your problem !",
  cham_soc_khach_hang: "Customer care",
  huong_dan_su_dung_he_thong: "User Manual",
  tai_pdf: "Download PDF",
  in_pdf: "Print PDF",

  phan_quyen_tao_mau_mo_ta: "Create",
  phan_quyen_sua_mau_mo_ta: "Edit",
  phan_quyen_xoa_mau_mo_ta: "Delete",
  phan_quyen_khoa_mau_mo_ta: "Lock",
  phan_quyen_tra_ket_qua: "Return",
  phan_quyen_huy_phieu: "Cancel",

  so_hoan_tien: "Refund note",

  field_Ten_may: "Machine name",
  field_Ma_BHYT: "Health insurance code",
  field_Loai_may: "Machine type",
  title_Danh_sach_nguoi_dung: "User list",
  menu_mau_mo_ta_cdha: "RIS description template",
  menu_tra_kq_cdha: "Return result",
  bao_cao_cdha: "Report",
  huy_tra_phieu: "Cancel ticket",
  title_Danh_sach_mau_mo_ta: "Description template list",
  title_Tao_mau_mo_ta: "Create description template",

  mau_danh_muc: "Category template",
  field_ma_kq_dv: "Service result code",
  field_ten_kq_dv: "Service result name",

  chon_nhom_dv: "Select service group",
  chon_loai_dv: "Select service type",
  chon_dv: "Select services",
  btn_mo_file_mo_ta: "Open description file",
  placeholder_nhap_mo_ta: "Enter description...",
  field_ket_luan: "Conclusion",
  tu_dong: "Auto generated",
  field_mo_ta: "Description",
  field_may_thuc_hien: "Execution machine",
  warning_chon_nhom_dv: "Please select service group",
  warning_chon_loai_dv: "Please select service type",

  btn_xem_them: "Load more",
  field_Ma_CK: "Exam code",
  btn_chen_anh: "Attach",
  btn_chup_anh: "Take picture",
  common_cai_dat_lai: "Reset",
  common_Ngay_mai: "Tomorrow",
  next_7_days: "Next 7 days",
  next_30_days: "Next 30 days",
  last_7_days: "Last 7 days",
  last_30_days: "Last 30 days",
  tong_tra: "Total amount",
  title_Danh_sach_thuoc_nhap: "List of imported drugs",

  //key phân hệ khám bệnh
  khambenh_7ngay_gan_nhat: "Last 7 days",
  khambenh_30ngay_gan_nhat: "Last 30 days",
  khambenh_xemthem: "See more",
  khambenh_phieu_so: "Voucher No",
  khambenh_err_xoa_dich_vu: "Paid services cannot be deleted",
  khambenh_du_kq: "Result Fulfilled",
  khambenh_cho_kq: "Result Pending",
  khambenh_xoa_ban_ghi: "Delete record",
  khambenh_confirm_xoa: "Are you sure to delete this service ?",
  khambenh_moi_ngay: "Everyday",
  khambenh_chia_lam: "Divided",
  khambenh_lan: "Time",
  khambenh_so_luong_thuoc_lon_hon_0: "The number of drugs needed is greater than 0",
  khambenh_so_luong_thuoc_khong_du: "The amount of medicine in stock is not enough",
  khambenh_toi_da: "maximum",
  khambenh_thuoc: "Medicine",
  khambenh_chua_dien_cach_dung: "did not fill in the amount or usage",
  khambenh_thuoc_da_het: "The medicine is out of stock",
  khambenh_sl_toida: "Maximum number",
  khambenh_empty: "Empty",
  khambenh_khong_huy_don_da_ban: "An approved prescription cannot be canceled",
  khambenh_khong_sua_don_da_ban: "Approved prescription cannot be edited",
  khambenh_cho_thanh_toan: "Wait for pay",
  khambenh_da_thanh_toan: "Paid in full",
  khambenh_homqua: "Yesterday",
  vienphi_dv_khong_thanh_toan: "Service cannot be paid due to performed",
  vienphi_hoan_tien_dich_vu: "Service Refund",
  vienphi_ds_dv_can_hoan_tien: "List of services to be refunded",
  vienphi_tong_mien_giam: "Total exemption and reduction",
  vienphi_da_thanh_toan: "Paid",
  vienphi_da_thuc_hien: "Already tried",
  vienphi_chua_thuc_hien: "Not yet",
  vienphi_hoan_tien: "Refund",
  vienphi_xoa_dich_vu: "Remove service",
  vienphi_so_hd_day: "Invoice book is full",
  vienphi_tong_tt: "Total payment",
  vienphi_vui_long_chon_so_tt: "Please select the collection book!",
  vienphi_no_service: "No service",
  vienphi_dv_da_tt: "Paid Service",
  vienphi_chon_so_hoan_tien: "Please select a refund book!",
  vienphi_da_hoan_tien: "Refunds",
  vienphi_ds_cac_dichvu: "List of services",
  vienphi_in_phieu_thu: "Print receipt",
  vienphi_chua_thanh_toan: "Not yet paid",
  vienphi_da_tt: "Paid",
  vienphi_ngay: "Date",
  vienphi_ghi_chu: "Notes",

  //key phân hệ tiếp đón
  tiepdon_nguoi_sua: "Editor",
  tiepdon_ngay_sua: "Correction date",
  tiepdon_nhap_lien_tuc: "Continuous input",
  tiepdon_khong_xoa_dv_da_tt: "Paid services cannot be deleted!",
  tiepdon_chi_chon_1_ngay: "You can only choose 1 exam date per appointment!",
  tiepdon_xoa_cuoc_hen: "Delete appointment",
  tiepdon_chinh_sua_cuoc_hen: "Edit appointment",
  tiepdon_cho_xac_nhan: "WAIT FOR CONFIRMATION",
  tiepdon_da_xac_nhan: "CONFIRMED",
  tiepdon_da_huy: "CANCELLED",
  tiepdon_xac_nhan_xoa_cuoc_kham: "Are you sure you want to delete this exam?",
  tiepdon_xac_nhan_xoa_ck_da_chon: "Are you sure you want to delete the selected exam?",
  tiepdon_chua_chon_phong: "No room selected yet!",
  tiepdon_doi_cuoc_hen_thanh_cong: "Change appointment successfully!",
  tiepdon_doi_cuoc_hen_that_bai: "Change of appointment failed!",
  tiepdon_in_dich_vu: "Print Service",
  tiepdon_da_nghi: "Retired",
  tiepdon_khong_hoat_dong: "inactive",
  tiepdon_da_kham: "Checked",
  tiepdon_xoa_cuoc_kham: "Delete the examination",
  tiepdon_xac_nhan_huy_ck: "Are you sure you want to cancel these exams?",
  tiepdon_in_cuoc_kham: "Print the examination",
  tiepdon_sua_cuoc_kham: "Edit examination",
  tiepdon_xac_nhan_huy_cuoc_kham: "Are you sure you want to cancel this exam?",

  bc_da_thuc_hien: "Done",
  bc_chua_thuc_hien: "Unfulfilled",
  trang_thai_thuc_hien: "Execution status",

  // fix ngôn ngữ dược
  duoc_message_chua_chon_loai_kho: "Have not selected the type of warehouse",
  con_ton: "Still exist",
  da_het: "It's over",
  duoc_chon_nguoi_ban: "Select authorization of the seller",
  duoc_ton_kha_dung: "Available",
  duoc_nguoi_huy: "Canceler",
  duoc_ngay_huy: "Cancellation date",
  duoc_thuoc: "Drug",
  duoc_message_lay_ds_kho_thuoc_loi: "Get list of bug potion stock",
  duoc_xoa_nha_cung_cap: "Delete",
  sua_nha_cung_cap: "Edit",
  confirm_xoa_nha_cung_cap: "Are you sure to delete this provider?",
  duoc_hay_nhap_gia_tri_lon_hon_0: "Please enter a value greater than 0",
  duoc_phieu_dieu_chinh_chi_tiet: "Detailed adjustment adventure",
  duoc_in_phieu_thanh_toan: "Print",
  duoc_phieu_thanh_toan_chi_tiet: "Detailed payment slip",
  duoc_ma_ncc_trung: "Vendor code is the same",
  duoc_ma_so_thue_trung: "Duplicate tax code",
  duoc_them_moi_nhom_ncc: "Add new supplier group",
  duoc_ten_nhom_ncc: "Supplier group name",
  duoc_nhap_gia_tri_nho_toi_lon: "Please enter a value from small to large",
  da_xac_nhan: "Confirmed",
  duoc_chua_chon_kho_thuoc: "Haven't selected a pharmacy",
  duoc_so_luong_toi_da: "Maximum quantity",
  duoc_so_luong_lon_hon_ton_kha_dung: "Larger quantity available",
  duoc_thuoc_da_ton_tai: "The drug already exists ",
  duoc_so_luong_thuoc_khong_kha_dung: "Number of drugs not available",
  duoc_chi_tiet_phieu_chuyen_kho: "Details of the transfer note",
  duoc_them_phieu_chuyen_kho: "Add transfer slip",
  duoc_kho_xuat_thuoc: "Export warehouse",
  duoc_kho_nhap_thuoc: "Import warehouse",
  duoc_chua_chon_kho_xuat: "Select export warehouse",
  duoc_xoa_thuoc: "Delete drug",
  duoc_gia_nhap_lon_hon_gia_ban: "The import price is greater than the selling price",
  duoc_chua_nhap_thuoc: "Haven't imported medicine yet",
  duoc_gia_sau_vat: "Price after VAT",
  title_phieu_nhap_ncc: "Supplier entry slip",
  duoc_chua_chon_thuoc: "Haven't chosen medicine yet",
  duoc_phieu_xuat_tra_ncc: "delivery note",
  duoc_phieu_xuat_vo_hong: "broken out",
  duoc_phieu_xuat_huy: "export cancel",
  duoc_phieu_xuat_het_han: "expired export",
  duoc_phieu_xuat_khac: "other export",
  duoc_phieu_ban_benh_nhan: "sell export",
  duoc_ly_do_xuat: "Reason export",
  duoc_chon_kho_thuoc: "Select warehouse",
  duoc_chon_ncc: "Supplier selection",
  duoc_vui_long_ke_don_thuoc: "Please prescribe medicine",
  duoc_khong_du_so_luong: "not enough quantity",
  duoc_nhap_day_du_thong_tin_benh_nhan: "Fill in patient information",
  duoc_chua_ban: "Not sold yet",
  duoc_da_ban: "Sold",
  duoc_nam_sinh_hop_le: "Enter a valid year of birth",
  duoc_khach_vang_lai: "Passersby",
  duoc_them_benh_nhan: "Add patient",
  duoc_huy_don: "Cancellation",
  duoc_huy_duyet: "Cancel approval",
  duoc_duyet_don: "Approval",
  duoc_huy_thanh_toan: "Cancel payment",
  duoc_xuat_kho_in_phieu: "Export stock + Print",
  duoc_huy_xuat_kho: "Cancellation export",
  duoc_confirm_xoa_don_thuoc: "You definitely want to delete this prescription?",
  phan_mem_ho_tro: "Remote device access software",
  mau_dm_khoa: "Faculty list",
  mau_dm_phong: "Room catalog",
  mau_dm_don_vi_tinh: "Unit list",
  mau_dm_nhom_dich_vu: "Service group catalog",
  mau_dm_loai_dich_vu: "Service type catalog",
  mau_dm_dich_vu: "Service catalog",
  mau_dm_thuoc: "Drug list",
  mau_dm_hang_san_xuat: "Manufacturers",
  mau_dm_hoat_chat: "active ingredients",
  mau_dm_duong_dung: "Sugar usage",
  tong_hop_template_mau_danh_muc: "Synthesize sample",
  tai_tat_ca_cac_mau: "Download all sample",
  bao_cao_duoc: "Pharmaceutical report",

  // fix ngôn ngữ báo cao
  //title báo cáo
  bao_cao_theo_doi_thu_ngan: "Cashier tracking report",
  bao_cao_bac_si_chi_dinh: "Report to the appointed doctor",
  bao_cao_phong_chuc_nang: "Report to the function room",
  bao_cao_doanh_thu: "Sales report",
  bao_cao_thu_ngan_benh_nhan: "Cashier tracking by patient and service report",
  bao_cao_the_kho: "Report card stock",
  bao_cao_su_dung_thuoc_khang_sinh: "Report on antibiotic use",
  bao_cao_bien_ban_kiem_ke_thuoc: "Drug inventory record",
  bao_cao_xuat_nhap_ton: "Import existing reports",
  bao_cao_phuong_thuc_thanh_toan: "Report to payment type",
  bao_cao_doanh_thu_ban_thuoc: "Medicine sales report",

  // bao_cao_bac_si_chi_dinh : "",
  // bao_cao_bac_si_chi_dinh : ""
  // fixx ngôn ngữ báo cáo
  bao_cao_tien_kham: "Medical examination fee",
  bao_cao_tien_dvkt: "Service money",
  bao_cao_tien_thuoc: "Drug money",
  bao_cao_tien_huy: "Cancellation money",
  bao_cao_tien_xet_nghiem: "Test money",
  bao_cao_tien_cdha: "Image analysation money",
  bao_cao_tien_khac: "Other",
  bao_cao_tong_cong: "Total",
  bao_cao_tien_mien_giam: "Free money",
  phong_chuc_nang: "Function room",
  bao_cao_xuat_tat_ca_thuoc: "Export all drugs",
  bao_cao_xuat_tat_ca_thuoc_co_lich_su_nhap_xuat: "Export all drugs with import and export history",
  bao_cao_sl_so_sach: "Number",
  bao_cao_ngoai_tru: "Outpatient",
  bao_cao_tien: "Money",
  bao_cao_ten_thuoc_ham_luong_nong_do: "Drug name, concentration, strength",
  bao_cao_chon_kho_thuoc: "Choose medicine store",

  bao_cao_ton_dau_ky: "Survive head",
  bao_cao_ton_cuoi_ky: "Survive end",
  bao_cao_sl_nhap: "Amount import",
  bao_cao_sl_xuat: "Amount export",
  bao_cao_khac: "Other",
  bao_cao_sct_nhap: "License import",
  bao_cao_sct_xuat: "License export",
  bao_cao_lo_sx: "Production batch",
  bao_cao_han_dung: "Due date",
  bao_cao_dien_giai: "Explain",
  bao_cao_sl_ton_dau: "Initial stock",
  bao_cao_hl: "Content",
  bao_cao_sl_ton_cuoi: "End stock",
  bao_cao_thoi_gian_365_ngay: "Invalid search time or more than 365 days",
  bao_cao_khong_co_thuoc: "No medicine",
  bao_cao_chon_nhan_su: "Select personnel",
  bao_cao_nhan_su_da_ton_tai: "Personnel already exist",
  bao_cao_thanh_phan_tham_gia: "Participants",
  bao_cao_them_thanh_phan_tham_gia: "Add participants",
  bao_cao_chon_kho_xuat_bao_cao: "Select the report export repository",
  bao_cao_vui_long_nhap_thong_tin_thanh_phan_tham_du: "Please enter participant information",
  bao_cao_chuc_vu: "Position",
  bao_cao_sdkt: "Registration number",
  bao_cao_hinh_thuc_thanh_toan: "Reported of payment types",

  common_lua_chon_khac: "Another choice",
  common_xuat_file_thanh_cong: "Export file success",
  common_co_loi_xay_ra: "An error occurred",
  commom_khong_co_du_lieu: "No data",
  nguoi_lap_bao_cao: "The reporting person",
  so_dang_ky: "Registration number",
  so_chung_tu: "License number",
  so_luong_dau_ky: "Quantity at the beginning of the period",
  stt: "No.",
  ten_bs_chi_dinh: "Doctor name",
  cong_khoan: "Total payment",
  bac_si: "Doctors",
  truong_phong_tckt: "Head of Finance and Accounting Department",
  ky_ten_va_dong_dau: "Signed and sealed",
  ky_ten_va_ghi_ro_ho_ten: "Sign and write full name",
  ma: "ID",
  ten_bn: "Patient name",
  truong_khoa_duoc: "Head of Pharmacy Department",
  to_kiem_ke_gom_co: "The inventory team",
  thoi_gian_kiem_ke_thuoc: "Inventory drug date",
  dia_chi_kiem_ke: "Inventory address",
  hong_vo: "Broken",
  han_dung: "Due date",
  ghi_nho: "Note",
  so_sach: "Documented",
  thuc_te: "Reality",
  y_kien_de_xuat: "Recommendations",
  thanh_vien: "Member",
  sl_nhap: "Import num",
  sl_xuat: "Export num",
  thuoc_kho: "Storage",
  ten_nhan_vien: "Staff name",
  so_nhan_vien: "personels",
  nhan_vien: "Staff",
  thong_ke_phong_chuc_nang: "Function rooms report",
  xuat: "Export",
  nguoi_lap_bang: "Table maker",
  hoa_don_ban_hang: "Receipt",
  khach_hang: "Customer",
  khach_mua_theo_don: "Customer buy by order",
  khach_vang_lai: "Passersby",
  ten_san_pham: "Product name",
  tong_tien_hang: "Total amount",
  chiet_khau_hoa_don: "Invoice discount",
  chiet_khau: "Discount",
  tong_thanh_toan: "Total payment",
  cam_on_va_hen_gap_lai: "Thank you and see you next time",
  phieu_nhap_hang: "Import coupon",
  nguoi_lap: "Creator",
  chi_nhanh: "Branch",
  ma_hang: "PLU",
  ten_hang: "Product name",
  giam_gia: "Discount",
  tong_thanh_tien: "Total money",
  mien_giam_hoa_don: "Exemptions bill",
  tien_ncc_can_tra: "Suppliers need to pay",
  ky_va_ghi_ro_ho_ten: "Sign and write full name",
  gia_tri_huy: "Cancellation value",
  sl_huy: "Number of cancellations",
  ma_so: "Code",
  thoi_gian: "Date",
  so: "Number",
  ten_thuoc_hoa_chat: "Names of drugs, chemicals, medical consumables",
  ham_luong_nong_do: "Content, concentration, packing specifications",
  ki_thuat_chi_dinh: "Designated technique",
  loi_dan_cua_bac_si_khoa: "Doctor's advice",
  bac_si_phu_trach_kham: "Doctor in charge of examination",
  do_tuoi: "Age",
  kham_lai_theo_don_thuoc_nay: "Follow up with this prescription",
  bang_chu: "In letters",
  thong_tin_bo_loc: "Filter information",
  ten_benh_nhan: "Patient name",
  ma_don_thuoc_mau_da_ton_tai: "Sample prescription code already exists, do you want to replace the saved form?",
  ma_khach_hang: " Client code",
  kham_ngay: "Day of the exam",
  Phieu_co_gia_tri_trong_ngay: "Date of ticket",
  thu_ngan_giu: "Cashier kept",
  khach_hang_giu: "Client kept",
  thong_tin_khach_hang: "Client infomation",
  ten_khach_hang: "Client name",
  tong_so_tien: "Total cost",
  bac_si_fulltime: 'Fulltime doctor',
  bac_si_parttime: 'Parttime doctor',
  chuyen_gia_hop_tac: 'Co-op specialist',
  co_so_kcb: 'Medical facility',
  sales: 'Sales',
  cong_tac_vien: 'Collaborator',

  title_Phieu_thu_phi_dich_vu: "Service fee receipt",
  title_Phieu_tom_tat_kham: "Examination summary sheet",
  nguoi_tao_bao_cao: "Reporter",
  sdt: "PN",
  field_doi_tuong_bn: " Object type",
  cong_hoa_xa_hoi_chu_nghia: "Socialist Republic of Vietnam",
  doc_lay_tu_do_hanh_phuc: "Independence - Freedom - Happiness",
  txt_ngay_nhap: "Date Added",

  txt_hoa_don_so: "Digital receipt",
  txt_nhap_tai_kho: "Enter at the warehouse",
  common_da: "had",

  txt_nguoi_giao: "transtporter",
  txt_ke_toan: "accountant",
  txt_Thu_truong_don_vi: "Header of Unit",

  noti_so_tien_am: "Cash is less than zero",
  noti_khong_dong: "Is zero",
  title_Chi_tiet_cong_no_ncc: "Supplier dept detail",
  no_dau_ky: "Dept at the begining",
  txt_cong_khoan: "Total amount",
  txt_nguoi_tao_phieu: "Voting creator",
  title_phieu_thanh_toan: "Payment ticket",
  txt_tm_cong_ty: "Company representative",
  common_Tong: "Total",
  txt_phieu_chi: "payment slip",
  txt_nguoi_nhan_tien: "Receivers",
  txt_ly_do_nhan: "Reason for receive",
  txt_So_tien: "Amount of money",
  txt_thu_quy: "Treasurer",
  txt_nguoi_lap_phieu: "Voter",
  txt_nguoi_nhan: "Receivers",
  txt_phong_tai_chinh_ke_toan: "Financial Accounting room",
  phieu_co_gia_tri_trong_ngay: "Ticket is valid for today only",
  phieu_thu_phi_dich_vu: "Service fee ticket",
  lien_0_dau: "Inter",
  thanh_tien: "Amount",
  tong_cong: "Total",
  phieu_tom_tat_kham: "Examination summary sheet",
  loi_nhap_form: "Error typing",
  status_Da_hoan_thanh: "Completed",
  tip_xoa_ban_ghi: "Delete record",
  duoc_sua_nha_cung_cap: "Edit supplier",

  text_khoi_tao_du_lieu: "System is initializing data. Please wait for a few seconds.",
  tip_xoa_nhieu: "Delete records",
  noti_dang_chuyen_doi_file: "Converting file...",
  noti_chuyen_doi_file_thanh_cong: "Convert file successfully",
  noti_chuyen_doi_file_that_bai: "Convert file failed",
  noti_dinh_dang_file_word: "Please update doc, docx file",
  noti_vui_long_nhap_mo_ta: "Please enter description",
  thuoc_thuong: "Normal drug",
  thuoc_nha_thuoc: "Drugstore drug",
  prefix_so_luong_thuoc_trong_kho_hien_khong_du: "Quantity of drug at",
  suffix_so_luong_thuoc_trong_kho_hien_khong_du: "is not enough. Do you want to change?",

  sl_chuyen_kho: "Number of warehouse transfer",
  noti_so_luong_duyet_khong_lon_hon_ton_kha_dung: "The number of browsing is not greater than the availableness!",

  xuat_ban_dau_sac: "Export to sell",
  loai_phieu_dieu_chinh: "Ticket editing",
  duoc_them_moi_nhom: "Add new group",
  tip_sua_ban_ghi: "Edit records",
  tao_moi_mau_mo_ta: "Add new template",
  noti_chua_nhap_mo_ta: "Please enter description",
  noti_chua_nhap_ket_luan: "Please enter conclusion",
  error_khoa_da_duoc_chon: "This speciality is selected!",
  prefix_xoa_lich: "Delete calendar",
  noti_chua_hoan_thanh_dich_vu: "Haven't completed the service, do you want to leave?",
  file_hdsd: "File instruction",
  cai_dat: "Setting",
  chi_tiet_cuoc_kham: "Exam detail",
  tim_kiem_lich_lam_viec: "Search schedule",
  tim_kiem_dich_vu:"Service search",
  /// fix language Exel
  common_benh_vien: "Hospital",
  common_bo_y_te: "Ministry of Health (Department of Health)",
  common_tt: "Number",
  common_ngay_thang_nam: "Date...month...year",
  common_tu_ngay: "From date",
  common_den: "to",
  ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao: "Names of drugs, chemicals, medical consumables",
  ham_luong_nong_do_quy_cach_dong_goi: "Content, concentration, packing specifications",
  ky_ghi_ro_ho_ten: "(Sign and write full name)",
  nguoi_tao_phieu: "Voting creator",
  tm_cong_ty: "On behalf of the company",
  chi_tiet_cong_no: "Details liabilities",
  so_kiem_soat: "Control number",
  bien_ban_kiem_nhap_thuoc: "Drug import record",
  bs_chi_dinh: "Doctor appointed",
  phong_tai_chinh_ke_toan: "FINANCIAL ACCOUNTING",
  mau_bao_cao_ve_khang_sinh: "Antibiotic report form",
  so_giuong_benh_ke_hoach_thuc_ke: "Planned/actual number of hospital beds",
  cong_suat_su_dung_giuong_benh: "Bed usage capacity:...(%)",
  tt_hoat_chat: "Active ingredient information",
  tt_biet_duoc: "Brand name drug information",
  nong_do_ham_luong: "Concentration/Concentration",
  ma_atc: "Code ATC",
  ten_biet_duoc: "Brand name",
  don_vi_don_goi: "Packing unit",
  duong_dung: "Usage route",
  bao_cao_su_dung_thuoc: "Drug use report",
  thoi_gian_kiem_ke_thuoc_tinh_den: "Drug inventory time up to",
  lich_su_nhap_tra_ncc: "Payment import history",
  nguoi_thu_tien: "Collecter",
  no_cuoi_ky: "End-of-term debt",
  phat_sinh_trong_ky: "Born in the period",
  tong_hoa_don: "Total bill",
  txt_can_tra: "Need to pay",
  txt_thuc_tra: "Real pay",
  txt_ghi_no: "Debit",
  txt_ghi_co: "Credit",
  da_kiem_ke_tai: "Inventoryed at",
  common_gio: "Hours",
  ho_ten: "Name",
  noti_lay_danh_sach_that_bai: "Get list failure",
  khong_duoc_de_trong: "${label} cannot blank charaters",
  toi_da_ky_tu: "${label} maximum ${max} charaters",
  thong_tin_doi_tuong: "Object infomation",

  sdt_nguoi_than: "Parent phone number",
  dia_chi_nguoi_than: "Address of relatives",
  //update
  ten_nguoi_than: "Relative's name",
  quan_he_gia_dinh: "Family relationship",
  tong_chi_phi_KCB: "Total cost",
  bh_thanh_toan: "Payment insurance",
  benh_nhan_da_tt: "Paid",
  benh_nhan_chua_tt: "Unpaid",
  can_nang: "Weight",
  chieu_cao: "Hieght",

  tien_su_benh: "Medical history",
  tien_sua_benh_ban_than: "Personal medical history",
  benh_su: "Medical history",
  kham_benh: "Medical examination",
  tien_su_san_khoa: "Obstetric history",
  tien_su_benh_gia_dinh: "Family medical history",
  tien_su_di_ung: "History of allergies",
  trieu_chung_lam_sang: "Clinical symptoms",
  tai_nan_thuong_tich: "Injury",
  toan_than: "Body",
  cac_bo_phan: "Parts",
  tuan_hoan: "Cyclic",
  ho_hap: "Respiratory",
  tieu_hoa: "Digestion",
  than_tiet_nieu: "Urinary kidney",
  noi_tiet: "Endocrine",
  co_xuong_khop: "Musculoskeletal",
  than_kinh: "Nerve",
  bs_kham: "Doctor examines",
  chan_doan_so_bo: "Preliminary diagnosis",
  icd_benh_chinh: "ICD Main disease",
  icd_yhct_chinh: "ICD Traditional medicine",
  icd_benh_kem_theo: "ICD Including diseases",
  idc_yhct_kem: "ICD Accompanying traditional medicine",
  chan_doan_xac_dinh: "Implementing the quadrants",
  huong_xu_tri: "Treatment direction",
  ngay_tai_kham: "Re-examination date",
  loi_dan: "Advice",
  hoi_benh: "Ask the sick",
  kham_tong_quan: "General examination",
  chan_doan_xu_tri: "Diagnosis and treatment",
  confirm_huy_xuat_kho: "Are you sure to cancel the shipment?",
  confirm_huy_thanh_toan_don_thuoc: "Are you sure to cancel the payment?",
  confirm_huy_duyet_don_thuoc: "Are you sure to cancel your prescription?",
  confirm_ket_thuc_cuoc_kham: "Are you sure you want to end the exam?",
  ke_don: "Prescription",
  vui_long_nhap_chan_doan_so_bo: "Please enter preliminary diagnosis",
  nguon_khach: "Source of customers",
  mau_thong_tin: "Information form",

  //CÁCH DÙNG
  them_moi_cach_dung: "Added new usage",
  ten_cach_dung: "Usage name",
  vui_long_nhap_ten_cach_dung: "Please enter username",
  thong_tin_ck: "Information about the examination",
  don_thuoc_cu: "Old prescriptions",
  ket_qua_cls: "Subclinical results",
  xem_nhanh: "Quick view",
  ngay_gio_y_lenh: "Order date and time",
  so_ngay_sd: "Number of days of use",
  huong_dan_su_dung: "User manual",
  xem_nhanh_lich_su_kham_benh: "Quick view of medical history",
  lich_su_kham_benh: "Medical examination history",
  tien_su: "Antecedent",
  new_so_ngay_sd: "Number of days of use",
  new_moi_ngay: "Every day use",
  tan_suat_sd: "Frequency of use",
  txt_ngay: "date",

  chan_doan_hinh_anh: "Image analysation",
  phieu_kham_benh: "Medical bill",
  don_thuoc_dich_vu: "Service prescription",
  ma_benh_nhan: "Patient number",
  chan_doan_chinh: "Main diagnosis",
  chan_doan_phu: "Secondary diagnosis",
  ngay_dung: "Use date",
  chi_dan_dung_thuoc: "Medication instructions",
  loi_dan_bac_sy: "Doctor's advice",
  ten_bo_hoac_me_cua_tre:
    "Name of the child's father or mother or the person who brought the child for medical examination and treatment",
  kham_lai_xin_mang_theo_don: "For re-examination, please bring this form",
  title_phieu_chi_dinh_cho_bn_ngoai_bh: "INDICATION FORM FOR OUT-INSURANCE PATIENTS",
  khoa_phong: "Departments",
  tong_tien_nguoi_benh_phai_tra: "Total amount paid by the patient",
  da_duoc_tu_van_day_du:
    "Have been fully consulted and agreed to perform services at the clinic at the price listed in the medical service price list.",

  error_loi_ket_noi: "Connection errors . Please log in again",
  cho_thuc_hien: "Waiting",
  Dang_thuc_hien: "Processing",

  ban_than: "Self",
  gia_dinh: "Family",

  vui_long_chon_thuoc: "Please choose drug",
  don_thuoc_mau: "Sample prescription",
  vui_long_chon_kho: "Please select storage",
  ma_don: "Sample prescription code",
  ten_don: "Sample prescription name",
  danh_sach_thuoc_chi_dinh_trong_don: "Drug list",
  in_don_tu_van: "Print advice pres",

  phong_tiep_don: "Reception room",
  noti_chua_luu_thay_doi_don_thuoc_khi_bam_in: "You have not saved the prescription yet. Please save before printing.",

  phieu_tu_van: "Counseling prescription",
  noti_don_thuoc_khong_co_thuoc_byt: "No drugs of ministry of health founded in prescription",
  noti_don_thuoc_khong_co_thuoc_tpcn: "No dietary supplement founded in prescription",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc: "Please enter required fields",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc_cua_thuoc_trong_don:
    "Please fulfill required information of drugs in prescription",
  noti_vui_long_nhap_dung_dinh_dang: "Please enter correct format",
  nhap_gia_tri_lon_hon: "Enter value bigger than",
  va_nho_hon: "and smaller than",
  chua_co_lich_su: "No history",
  chua_chon_benh_nhan_vao_kham: "No patient",

  //popup thông tin khám
  ttk_tieu_de: "List of templates",
  ttk_tao_moi: "Add new model",
  nhap_ten_mau: "Model name",
  luu_mau: "Save Template",
  sua_mau: "Edit Template",
  msg_ten_mau: "Please enter a template name",
  msg_xoa_mau_thanh_cong: "Delete sample information successfully",
  tooltip_sua_mau: "Edit examination information form",
  txt_xac_nhan_xoa_mau: "Are you sure you want to delete this template?",
  msg_luu_mau_thanh_cong: "Sample saved successfully!",
  msg_sua_mau_thanh_cong: "Modify successful!",

  ket_qua_xet_nghiem: "Test results",
  thong_tin_ket_qua_xn: "Information about test results",
  file_dinh_kem: "File ",
  ten_xet_nghiem: "Test name",
  gia_tri_tham_chieu: "Reference value",
  ngay_nhan_kq: "Date result",
  thiet_bi: "Device",
  so_chay: "Number run",
  ngay_chay: "Date run",
  ngay_duyet: "Date confirm",
  chi_dinh: "Point",
  thong_tin_file_dinh_kem: "Attached file information",
  khoang_tham_chieu: "KReference range",
  nguoi_lay_mau: "Sampler",
  thoi_gian_lay_mau: "Sampling time",
  ket_qua_nam_lech: "Results in the range of",
  ket_qua_nam_giua: "Result in the center",
  ket_qua_in_dam: "Result in bold",
  trai: "the left",
  phai: "the right",
  label_ngay_tiep_don: "Reception date",
  khong_dung_dinh_dang: "is not match format",

  ten_hinh_thuc_thanh_toan: "Name payment method",
  cate_hinh_thuc_thanh_toan: "Payment method",

  answer_co: "Yes",
  answer_khong: "No",
  khai_thac_benh_su: "History extraction",
  thong_tin_benh_nhan: "Patient info",
  question_1: "Are you allergic to foods, drugs or substances?",
  question_2: "Have you been hospitalized or had surgery before?",
  question_3: "Have you ever had a blood transfusion?",
  question_4: "Are you taking any medications or treatments?",
  question_family: "Has anyone in the family had previous illnesses?",
  question_info_1: "From what sources do you know our clinic?",
  nguyen_vong_khac: "Other wishes",
  lich_kham_chua_hoan_thanh: "The patient has an incomplete day of examination",
  huyet_ap_cao: "High Blood Pressure",
  tieu_duong: "Diabetes",
  tai_bien_nao: "Brain stroke",
  benh_tim_mach: "Heart-related diseaes",
  benh_than: "Kidney disease",
  benh_kawasaki: "Kawasaki disease",
  sot_xuat_huyet: "Dengue",
  benh_lao: "Labor",
  viem_da_di_ung: "Allergic dermatitis",
  other: "Other",
  ung_thu: "Cancer",
  dau_nua_dau: "Migraine",
  bao_chi: "Newspapers",
  cong_ty_ban_be: "Company/Friend",
  save_image: "Save image",
  error_khong_co_dich_vu: "Sevice empty",
  action_dung: "Stop",
  chup_tu_dong: "Auto",
  so_luong_anh_toi_da: "Total image max 6",
  thoi_gian_dem_lui: "Countdown time",
  tiep_tuc_tai_anh_len: "Continue",
  chinh_sua_anh: "Edit image",
  chon_anh: "Select image",
  tai_len_hinh_anh: "Upload image",

  ma_loai_dich_vu: "Service type code",
  nhom_loai_dich_vu: "Service type group",
  truong_khoa_chan_doan_hinh_anh: "Department of diagnostic imaging",
  thong_ke_hoat_dong_chan_doan_tham_do_chuc_nang: "Functional diagnostic activity statistics",
  thoi_gian_chi_dinh: "Specify time",
  ngay_thanh_toan: "Date of Payment",
  ma_kh: "Client code",
  bao_cao_doanh_thu_kh: "Sale report by customer",
  bao_cao_phieu_thu_dich_vu: "Service receipt report",
  phieu_dich_vu: "Service Voucher",

  CIS_ql_pk: "CIS",
  LIS_ql_xn: "LIS",
  RIS_ql_cdha: "RIS",
  PRM_ql_cskh: "PRM - Quản lý CSKH",
  home_vtc: "Home",
  right_duoc: "Outpatient\n" + "pharmacy",
  noti_khong_the_xoa_cuoc_kham_co_dich_vu_da_thanh_toan: "Can't executed examination, one of service had pay",
  khuyen_nghi: "Recommendations",
  cap_nhat_phan_mem: "Migrate to new version",
  content_update_version: "New version is already, please login to have a new feature experience",
  thu_gon: "Collapse",
  mo_rong: "Expand",
  trang_thai_kham: "Medical examination status",
  trang_thai_thanh_toan: "Payment status",
  field_Dat_kham: "Booking",

  da_thanh_toan: "Had pay",
  can_tao_lich_lam_viec: "You need to create the schedule for this service",
  tong_kh_thanh_toan: "Total payment",
  tong_tien_hoan: "Total refund",

  da_xac_thuc: "Authenticated",
  chuyen_huong_toi_trang_chu: "Returning to homepage",
  quan_ly_tai_khoan: "Account managment",
  thong_tin_tai_khoan: "Account infomation",
  doi_mat_khau: "Change password",
  mat_khau_cu: "Old password",
  mat_khau_moi: "New password",
  nhap_lai_mat_khau_moi: "Confirm password",
  content_doi_mat_khau: "Please valid the password before change",
  vui_long_xac_nhapn_mat_khau: "Please confirm password first",
  xac_nhan_mat_khau_khong_dung: "Confirm password is not correct",
  thong_tin_ket_qua_xet_nghiem_chuan:"information about standard test results",
  cate_quan_ly_phan_he: "Module Management",
  ket_qua_mac_dinh: "Default result",
  ghi_chu_can_tren: "Upper bound note",
  ghi_chu_can_duoi: "Lower bound note",
  ghi_chu_trung_binh: "Note average",
  hien_thi:"Show",
  cs_kem_theo:"Attached indicators",
  can_duoi_nam:"Man bottom edge",
  can_duoi_nu:"Woman bottom edge",
  can_tren_nu: "Woman top edge",
  can_tren_nam: "Man top edge",
  can_tren_tre_em: "Children top edge",
  can_duoi_tre_em: "Children bottom edge",
  can_tren: "Top edge",
  can_duoi: "Bottom edge",
  loai_xet_nghiem: "Test type",
  danh_sach_chi_so_kem_theo: "List of attached indicators",
  thong_tin_chi_so_kem_theo:"Infomation of attached indicators",
  title_chinh_sua_thong_tin_ket_qua_xn_chuan:"Edit information about standard test results",
  title_quan_ly_phan_quyen:"Permission management",
  thuc_hien_thu_thuat:"Perform tricks",
  huy_thuc_hien:"Cancel execution",
  tab_Bao_cao_thu_thuat:"Medical examination report",
  phan_he_lam_viec:"Working subsystem",
  le_tan:"Reception",
  Duoc_si:"Pharmacist",
  mkt_leader:"Customer care leader",
  cskh:"Customer care",

  noPermission: "You don't have the permission to use this function !",
  title_thong_tin_nguoi_dung:"User information",
  noti_reload:"This action requires reloading the page",
  sub_title_dm: 'Administrate catalog, subsystems, system management, staff...',
  sub_title_cis:"Management of medical examination and treatment activities",
  sub_title_duoc_ngoai_tru:"Manage pharmaceutical supplies, cabinets",
  sub_title_lis:"Management of laboratories and testing centers",
  sub_title_ris:"Management of diagnostic imaging center",
  sub_title_prm:"Manage customer care, increase sales",
  noti_khong_the_huy_thao_tac:"You can't undo this action, keep changing ?",
  in_luot_kham:"Print report",
  mo_hinh_cskcb:"Model of medical examination and treatment",
  tham_do_chuc_nang:"Function exploration",
  dich_vu_thu_thuat_phau_thuat:"Service of procedures, surgery",
  dm_chung: "Shared category",
  duoc_ban_thuoc: "Outpatient pharmacy/Drug sales",
  qlch: "Configuration management",

  mo_hinh_CSKCB: "Model of non-medical examination facility",
  mo_hinh_chuoi: "Chain model",
  mo_hinh_HUB: "HUB model",
  mo_hinh_mot_phong_kham: "One clinic model",
  quan_ly_hoa_hong: "Commission management",
  dm_doi_tuong_nguoi_dung: "User object category",
  nhom_doi_tuong_nguoi_dung: "User object group",
  hoa_hong: "Commission",

  nguon_chi_dinh: "Designated source",
  tim_kiem: "Search",
  loai_phong_kham: "Clinic type",
  pk_chuyen_khoa: "Specialist",
  pk_da_khoa: "Polyclinic",
  title_pop_up_thong_bao: 'Notification',
  message_thong_bao: 'Do you want to synchronize services with the central clinic?',
  noi_soi_tieu_hoa: 'Gastrointestinal endoscopy',
  dien_tim: 'ECG',
  noi_soi_tai_mui_hong: 'Endoscopy otorhinolaryngology',
  dien_nao: 'Electroencephalogram',
  soi_co_tu_cung: 'Colposcopy',
  phuc_hoi_chuc_nang: 'Rehabilitation',
  vi_sinh: 'Microbiology',
  sinh_hoc_phan_tu: 'Molecular Biology',
  te_bao: 'Cell',
  nuoc_tieu: 'Urine',
  huyet_hoc: 'Hematology',
  sinh_hoa: 'Biochemistry',
  giai_phau_benh: 'Pathology',
  sieu_am:'Supersonic',
  CT: 'CT',
  xquang: 'X-ray',
  chup_MRI: 'MRI (Magnetic Resonance)',
  mamo: 'Mamo',
  loang_xuong: 'Osteoporosis',
  bao_cao_thong_ke: 'Report, statistic',
  sub_title_bao_cao: "Manage reports, statistics of all medical activities",
  merge: 'Merge',
  detach: 'Detach',
  noti_xac_nhan_thay_doi: "Do you confirm the change ?",
  noti_existed_email: 'Existed email',

  noti_lock_service: "You can't specify the service after disabling it, are you sure you want to disable it ?",
  noti_delete_service1: 'There is an appointment using the service, service information in the appointment ',
  noti_delete_service2: 'will disappear',
  noti_delete_service3: 'After deletion, service information ',
  noti_delete_service4: 'cannot be recovered',
  noti_delete_service5: '. Service usage history information is still kept. Are you sure you want to continue deleting the service ?',
  noti_delete_service5_1: 'Are you sure you want to continue deleting the service ?',
  dm_dv_pk: "Clinic's services catalog",
  dm_pk_vt: "Satellite clinic's services",
  dm_pk_tt: "Center clinic's services",
  chon_pk_vt: 'Select satellite clinic',

  dm_loai_goi_dv: "Service bundle type catalog",
  loai_goi_dv: "Service bundle type",
  loai_goi_dv_lower: "service bundle type",
  ma_goi_dv: "Bundle type code",
  ten_goi_dv: "bundle type name",

  noti_delete_applied_commission_1: 'The commission mechanism has been applied to the subject.',
  noti_delete_applied_commission_2: 'After deletion, commission mechanism information',
  noti_delete_applied_commission_3: ' cannot be restored.',
  noti_delete_applied_commission_4: 'Service commission reports are still kept. Are you sure you want to continue removing the commission mechanism?',

  noti_delete_applied_exemption_1: 'The exemption mechanism has been applied to the subject.',
  noti_delete_applied_exemption_2: 'After deletion, exemption mechanism information',
  noti_delete_applied_exemption_3: ' cannot be restored.',
  noti_delete_applied_exemption_4: 'Service exemption reports are still kept. Are you sure you want to continue removing the exemption mechanism?',

  pl_update_exemption:"Please update the exemption mechanism",
  already_duplicated:'Already duplicated, please select again',

  normal_index: "Normal index",
  index_group: "Index group",
  index_information:'Index information',
  option_value: 'Option Value',
  index_type: 'Index type',
  decimal: 'Decimal',
  integer: 'Integer',
  singleChoice: 'Single choice',
  multipleChoice: 'Multiple choice',
  textInput: 'Text input',
  choiceName: 'Choice name',
  serviceNameTT13: 'Service name according to Circular 13',
  serviceCodeTT13: 'Service code according to Circular 13',
  underOneYearOld: 'Under 1 year old',
  oneToTwoYearsOld:"1-2 year old",
  twoToSixYearsOld:"2-6 year old",
  sixToTenYearsOld: "6-15 year old",
  overTenYearsOld:"Over 15 year old",
  overSixtyYearsOld: "Over 60 year old",
  createNewContinuously :'Create new continuously',
  addNewSubscript: 'Add new subscript',
  referenceIndex: 'Reference index',
  messageErrorAddNewDV: 'Please enter all required fields.',
  listOfAttachedStats: 'List of attached stats',
  pleaseAddTheAttachedIndex :'Please add the attached index',
  cf_lock_xn_dv: `You can't specify the service after locking the service, are you sure you want to lock the service?`,
  noti_delete_applied_DvXn_1:'There is an appointment scheduled to use the service, service information in the appointment ',
  cf_lock_xn_dv_dvcon: 'Are you sure to lock this index?',
  cf_delete_xn_dv_dvcon: 'Are you sure to delete this index?',
  ma_chi_so: 'Index code',
  warning_can_duoi: 'Value less than or equal to upper bound',
  warning_can_tren: 'Price is greater than or equal to the lower bound',
  default_value: 'Default value',
  warning_decimal_or_zero:  'Please enter a valid decimal or 0',
  warning_integer:  'Please enter a valid integer!',
  create_a_successful_service: 'Create a successful service',
  create_subscript_translation_with_success : "Create subscript translation with success",
  noti_error_create_fail : "Noti error create fail",

  tiep_theo: 'Next',
  exemption_management: 'Exemption management',
  add_new_exemption_mechanism: 'Add new exemption mechanism',
  exemption_mechanism: 'Exemption mechanism',

  quan_ly_goi_dich_vu:"Manage and use service packs",
  danh_sach_goi_dich_vu:"List of service packages",
  common_dang_ky:"Register",
  common_kich_hoat:"Activated",
  hoan_thanh_goi:"Complete package",
  tam_ngung:"create condensation",
  in_file:"print file",
  ql_dang_ky_goi_dich_vu:"Manage service package subscriptions",
  ql_su_dung_goi_dich_vu:"Manage and use service packs",
  sao_chep:"copy",
  quan_ly_cong_no:"Debt control",
  quan_ly_tam_ung:"Advance management",
  tao_phieu_thu_tam_ung:"Create advance receipt",
  huy_phieu_thu_tam_ung:"Cancel advance receipt",
  benh_pham:"Specimens",
  quan_ly_khach_hang:"Customer management",
  khoa_kho_thuoc:"Lock the medicine warehouse",
  tien_ich: 'Utilities',
  gioi_thieu_chung: 'General introduction',
  app_mobicare: 'PHR app',
  sub_mobi_care: 'Appointment booking application, appointment management, health record management, private doctor consulting service',
  tro_ly_y_khoa_AI: 'AI Medical Assistant',
  sub_title_tro_ly_y_khoa_AI: 'AI assistant provides professional support to medical staff in medical examination and treatment',
  trung_sdt: "Phone number is taken",
  ton_tai_username_sdt: "Username or phone number already exists",

  // PRM Sider
  roomCatalog: "Room Catalog",
  SMC_Catalog: "SMC Room Catalog",
  customerCatalog: "Customer Catalog",
  customerStatus: "Customer Status",
  relationship: "Relationship",
  potentialReview: "Potential Rating",
  career: "Career",
  customerDemand: "Customer Demand",
  tagManagement: "Tags Management",
  businessCatalog: "Business Catalog",
  businessType: "Business Type",
  businessStatus: "Business Status",
  appointmentStatus: "Appointment Status",
  orderStatus: "Order Status",
  quoteStatus: "Quote Status",
  contractStatus: "Contract Status",
  debtStatus: "Debt Status",
  med_HealthCare_Catalog: "Medicine and Healthcare Catalog",
  healthCareProductGroup: "Healthcare Product Group",
  healthCareProductCatalog: "Healthcare Product Catalog",
  config: "Config",
  configManagement: "Switchboard Config Management",
  feedbackConfigManagement: "Customer Feedback Config Management",
  employeeManagement: "Employee Management",
  proWorkingSchedule: "Professional Working Schedule",
  smcWorkingSchedule: "BP SMC Working Schedule",
  voucherManagement: "Voucher Management",

  //PRM Columns
  workDay: "Working Day",
  employeeList: "Employee List",
  statusCode: "Status Code",
  statusName: "Status Name",
  relationshipCode: "Relationship Code",
  relationshipName: "Relationship Name",
  statusMechanism: "Status Mechanism",
  careerCode: "Career Code",
  careerName: "Career Name",
  demandCode: "Demand Code",
  demandName: "Demand Name",
  tagName: "Tag Name",
  businessCode: "Business Code",
  businessName: "Business Name",
  level: "Level",
  warningLimit: "Warning Limit",
  limitedTime: "Limited Time",
  act: "Act",
  ext: "Ext",
  part: "Part",
  productGroup: "Product Group",
  specifications: "Specifications",
  formCode: "Form code",
  feedbackFormName: "Feedback form name",
  latestEditor: "Latest editor",
  latestEditedDate: "Latest edited at",
  voucherCode: "Voucher Code",
  voucherName: "Voucher Name",
  voucherType: "Voucher Type",
  duration: "Duration",
  voucherContent: "Voucher Content",
  appliedClinic: "Applied Clinic",
  waiting_to_apply: "Waiting to apply",
  applying: "Applying",
  expired: "Expired",

  //PRM Header
  smcRoom: "SMC Room",
  smc_room: "SMC room",
  customer_status: "customer status",
  cate_relationship: "relationship",
  potential: "potential",
  cate_career: "career",
  customer_demand: "customer demand",
  tag: "tag",
  business_type: "business type",
  business_status: "business status",
  appointment_status: "appointment status",
  order_status: "order status",
  quote_status: "quote status",
  contract_status: "contract status",
  debt_status: "debt status",
  switch_config: "switchboard configuration",
  feedbackFormManagement: "Customer feedback form management",
  survey_form: "survey form",
  healthcare_group: "healthcare group",
  healthcareProduct: "Healthcare Product",
  healthcare_product: "healthcare product",

  // PRM Form
  refValue: "Reference value",
  businessTypeCode: "Business Type Code",
  businessTypeName: "Business Type Name",
  businessStatusName: "Business Status Name",
  appointmentStatusName: "Appointment Status Name",
  orderStatusName: "Order Status Name",
  quoteStatusName: "Quote Status Name",
  contractStatusName: "Contract Status Name",
  businessStatusCode: "Business Status Code",
  appointmentStatusCode: "Appointment Status Code",
  orderStatusCode: "Order Status Code",
  quoteStatusCode: "Quote Status Code",
  contractStatusCode: "Contract Status Code",
  levelName: "Level Name",
  message: "Message",
  call: "Call",
  wholeWeek: "Whole week",
  revenue: "Revenue",
  examNumber: "Number of examinations",
  viewList: "View list",
  healthcare_group_name: "Healthcare group name",
  pleaseSelectPart: "Please select part !",
  productCode: "Product code",
  generalInfo: "General information",
  preview: "Preview",
  title: "Title",
  questionList: "Questions list",
  questionType: "Question type",
  content: "Content",
  addQuestion: "Add question",
  addOption: "Add option",
  option: "Option",
  convertPoint: "Convert point",
  select1answ: "Select 1 answer",
  selectMultiAnsw: "Select multiple answers",
  shortAnsw: "Short answer",
  paragraph: "Paragraph",
  please_add_question: "Please add question",
  please_choose_suitable_answ: "Please choose the suitable answers !",
  employeeInfo: "Employee's information",
  workShift: "Work shift",
  apply_to_all_week_of_month: "Apply to all weeks of the month",
  duplicateShift: "Work shift is duplicated",
  voucherInfo: "Voucher's info",
  applyTime: "Time of application",
  service_discount_percent: "Service % discount",
  service_discount_amount: "Service fee discount",
  bill_discount_percent: "Bill % discount",
  bill_discount_amount: "Bill fee discount",
  service_gift: "Gift service",
  identification: "Identification",
  voucherIdentifier: "Voucher code identifier",
  regular: "Regular",
  servicePackage: "Service package",
  priceType: "Price type",
  discount: "Discount",
  discountPrice: "Discount price",
  clinic_apply: "Clinics apply",
  condition_apply: "Conditions apply",
  discountContent: "Discount content",
  service_based_discount: "Service based discount",
  invoice_based_discount: "Invoice based discount",
  seeDetail: "See detail",
  signature_name: 'Signature name',
  resident_doctor: "Resident doctor",
  doctor: "Doctor",
  bachelor: 'Bachelor',
  engineer: "Engineer",
  can_do:"Weights and Measures",
  //
  tool_tip_quan_tri: 'Categories and subsystems management',
  tool_tip_cis: 'Medical examination and treatment activities management',
  tool_tip_lis: 'Laboratory management',
  tool_tip_ris: 'Diagnostic imaging department management',
  tool_tip_prm: 'Customer care management',
  tool_tip_duoc: 'Outpatient pharmacy management',
  tool_tip_bao_cao: 'Reports and statistics management',
  mess_error_field_Loai_chi_so: 'Please select an indicator type',
  mess_error_field_Loai_chi_vu: 'Please select service type',
  thuc_hien_can_lam_sang: "Perform clinical trials",
  dm_nhom_dv_thuong_dung: "Frequently used service group catalog",
  nhom_dv_thuong_dung: "Frequently used service group",
  ten_nhom_dv_thuong_dung: "Frequently used service group name",
  nam:"Male",
  nu:"Female",

  vat_tu: 'Material',
  tab_duoc: 'Dental',

  dinh_dang: 'Format',
  kich_thuoc: 'Size',
  "DCCK03": "Internal Digestion",
  "DCCK10": "Internal muscles - bones - joints",
  "DCCK11": "Cardiovascular",
  "DCCK31": "Internal kidney - urinary tract",
  "DCCK34": "Internal synthesis",
  "DCCK36": "Respiratory",
  "DCCK40": "Endocrine",
  "DCCK20": "External digestion",
  "DCCK28": "General Foreign Affairs",
  "DCCK01": "Teeth - Jaw - Face",
  "DCCK18": "Ear - Nose - Throat",
  "DCCK06": "Nhi",
  "DCCK24": "Obstetrics",
  "DCCK15": "Physical therapy - Rehabilitation",
  "DCCK22": "Orthopaedic trauma",
  "DCCK14": "Oncology",
  "DCCK16": "Dermatology",
  "DCCK21": "Allergy",
  "DCCK23": "Eye",
  "DCCK27": "Traditional medicine",
  "DCCK35": "Nervous",
  "DCCK37": "Contagious",
  "DCCK39": "General Department",
  "DCCK42": "Medical examination",
  "DCCK41": "Emergency",
  "DCCK43": "Emergency resuscitation",
  "DCCK46": "Surgery - Anesthesia and Resuscitation",
  "DCCK47": "Nuclear medicine",
  "DCCK48": "Blood Transfusion",
  "DCCK49": "Artificial hemodialysis",
  "DCCK17": "Clinical hematology",
  "DCCK50": "Hematology",
  "DCCK51": "Biochemistry",
  "DCCK52": "Microbiology",
  "DCCK61": "Test",
  "DCCK56": "Pathology",
  "DCCK53": "Diagnostic imaging",
  "DCCK60": "Molecular Biology",
  "DCCK54": "Function probe",
  "DCCK55": "Endoscopy",
  "DCCK57": "Anti-infection",
  "DCCK58": "Pharmacy",
  "DCCK59": "Nutrition",
  "DCCK62": "Active resuscitation",
  "DCCK63": "Anti-poison",
  "DCCK64": "Aesthetics",
  "DCCK65": "Vaccination",
};
export default en;

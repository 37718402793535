import { takeLatest } from "redux-saga/effects";
import { fieldDoiTuong } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog.js";
import { doCreateDmPhong, doDeleteDmPhong, doGetListDmPhong, doSetListDmPhong, doUpdateDmPhong, exportDmPhong, getDsPhongTh, getDsPhongThSuccess, importDmPhong, lockDmPhong, updatePhong } from "../../slices/DM_Chung/DmPhong.js";
import { handleCommonAdd, handleCommonDelete, handleCommonEdit, handleCommonExport, handleCommonGet, handleCommonImport, handleCommonLock } from "../commonSaga/commonSaga.js";
import { dispatchAddDmPhong, dispatchDeleteDmPhong, dispatchGetListDmPhong, dispatchLockDmPhong, dispatchUpateDmPhong, exportPhong, postGetDsPhongThucHien, postImportPhong } from "./apiDM_Chung.js";
import dayjs from "dayjs";
import Apis from "../../../constants/apis.js";
import { convertKeys } from "../../../helpers.js";
import { mappingDataExcel } from "../../../constants/data.js";


export function* watchDoDmPhong() {
  yield takeLatest(doGetListDmPhong, handleGetListDmPhong);
  yield takeLatest(doCreateDmPhong, handleCreateDmPhong);
  yield takeLatest(lockDmPhong, handleLockDmPhong);
  yield takeLatest(doDeleteDmPhong, handleDeleteDmPhong);
  yield takeLatest(doUpdateDmPhong, handleUpdateDmPhong);
  yield takeLatest(exportDmPhong, handleExportDmPhong);
  yield takeLatest(importDmPhong, handleImportDmPhong);
  yield takeLatest(getDsPhongTh, handleGetDsPhongTh)

}

function* handleGetListDmPhong(action) {
  try {
    const info = action.payload;

    yield handleCommonGet({ ...info, NHANSU_ID: null }, dispatchGetListDmPhong, doSetListDmPhong);
  } catch (error) {}
}

function* handleCreateDmPhong(action) {
  const info = action.payload;
  yield handleCommonAdd(
    {
      add: {
        ...info.add.data,
        KHOA: 0,
        GIO_BAT_DAU: info.add.data.GIO_BAT_DAU ? dayjs(info.add.data.GIO_BAT_DAU).format("HH:mm") : null,
        GIO_KET_THUC: info.add.data.GIO_KET_THUC ? dayjs(info.add.data.GIO_KET_THUC).format("HH:mm") : null,
      },
      get: info.get,
    },
    dispatchAddDmPhong,
    doGetListDmPhong
  );
}

function* handleUpdateDmPhong(action) {
  const info = action.payload;

  yield handleCommonEdit(
    {
      edit: {
        ...info.edit.data,
        KHOA: info?.edit?.data?.KHOA ? 1 : 0,
        GIO_BAT_DAU: info.edit.data.GIO_BAT_DAU ? dayjs(info.edit.data.GIO_BAT_DAU).format("HH:mm") : null,
        GIO_KET_THUC: info.edit.data.GIO_KET_THUC ? dayjs(info.edit.data.GIO_KET_THUC).format("HH:mm") : null,
      },
      get: info.get,
    },
    dispatchUpateDmPhong,
    doGetListDmPhong
  );
}

function* handleLockDmPhong(action) {
  const info = action.payload;

  yield handleCommonLock(
    {
      ID: info.data,
      Phong: info.lockVal,
      get: info.get,
      ...info,
    },
    dispatchLockDmPhong,
    doGetListDmPhong,
    updatePhong
  );
}

function* handleDeleteDmPhong(action) {
  const info = action.payload;

  yield handleCommonDelete(
    {
      delete: {
        // ID: Array.isArray(info.delete.data)
        //   ? info.delete.data.map((item) => item[fieldDoiTuong.id])
        //   : [info.delete.data[fieldDoiTuong.id]],

        data: Array.isArray(info.delete.data) ? info.delete.data : [info.delete.data],
        partner_code: info.delete.partner_code,
        BENH_VIEN_ID: info.delete.BENH_VIEN_ID,
      },
      get: info.get,
    },
    dispatchDeleteDmPhong,
    doGetListDmPhong
  );

}

function* handleExportDmPhong(action) {
  const info = action.payload
  yield handleCommonExport(
    {
      template: 0,
      partner_code: info.partner_code,
      BENH_VIEN_ID: info.BENH_VIEN_ID,
      limit: 999,
      page: 1
    },
    exportPhong,
    info.name,
  )
}


function* handleImportDmPhong(action) {
  const info = action.payload;
  info.data = convertKeys(info.data, mappingDataExcel, ['Mã loại phòng']);
  yield handleCommonImport(
    {
      import: info,
      get: {
        BENH_VIEN_ID: action.payload.BENH_VIEN_ID,
        partner_code: action.payload.partner_code,
        limit: info.limit || 20,
        page: info.page || 1
      },
    },
    postImportPhong,
    doGetListDmPhong
  );
}

function* handleGetDsPhongTh(action) {
  yield handleCommonGet(
    action.payload,
    postGetDsPhongThucHien,
    getDsPhongThSuccess,
    false,
  )
}
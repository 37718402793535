import { Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import style from "../../../assets/scss/layout/form.module.scss";
import { danhSachLoaiKhoa } from "../../../helpers";
// import i18n, {languageKeys} from "../../../i18n/index.js";
// import {fieldDonViTinh} from "../../KeysCatalog/fieldsCatalog.js";

const FormDMKhoa = ({ isCreateNew, disabled = false }) => {
  
  return (
    <Space
      direction="vertical"
      size={10}
      style={{ width: "100%" }}
      className={style["form"]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <h3>Thông tin cơ bản</h3>
        <div className={style["wrapper"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={"Mã khoa"}
                name={"MA_KHOA"}
                rules={[{ whitespace: false }]}
              >
                <Input placeholder={"Nhập"} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Tên khoa"}
                name={"TEN_KHOA_PHONG"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                <Input placeholder={"Nhập"} disabled={disabled} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={"Loại khoa"}
                name={"LOAI_KHOA"}
                rules={[
                  {
                    required: true,
                    whitespace: false,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Vui lòng chọn !",
                    },
                  ]}
                  options={danhSachLoaiKhoa}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={"Ghi chú"} name={"GHI_CHU"}>
                <Input placeholder={"Nhập"} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Space>
    </Space>
  );
};

export default FormDMKhoa;

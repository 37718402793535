import { Apis } from "../../../constants";
import { common_post, common_post_with_error } from "../../../services/apiInstances";

// Dm thuốc
export const postGetDmHangSx = (dataRequest) => common_post(Apis.dm_lay_ds_hang_sx, dataRequest);
export const postAddHangSx = (dataRequest) => common_post(Apis.dm_them_hang_sx, dataRequest);
export const postEditHangSx = (dataRequest) => common_post(Apis.dm_sua_hang_sx, dataRequest);
export const postDeleteHangSx = (dataRequest) => common_post(Apis.dm_xoa_ds_hang_sx, dataRequest);
export const postExportHangSx = (dataRequest) => common_post(Apis.dm_hang_sx_export, dataRequest);
export const postImportHangSx = (dataRequest) => common_post(Apis.dm_them_hang_sx, dataRequest);
export const postLockHangSx = (dataRequest) => common_post(Apis.dm_khoa_ds_hang_sx, dataRequest);

// DM Đường dùng
export const postGetDmDuongDung = (dataRequest) => common_post(Apis.dm_lay_ds_duong_dung, dataRequest);
export const postAddDuongDung = (dataRequest) => common_post(Apis.dm_them_duong_dung, dataRequest);
export const postEditDuongDung = (dataRequest) => common_post(Apis.dm_sua_duong_dung, dataRequest);
export const postDeleteDuongDung = (dataRequest) => common_post(Apis.dm_xoa_ds_duong_dung, dataRequest);
export const postExportDuongDung = (dataRequest) => common_post(Apis.dm_duong_dung_export, dataRequest);
export const postImportDuongDung = (dataRequest) => common_post(Apis.dm_them_duong_dung, dataRequest);
export const postLockDuongDung = (dataRequest) => common_post(Apis.dm_khoa_ds_duong_dung, dataRequest);

// DM Hoạt chất
export const postGetDmHoatChat = (dataRequest) => common_post(Apis.dm_lay_ds_hoat_chat, dataRequest);
export const postAddHoatChat = (dataRequest) => common_post(Apis.dm_them_hoat_chat, dataRequest);
export const postEditHoatChat = (dataRequest) => common_post(Apis.dm_sua_hoat_chat, dataRequest);
export const postDeleteHoatChat = (dataRequest) => common_post(Apis.dm_xoa_ds_hoat_chat, dataRequest);
export const postExportHoatChat = (dataRequest) => common_post(Apis.dm_hoat_chat_export, dataRequest);
export const postImportHoatChat = (dataRequest) => common_post(Apis.dm_them_hoat_chat, dataRequest);
export const postLockHoatChat = (dataRequest) => common_post(Apis.dm_khoa_ds_hoat_chat, dataRequest);

// DM Xuất khác
export const postGetDmXuatKhac = (dataRequest) =>
  common_post(Apis.dm_lay_danh_sach_ly_do_xuat_huy_phieu_thuoc, dataRequest);
export const postAddXuatKhac = (dataRequest) => common_post(Apis.dm_them_ly_do_xuat_huy_phieu_thuoc, dataRequest);
export const postEditXuatKhac = (dataRequest) => common_post(Apis.dm_sua_ly_do_xuat_huy_phieu_thuoc, dataRequest);
export const postDeleteXuatKhac = (dataRequest) => common_post(Apis.dm_xoa_ly_do_xuat_huy_phieu_thuoc, dataRequest);
export const postExportXuatKhac = (dataRequest) =>
  common_post("api/partner/public/phieu_thuoc/ly_do_xuat_huy/xuatLyDoXuatHuyPhieuThuoc", dataRequest);
export const postImportXuatKhac = (dataRequest) =>
  common_post("api/partner/public/phieu_thuoc/ly_do_xuat_huy/nhapNhieuLyDoXuatHuyPhieuThuoc", dataRequest);

// DM Nguồn
export const postGetDmNguon = (dataRequest) => common_post(Apis.dm_lay_danh_sach_nguon_nhap_thuoc, dataRequest);
export const postAddNguon = (dataRequest) => common_post(Apis.dm_them_nguon_nhap_thuoc, dataRequest);
export const postEditNguon = (dataRequest) => common_post(Apis.dm_sua_nguon_nhap_thuoc, dataRequest);
export const postDeleteNguonc = (dataRequest) => common_post(Apis.dm_xoa_nguon_nhap_thuoc, dataRequest);
export const postExportNguon = (dataRequest) =>
  common_post("api/partner/public/thuoc/nguon_nhap_thuoc/xuatNguonNhapThuoc", dataRequest);
export const postImportNguon = (dataRequest) =>
  common_post("api/partner/public/thuoc/nguon_nhap_thuoc/nhapNguonNhapThuoc", dataRequest);

// DM Cách dùng
export const postGetDmCachDung = (dataRequest) => common_post(Apis.dm_lay_ds_cach_dung, dataRequest);
export const postAddCachDung = (dataRequest) => common_post(Apis.dm_them_cach_dung, dataRequest);
export const postEditCachDung = (dataRequest) => common_post(Apis.dm_sua_cach_dung, dataRequest);
export const postDeleteCachDung = (dataRequest) => common_post(Apis.dm_xoa_cach_dung, dataRequest);
export const postExportCachDung = (dataRequest) => common_post(Apis.dm_export_cach_dung, dataRequest);
export const postImportCachDung = (dataRequest) => common_post(Apis.dm_import_cach_dung, dataRequest);
export const postLockCachDung = (dataRequest) => common_post(Apis.dm_khoa_cach_dung, dataRequest);

// Dm thuốc
export const postLayDsThuoc = (dataRequest) => common_post(Apis.dm_lay_ds_thuoc, dataRequest);
export const postThemMoiThuoc = (dataRequest) => common_post(Apis.dm_them_thuoc_v3, dataRequest);
export const postEditThuoc = (dataRequest) => common_post(Apis.dm_sua_thuoc_v2, dataRequest);
export const postXoaThuoc = (dataRequest) => common_post(Apis.dm_xoa_ds_thuoc, dataRequest);
export const postKhoaThuoc = (dataRequest) => common_post(Apis.dm_khoa_ds_thuoc, dataRequest);
export const postExportThuoc = (dataRequest) => common_post(Apis.dm_thuoc_export, dataRequest);
export const postImportThuoc = (dataRequest) => common_post_with_error(Apis.nhapDanhMucThuoc, dataRequest);

// Dm kho thuốc
export const postGetDmKhoThuoc = (dataRequest) => common_post(Apis.lay_ds_kho_thuoc, dataRequest);
export const postAddKhoThuoc = (dataRequest) => common_post(Apis.them_kho_thuoc, dataRequest);
export const postEditKhoThuoc = (dataRequest) => common_post(Apis.cap_nhat_thong_tin_kho_thuoc, dataRequest);
export const postDeleteKhoThuoc = (dataRequest) =>
  common_post(Apis.xoaNhieuKhoThuoc, dataRequest);
export const postLockKhoThuoc = (dataRequest) =>
  common_post(Apis.khoaKhoThuocTheoDsID, dataRequest);
export const postGetDetailKho = (dataRequest) => common_post(Apis.lay_chi_tiet_kho_thuoc, dataRequest);
export const postGetDsNhanSu = (dataRequest) => common_post(Apis.dm_lay_ds_user, dataRequest);
export const postGetKhoChuyen = (dataRequest) => common_post(Apis.lay_ds_kho_thuoc_hieu_luc, dataRequest);
export const postExportKhoThuoc = (dataRequest) =>
  common_post(Apis.xuatKhoThuoc, dataRequest);
export const postImportKhoThuoc = (dataRequest) =>
  common_post_with_error(Apis.nhapDanhMucKhoThuoc, dataRequest);

// DM NCC
export const postGetDmNhaCungCap = (dataRequest) => common_post(Apis.lay_ds_ncc, dataRequest);
export const postAddNhaCungCap = (dataRequest) => common_post(Apis.them_moi_ncc, dataRequest);
export const postEditNhaCungCap = (dataRequest) => common_post(Apis.sua_thong_tin_ncc, dataRequest);
export const postDeleteNhaCungCap = (dataRequest) =>
  common_post("api/partner/public/nhaCungCap/xoaNhieuNhacungCap", dataRequest);
export const postLockNhaCungCap = (dataRequest) =>
  common_post("api/partner/public/nhaCungCap/khoaNhieuNhacungCap", dataRequest);
export const postGetNhomNCC = (dataRequest) => common_post(Apis.lay_ds_nhom_ncc, dataRequest);
export const postAddNhomNCC = (dataRequest) => common_post(Apis.them_moi_nhom_ncc, dataRequest);
export const postExportDmNhaCungCap = (dataRequest) =>
  common_post("api/partner/public/nhaCungCap/xuatDanhSachNhaCungCap", dataRequest);
export const postImportDmNhaCungCap = (dataRequest) =>
  common_post_with_error("api/partner/public/nhaCungCap/nhapDanhSachNhacungCap", dataRequest);

// Hoa hồng
export const handleGetNhomNguonKhach = (dataRequest) => common_post(Apis.getNhomNguonKhach, dataRequest);
export const handleGetCommissionList = (dataRequest) => common_post(Apis.getCommissionList, dataRequest);


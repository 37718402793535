import React from 'react'
import { Button } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useNavigate, useHistory } from "react-router-dom"
import { paths } from '../../utils/constant'
import style from "./backBtn.module.scss"
const BackBtn = ({onClick = () => {}}) => {
    const navigate = useNavigate()
    const handleClick = () => {
        onClick();
        navigate(`/${paths.HOME}`);
    }
  return (
    <button className={style['back-btn']} onClick={handleClick}>
      <LeftOutlined style={{transform:"scale(0.9)",color:'#6576ff'}}/>
    </button>
  )
}

export default BackBtn
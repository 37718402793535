import React, { useCallback } from "react";
import { Form, Col, Row, Checkbox, Radio, Tree, Button } from "antd";
import style from "./styles/style.module.scss";
import classNames from "classnames";
import dmLoaiDichVu from "../../../../../assets/initData/dataDichVu.json";
import { TreeSelect } from "../../../../../components";
const { Item } = Form;

const convertCheckAllData = (data) => {
  const childData = data.map(it => ({
    ...it,
    key: it.value
  }))
  return [
    {
      title: "Tất cả",
      value: "ALL",
      key: "ALL",
      className: "parent",
      children: childData,
    },
  ];
};

const ClinicServicesProvide = ({ form }) => {
  const formattedDsChuyenKhoaKhamBenh = React.useMemo(() => {
    let data = [];
    dmLoaiDichVu.forEach((d) => {
      if (d?.MA_LOAI?.includes("KB_")) {
        data.push({
          title: d.TEN_LOAI,
          value: d.ID,
          key: d.ID,
          fullValue: d,
        });
      }
    });

    return [
      {
        title: "Tất cả",
        value: "ALL",
        key: "ALL",
        className: "parent",
        children: data,
      },
    ];
  }, [dmLoaiDichVu]);
  //================================================================
  const filterDataLoaiXn = useCallback(
    (type) => {
      return dmLoaiDichVu
        .filter((d) => {
          return d?.MA_LOAI?.includes(type);
        })
        .map((i) => ({ title: i.TEN_LOAI, value: i.ID }));
    },
    [dmLoaiDichVu]
  );

  const dataXetNghiem = convertCheckAllData(filterDataLoaiXn("XN_"));
  const dataDiagnose = convertCheckAllData(filterDataLoaiXn("CDHA_"));
  const thamDoChucNang = convertCheckAllData(filterDataLoaiXn("TDCN_"));

  const handleCheckAll = (values, dataText) => {
    switch (dataText) {
      case fieldInitClinic.DICH_VU_KHAM_BENH:
        if (values && values.includes("ALL")) {
          const allValues = [
            "ALL",
            ...formattedDsChuyenKhoaKhamBenh[0].children.map(
              (item) => item.value
            ),
          ];

          form.setFieldValue(fieldInitClinic.DICH_VU_KHAM_BENH, allValues);
        } else {
          form.setFieldValue(fieldInitClinic.DICH_VU_KHAM_BENH, []);
        }
        break;

      default:
        break;
    }
  };

  //================================================================

  return (
    <>
      <Row justify={"center"} className={style["form-container"]}>
        <Col flex="60%">
          <Row gutter={[10, 10]}>
            <Col
              span={24}
              className={style["center-header"]}
              style={{ marginBottom: 16 }}
            >
              <b>Khởi tạo thông tin dịch vụ</b>
            </Col>
          </Row>
          <Row>
            <Col><span className={style["sub-title"]}>Hãy chọn loại dịch vụ phòng khám của bạn đang cung cấp</span></Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.DICH_VU_KHAM_BENH}>
                {/* <Checkbox.Group> */}
                  <Tree
                    className="clinic-services__tree"
                    checkable
                    expandAction={true}
                    defaultExpandAll
                    onCheck={e => form.setFieldValue(fieldInitClinic.DICH_VU_KHAM_BENH, e)}
                    
                    // checkedKeys={["1", "2", "13", "11"]}
                    // defaultCheckedKeys={["1", "2", "13", "11"]}
                    autoExpandParent={true}
                    treeData={formattedDsChuyenKhoaKhamBenh}
                  />
                  {/* </Checkbox.Group> */}
              </Item>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col span={24}>
              <span className={style["sub-title"]}>Thăm dò chức năng</span>
            </Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.THAM_DO_CHUC_NANG}>
                <Checkbox.Group
                  onChange={(e) => {
                    console.log({ value: e });
                  }}
                >
                <Tree
                  className="clinic-services__tree"
                  checkable
                  expandAction={true}
                  defaultExpandAll
                  onCheck={e => form.setFieldValue(fieldInitClinic.THAM_DO_CHUC_NANG, e)}
                  autoExpandParent={true}
                  treeData={thamDoChucNang}
                />
                </Checkbox.Group>
              </Item>
            </Col>

            <Col span={24}>
              <span className={style["sub-title"]}>Xét nghiệm</span>
            </Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.XET_NGHIEM}>
                <Checkbox.Group>
                <Tree
                  className="clinic-services__tree"
                  checkable
                  expandAction={true}
                  defaultExpandAll
                  onCheck={e => form.setFieldValue(fieldInitClinic.XET_NGHIEM, e)}
                  autoExpandParent={true}
                  treeData={dataXetNghiem}
                />
                </Checkbox.Group>
              </Item>
            </Col>
            <Col span={24}>
              <span className={style["sub-title"]}>Chẩn đoán hình ảnh</span>
            </Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.CHAN_DOAN}>
                <Checkbox.Group onChange={e => console.log({momo: e})}>
                  <Tree
                    className="clinic-services__tree"
                    checkable
                    expandAction={true}
                    defaultExpandAll
                    onCheck={e => form.setFieldValue(fieldInitClinic.CHAN_DOAN, e)}
                    autoExpandParent={true}
                    treeData={dataDiagnose}
                  />
                </Checkbox.Group>
              </Item>
            </Col>

            <Col span={24} style={{ display: "none" }}>
              <span className={style["sub-title"]}>
                Phòng khám của bạn có Thủ thuật, phẫu thuật không?{" "}
              </span>
              <Item name={fieldInitClinic.CO_TIEU_PHAU} initialValue={0}>
                <Radio.Group className={style["radio-wrapper"]}>
                  <Radio value={1}>Có</Radio>
                  <Radio value={0}>không</Radio>
                </Radio.Group>
              </Item>
            </Col>

            <Col span={24} style={{ display: "none" }}>
              <span className={style["sub-title"]}>
                Phòng khám của bạn có bán thuốc không?
              </span>
              <Item name={fieldInitClinic.KE_THUOC} initialValue={1}>
                <Radio.Group className={style["radio-wrapper"]}>
                  <Radio value={1} defaultChecked>
                    Có
                  </Radio>
                  <Radio value={0}>không</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const fieldInitClinic = {
  DICH_VU_KHAM_BENH: "DICH_VU_KHAM_BENH",
  THAM_DO_CHUC_NANG: "THAM_DO_CHUC_NANG",
  XET_NGHIEM: "XET_NGHIEM",
  CHAN_DOAN: "CHAN_DOAN",
  CO_TIEU_PHAU: "CO_TIEU_PHAU",
  KE_THUOC: "KE_THUOC",
};

export default ClinicServicesProvide;

// import { danhMucPermission, decentralization, prmPermission, staffGroup } from "../../../../../constants";
import { decentralization, staffGroup } from "../../../constants";
import { danhMucPermission, prmPermission } from "../../../constants/keys";
import i18n, { languageKeys } from "../../../i18n";
// import i18n, { languageKeys } from "../../../../../i18n";

export const optionLisPropTypes = [
  // {
  //   title: "Quản lý danh mục",
  //   value: "QUAN_LY_DANH_MUC",
  //   children: [
  //     {
  //       title: "Xem",
  //       value: "LIS.QUAN_LY_DANH_MUC.XEM",
  //     },
  //     {
  //       title: "Thêm mới",
  //       value: "LIS.QUAN_LY_DANH_MUC.THEM_MOI",
  //     },
  //     {
  //       title: "Sửa",
  //       value: "LIS.QUAN_LY_DANH_MUC.SUA",
  //     },
  //     {
  //       title: "Xóa",
  //       value: "LIS.QUAN_LY_DANH_MUC.XOA",
  //     },
  //     {
  //       title: "Khóa",
  //       value: "LIS.QUAN_LY_DANH_MUC.KHOA",
  //     },
  //   ],
  // },
  {
    title: i18n.t(languageKeys.benh_pham),
    value: "QUAN_LY_BENH_PHAM",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "LIS.QUAN_LY_BENH_PHAM.XEM",
      },
      {
        title: i18n.t(languageKeys.vien_phi_Tao) + ` SID`,
        value: "LIS.QUAN_LY_BENH_PHAM.TAO_BARCODE",
      },
      {
        title: i18n.t(languageKeys.common_Huy) + ` SID`,
        value: "LIS.QUAN_LY_BENH_PHAM.HUY_BARCODE",
      },
      {
        title: i18n.t(languageKeys.common_In) + ` SID`,
        value: "LIS.QUAN_LY_BENH_PHAM.IN_BARCODE",
      },
      {
        title: i18n.t(languageKeys.gui_sang_lis),
        value: "LIS.QUAN_LY_BENH_PHAM.GUI_SANG_LIS",
      },
      {
        title: i18n.t(languageKeys.gui_chay_lai),
        value: "LIS.QUAN_LY_BENH_PHAM.GUI_CHAY_LAI",
      },
      {
        title: i18n.t(languageKeys.nhan_mau),
        value: "LIS.QUAN_LY_BENH_PHAM.NHAN_MAU",
      },
      {
        title: i18n.t(languageKeys.duyet_mau),
        value: "LIS.QUAN_LY_BENH_PHAM.DUYET_MAU",
      },
      {
        title: i18n.t(languageKeys.luu_thong_tin),
        value: "LIS.QUAN_LY_BENH_PHAM.LUU_TT",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.mau_gui),
    value: "QUAN_LY_MAU_GUI",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "LIS.QUAN_LY_MAU_GUI.XEM",
      },
      {
        title: i18n.t(languageKeys.luu_thong_tin),
        value: "LIS.QUAN_LY_MAU_GUI.DUYET_MAU",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.xet_nghiem),
    value: "XET_NGHIEM",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "LIS.QUAN_LY_XET_NGHIEM.XEM",
      },
      {
        title: i18n.t(languageKeys.nhap_kq),
        value: "LIS.QUAN_LY_XET_NGHIEM.NHAP_KQ",
      },
      {
        title: i18n.t(languageKeys.sua_kq),
        value: "LIS.QUAN_LY_XET_NGHIEM.SUA_KQ",
      },
      {
        title: i18n.t(languageKeys.xac_nhan_huy_kq),
        value: "LIS.QUAN_LY_XET_NGHIEM.XAC_NHAN_KQ",
      },
      {
        title: i18n.t(languageKeys.duyet_huy_kq),
        value: "LIS.QUAN_LY_XET_NGHIEM.DUYET_KQ",
      },
      {
        title: i18n.t(languageKeys.common_In) + " " + i18n.t(languageKeys.field_Ket_qua),
        value: "LIS.QUAN_LY_XET_NGHIEM.IN_KQ",
      },
      {
        title: i18n.t(languageKeys.them_sua_file_dinh_kem),
        value: "LIS.QUAN_LY_XET_NGHIEM.THEM_SUA_FILE",
      },
      {
        title: i18n.t(languageKeys.gui_chay_lai),
        value: "LIS.QUAN_LY_XET_NGHIEM.GUI_CHAY_LAI",
      },
      // {
      //   title: "Trả sau",
      //   value: "LIS.QUAN_LY_XET_NGHIEM.TRA_SAU",
      // },
      // {
      //   title: "Hủy duyệt KQ",
      //   value: "LIS.QUAN_LY_XET_NGHIEM.HUY_DUYET",
      // },
    ],
  },
  // {
  //   title: "Báo cáo",
  //   value: "BAO_CAO",
  //   children: [
  //     {
  //       title: "Xem",
  //       value: "LIS.BAO_CAO.XEM",
  //     },
  //     {
  //       title: "In PDF",
  //       value: "LIS.BAO_CAO.IN_PDF",
  //     },
  //     {
  //       title: "Xuất báo cáo",
  //       value: "LIS.BAO_CAO.XUAT_EXCEL",
  //     },
  //   ],
  // },
];
export const optionRisPropTypes = [
  {
    title: i18n.t(languageKeys.menu_mau_mo_ta_cdha),
    value: "MAU_MO_TA",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "RIS.RIS_NHAP_TAY.MAU_MO_TA.XEM",
      },
      {
        title: i18n.t(languageKeys.common_Them_moi),
        value: "RIS.RIS_NHAP_TAY.MAU_MO_TA.THEM_MOI",
      },
      {
        title: i18n.t(languageKeys.common_sua),
        value: "RIS.RIS_NHAP_TAY.MAU_MO_TA.SUA",
      },
      {
        title: i18n.t(languageKeys.common_Xoa),
        value: "RIS.RIS_NHAP_TAY.MAU_MO_TA.XOA",
      },
      {
        title: i18n.t(languageKeys.field_Hien_thi),
        value: "RIS.RIS_NHAP_TAY.MAU_MO_TA.KHOA",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.field_Hien_thi),
    value: "TRA_KQ",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "RIS.RIS_NHAP_TAY.TRA_KQ.XEM",
      },
      {
        title: i18n.t(languageKeys.tra_kq_thuc_hien),
        value: "RIS.RIS_NHAP_TAY.TRA_KQ.THUC_HIEN_TRA_KQ",
      },
      {
        title: i18n.t(languageKeys.huy_tra),
        value: "RIS.RIS_NHAP_TAY.TRA_KQ.HUY",
      },
      {
        title: i18n.t(languageKeys.common_In) + " " + i18n.t(languageKeys.field_Ket_qua),
        value: "RIS.RIS_NHAP_TAY.TRA_KQ.IN_KQ",
      },
      {
        title: i18n.t(languageKeys.huy_thuc_hien),
        value: "RIS.RIS_NHAP_TAY.TRA_KQ.HUY_THUC_HIEN",
      },
    ],
  },
  // {
  //   title: "Báo cáo",
  //   value: "BAO_CAO",
  //   children: [
  //     {
  //       title: "Xem",
  //       value: "RIS.RIS_NHAP_TAY.BAO_CAO_CDHA.XEM",
  //     },
  //     {
  //       title: "In PDF",
  //       value: "RIS.RIS_NHAP_TAY.BAO_CAO_CDHA.IN_PDF",
  //     },
  //     {
  //       title: "Xuất báo cáo",
  //       value: "RIS.RIS_NHAP_TAY.BAO_CAO_CDHA.XUAT_EXCEL",
  //     },
  //   ],
  // },
];

export const optionCis = [
  {
    title: "Dashboard",
    value: `DASHBOARD`,
  },
  // {
  //   title: i18n.t(languageKeys.menu_Bao_cao),
  //   value: `BAO_CAO`,
  //   children: [
  //     {
  //       title: i18n.t(languageKeys.tab_Bao_cao_tiep_don),
  //       value: "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON",
  //       children: [
  //         {
  //           title: i18n.t(languageKeys.common_xem),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.XEM",
  //         },
  //         {
  //           title: i18n.t(languageKeys.in_pdf),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.IN_PDF",
  //         },
  //         {
  //           title: i18n.t(languageKeys.button_Xuat_excel),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.XUAT_EXCEL",
  //         },
  //       ],
  //     },
  //     {
  //       title: i18n.t(languageKeys.tab_Bao_cao_vien_phi),
  //       value: "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI",
  //       children: [
  //         {
  //           title: i18n.t(languageKeys.common_xem),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI.XEM",
  //         },
  //         {
  //           title: i18n.t(languageKeys.in_pdf),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI.IN_PDF",
  //         },
  //         {
  //           title: i18n.t(languageKeys.button_Xuat_excel),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI.XUAT_EXCEL",
  //         },
  //       ],
  //     },
  //     {
  //       title: i18n.t(languageKeys.tab_Bao_cao_thu_thuat),
  //       value: "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT",
  //       children: [
  //         {
  //           title: i18n.t(languageKeys.common_xem),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT.XEM",
  //         },
  //         {
  //           title: i18n.t(languageKeys.in_pdf),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT.IN_PDF",
  //         },
  //         {
  //           title: i18n.t(languageKeys.button_Xuat_excel),
  //           value: "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT.XUAT_EXCEL",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: i18n.t(languageKeys.menu_Quan_ly_tiep_don),
    value: "QUAN_LY_TIEP_DON",
    children: [
      {
        title: i18n.t(languageKeys.label_Tiep_don),
        value: "CIS.QL_TIEP_DON.DON_TIEP",
        // minBlock: 465,
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_TIEP_DON.DON_TIEP.XEM",
            style: { marginRight: 18 },
          },
          {
            title: i18n.t(languageKeys.tao_tiep_don),
            value: "CIS.QL_TIEP_DON.DON_TIEP.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.sua_tiep_don),
            value: "CIS.QL_TIEP_DON.DON_TIEP.SUA_DICH_VU",
          },
          {
            title: i18n.t(languageKeys.sua_thong_tin_kh),
            value: "CIS.QL_TIEP_DON.DON_TIEP.SUA_BENH_NHAN",
          },
          {
            title: i18n.t(languageKeys.common_Huy),
            value: "CIS.QL_TIEP_DON.DON_TIEP.HUY",
          },
          {
            title: i18n.t(languageKeys.in_pdf),
            value: "CIS.QL_TIEP_DON.DON_TIEP.IN_PDF",
          },
          {
            title: i18n.t(languageKeys.button_Xuat_excel),
            value: "CIS.QL_TIEP_DON.DON_TIEP.XUAT_EXCEL",
          },
          {
            title: i18n.t(languageKeys.tao_phieu_thu),
            value: "CIS.QL_TIEP_DON.DON_TIEP.TAO_PHIEU_THU",
          },
          {
            title: i18n.t(languageKeys.huy_phieu_thu),
            value: "CIS.QL_TIEP_DON.DON_TIEP.HUY_PHIEU_THU",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.label_quan_ly_lich_hen),
        value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI",
        minBlock: 550,
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.XEM",
            style: { marginRight: 88 },
          },
          {
            title: i18n.t(languageKeys.title_Tao_cuoc_hen),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.THEM_MOI",
            style: { marginRight: 22 },
          },
          {
            title: i18n.t(languageKeys.tiepdon_chinh_sua_cuoc_hen),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.SUA_DICH_VU",
          },
          {
            title: i18n.t(languageKeys.xac_nhan_lich_hen),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.XAC_NHAN",
          },
          {
            title: i18n.t(languageKeys.sua_thong_tin_kh),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.SUA_BENH_NHAN",
          },
          {
            title: i18n.t(languageKeys.huy_cuoc_hen),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.HUY",
          },
          {
            title: i18n.t(languageKeys.label_Tiep_don),
            value: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.TIEP_DON",
          },
        ],
      },
    ],
  },
  {
    title: i18n.t(languageKeys.menu_Quan_ly_vien_phi),
    value: "QUAN_LY_VIEN_PHI",
    children: [
      {
        title: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
        value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.XEM",
          },
          {
            title: i18n.t(languageKeys.txt_thanh_toan),
            value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.THANH_TOAN",
          },
          {
            title: i18n.t(languageKeys.field_hoan_tien),
            value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.HOAN_TIEN",
          },
          {
            title: i18n.t(languageKeys.in_pdf),
            value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.IN_PDF",
          },
          {
            title: i18n.t(languageKeys.vienphi_xoa_dich_vu),
            value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.XOA_DICH_VU",
          },
          {
            title: i18n.t(languageKeys.huy_phieu),
            value: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.HUY",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.label_So_hoa_don),
        value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.XEM",
          },
          {
            title: i18n.t(languageKeys.common_them_moi),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.common_Chinh_sua),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.SUA",
          },
          {
            title: i18n.t(languageKeys.common_Xoa),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.XOA",
          },
          {
            title: i18n.t(languageKeys.in_bao_cao),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.IN_PDF",
          },
          {
            title: i18n.t(languageKeys.button_Xuat_excel),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.XUAT_EXCEL",
          },
          {
            title: i18n.t(languageKeys.In_hoa_don),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.IN_HOA_DON",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.quan_ly_cong_no),
        value: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.XEM",
          },
          {
            title: i18n.t(languageKeys.txt_thanh_toan),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.THANH_TOAN",
          },
          {
            title: i18n.t(languageKeys.field_In_phieu),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.IN_PDF",
          },
          {
            title: i18n.t(languageKeys.field_hoan_tien),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.HOAN_TIEN",
          },
          {
            title: i18n.t(languageKeys.huy_phieu),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.HUY",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.quan_ly_tam_ung),
        value: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.XEM",
          },
          {
            title: i18n.t(languageKeys.tao_phieu_thu_tam_ung),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.field_hoan_tien),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.HOAN_TIEN",
          },
          {
            title: i18n.t(languageKeys.field_Thanh_toan),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.THANH_TOAN",
          },
          {
            title: i18n.t(languageKeys.huy_phieu_thu_tam_ung),
            value: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.HUY",
          },
        ],
      },
    ],
  },
  {
    title: i18n.t(languageKeys.quan_ly_khach_hang),
    value: "QL_BENH_NHAN",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "CIS.QL_BENH_NHAN.XEM",
      },
      {
        title: i18n.t(languageKeys.common_sua),
        value: "CIS.QL_BENH_NHAN.SUA",
      },
      {
        title: i18n.t(languageKeys.tip_tai_xuong),
        value: "CIS.QL_BENH_NHAN.TAI_XUONG",
      },
      {
        title: i18n.t(languageKeys.in_pdf),
        value: "CIS.QL_BENH_NHAN.IN_PDF",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.menu_Quan_ly_kham_benh),
    value: "QUAN_LY_KHAM_BENH",
    children: [
      // {
      //   title: i18n.t(languageKeys.field_Chi_so_sinh_ton),
      //   value: "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON",
      //   children: [
      //     {
      //       title: i18n.t(languageKeys.common_xem),
      //       value: "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.XEM",
      //     },
      //     {
      //       title: i18n.t(languageKeys.common_Nhap) + " chỉ số sinh tồn",
      //       value: "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.SUA",
      //     },
      //   ],
      // },
      {
        title: i18n.t(languageKeys.field_Thong_tin_kham),
        value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.XEM",
          },
          {
            title: i18n.t(languageKeys.nhap_thong_tin_kham),
            value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.SUA",
          },
          {
            title: i18n.t(languageKeys.common_Nhap) + i18n.t(languageKeys.field_Chi_so_sinh_ton).toLowerCase(),
            value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.THEM",
            // value: "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.SUA",
          },
          {
            title: i18n.t(languageKeys.common_Them) + " " + i18n.t(languageKeys.mau_thong_tin).toLowerCase(),
            value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.THEM_MAU",
          },
          {
            title: i18n.t(languageKeys.xoa) + " " + i18n.t(languageKeys.mau_thong_tin).toLowerCase(),
            value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.XOA_MAU",
          },
          {
            title: i18n.t(languageKeys.field_In_phieu),
            value: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.IN_PHIEU",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.Chi_dinh_CLS),
        value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS.XEM",
          },
          {
            title: i18n.t(languageKeys.common_them_moi),
            value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.common_sua),
            value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS.SUA",
          },
          {
            title: i18n.t(languageKeys.xoa),
            value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS.XOA",
          },
          {
            title: i18n.t(languageKeys.in_pdf),
            value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS.IN_PDF",
          },
          {
            title: i18n.t(languageKeys.thuc_hien),
            value: "CIS.QL_KHAM_BENH.CHI_DINH_CLS.THUC_HIEN",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.Ke_don_thuoc),
        value: "CIS.QL_KHAM_BENH.KE_DON_THUOC",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_KHAM_BENH.KE_DON_THUOC.XEM",
          },
          {
            title: i18n.t(languageKeys.sua_don_thuoc),
            value: "CIS.QL_KHAM_BENH.KE_DON_THUOC.SUA",
          },
          {
            title: i18n.t(languageKeys.common_Them_moi) + " " + i18n.t(languageKeys.Don_thuoc).toLowerCase(),
            value: "CIS.QL_KHAM_BENH.KE_DON_THUOC.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.common_huy_don),
            value: "CIS.QL_KHAM_BENH.KE_DON_THUOC.HUY",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.thuc_hien_thu_thuat),
        value: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.XEM",
          },
          {
            title: i18n.t(languageKeys.thuc_hien),
            value: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.THUC_HIEN",
          },
          {
            title: i18n.t(languageKeys.tra_ket_qua),
            value: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.THUC_HIEN_TRA_KQ",
          },
          {
            title: i18n.t(languageKeys.xem_tuong_trinh),
            value: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.XEM_TT",
          },
          {
            title: i18n.t(languageKeys.huy_kq),
            value: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.HUY",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.thuc_hien_can_lam_sang),
        value: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.XEM",
          },
          {
            title: i18n.t(languageKeys.common_them_moi),
            value: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.in_pdf),
            value: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.IN_PDF",
          },
          {
            title: i18n.t(languageKeys.tra_ket_qua),
            value: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.THUC_HIEN_TRA_KQ",
          },
          {
            title: i18n.t(languageKeys.xoa),
            value: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.XOA",
          },
        ],
      },
    ],
  },
  {
    title: i18n.t(languageKeys.menu_phauthuat_thuthuat),
    value: "PHAU_THUAT_THU_THUAT",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "CIS.PHAU_THUAT_THU_THUAT.XEM",
      },
      {
        title: i18n.t(languageKeys.thuc_hien),
        value: "CIS.PHAU_THUAT_THU_THUAT.THUC_HIEN",
      },
      {
        title: i18n.t(languageKeys.tra_ket_qua),
        value: "CIS.PHAU_THUAT_THU_THUAT.THUC_HIEN_TRA_KQ",
      },
      {
        title: i18n.t(languageKeys.xem_tuong_trinh),
        value: "CIS.PHAU_THUAT_THU_THUAT.XEM_TT",
      },
      {
        title: i18n.t(languageKeys.huy_kq),
        value: "CIS.PHAU_THUAT_THU_THUAT.HUY",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.quan_ly_goi_dich_vu),
    value: "QUAN_LY_GOI_DV",
    children: [
      {
        title: i18n.t(languageKeys.danh_sach_goi_dich_vu),
        value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.XEM",
          },
          {
            title: i18n.t(languageKeys.common_Them_moi),
            value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.common_Chinh_sua),
            value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.SUA",
          },
          {
            title: i18n.t(languageKeys.tam_ngung),
            value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.KHOA",
          },
          {
            title: i18n.t(languageKeys.sao_chep),
            value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.COPY",
          },
          {
            title: i18n.t(languageKeys.xoa),
            value: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.XOA",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.ql_dang_ky_goi_dich_vu),
        value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.XEM",
          },
          {
            title: i18n.t(languageKeys.dang_ky_goi_dv),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.dang_ky_goi_lieu_trinh),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.THEM_MOI_LIEU_TRINH",
          },
          {
            title: i18n.t(languageKeys.common_Chinh_sua),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA",
          },
          {
            title: i18n.t(languageKeys.chinh_sua_ke_hoach_su_dung),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA_KE_HOACH_SD",
          },
          {
            title: i18n.t(languageKeys.chinh_sua_goi_lieu_trinh),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA_LIEU_TRINH",
          },
          {
            title: i18n.t(languageKeys.in_file),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.IN_FILE",
          },
          {
            title: i18n.t(languageKeys.tam_ngung),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.KHOA",
          },
          {
            title: i18n.t(languageKeys.common_kich_hoat),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.ACTIVE",
          },
          {
            title: i18n.t(languageKeys.common_Huy),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.XOA",
          },
        ],
      },
      {
        title: i18n.t(languageKeys.ql_su_dung_goi_dich_vu),
        value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV",
        children: [
          {
            title: i18n.t(languageKeys.common_xem),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.XEM",
          },
          {
            title: i18n.t(languageKeys.dang_ky_goi_dv),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.THEM_MOI",
          },
          {
            title: i18n.t(languageKeys.dang_ky_goi_lieu_trinh),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.THEM_MOI_LIEU_TRINH",
          },
          {
            title: i18n.t(languageKeys.common_Chinh_sua),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.SUA",
          },
          {
            title: i18n.t(languageKeys.common_kich_hoat),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.ACTIVE",
          },
          {
            title: i18n.t(languageKeys.tam_ngung),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.KHOA",
          },
          {
            title: i18n.t(languageKeys.hoan_thanh_goi),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.COMPLETE",
          },
          {
            title: i18n.t(languageKeys.vienphi_tat_toan),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.TAT_TOAN",
          },
          {
            title: i18n.t(languageKeys.in_file),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.IN_FILE",
          },
          {
            title: i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_ngoai_goi),
            value: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.CHI_DINH_VAT_TU",
          },
        ],
      },
    ],
  },
];

export const optionChildrenPRM = [
  {
    title: i18n.t(languageKeys.common_xem),
    value: "XEM",
  },
  {
    title: i18n.t(languageKeys.them),
    value: "THEM",
  },
  {
    title: i18n.t(languageKeys.common_Chinh_sua),
    value: "SUA",
  },
  {
    title: i18n.t(languageKeys.in_va_tai_xuong),
    value: "IN_PDF",
  },
  {
    title: i18n.t(languageKeys.common_Xoa),
    value: "XOA",
  },
];

export const optionChildrenDM = [
  {
    title: i18n.t(languageKeys.common_xem),
    value: "XEM",
  },
  {
    title: i18n.t(languageKeys.tao_moi),
    value: "THEM",
  },
  {
    title: i18n.t(languageKeys.common_Chinh_sua),
    value: "SUA",
  },
  {
    title: i18n.t(languageKeys.tip_tai_xuong),
    value: "XUAT_EXCEL",
  },
  {
    title: i18n.t(languageKeys.field_Hien_thi),
    value: "KHOA",
  },
  {
    title: i18n.t(languageKeys.xoa),
    value: "XOA",
  },
];

export const optionChildrenDM_LLV = [
  {
    title: i18n.t(languageKeys.common_xem),
    value: "XEM",
  },
  {
    title: i18n.t(languageKeys.common_Them_moi),
    value: "THEM",
  },
  {
    title: i18n.t(languageKeys.common_Chinh_sua),
    value: "SUA",
  },
  {
    title: i18n.t(languageKeys.Cai_dat_llv),
    value: "CAI_DAT_LLV",
  },
  {
    title: i18n.t(languageKeys.field_Hien_thi),
    value: "KHOA",
  },
  {
    title: i18n.t(languageKeys.xoa),
    value: "XOA",
  },
];

export const decentralizationList = [
  {
    label: i18n.t(languageKeys.data_Quan_tri),
    value: decentralization.DANH_MUC,
  },
  {
    label: "CIS",
    value: decentralization.CIS,
  },
  {
    label: "LIS",
    value: decentralization.LIS,
  },
  {
    label: "RIS",
    value: decentralization.RIS,
  },
  {
    label: i18n.t(languageKeys.menu_Bao_cao),
    value: decentralization.BAO_CAO,
  },
  {
    label: i18n.t(languageKeys.tab_duoc),
    value: decentralization.DUOC_NGOAI_TRU,
  },
  {
    label: "PRM",
    value: decentralization.PRM,
  },
  {
    label: "Khám đoàn",
    value: decentralization.KHAM_DOAN,
  },
  {
    label: i18n.t(languageKeys.vat_tu),
    value: decentralization.VAT_TU,
  },
];

export const optionReportPropTypes = [
  {
    title: i18n.t(languageKeys.receptionReport),
    value: "BAO_CAO_TIEP_DON",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "BAO_CAO.BAO_CAO_TIEP_DON.XEM",
      },
      {
        title: i18n.t(languageKeys.in_pdf),
        value: "BAO_CAO.BAO_CAO_TIEP_DON.IN_PDF",
      },
      {
        title: i18n.t(languageKeys.button_Xuat_excel),
        value: "BAO_CAO.BAO_CAO_TIEP_DON.XUAT_EXCEL",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.salesReport),
    value: "BAO_CAO_DOANH_THU",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "BAO_CAO.BAO_CAO_DOANH_THU.XEM",
      },
      {
        title: i18n.t(languageKeys.in_pdf),
        value: "BAO_CAO.BAO_CAO_DOANH_THU.IN_PDF",
      },
      {
        title: i18n.t(languageKeys.button_Xuat_excel),
        value: "BAO_CAO.BAO_CAO_DOANH_THU.XUAT_EXCEL",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.commissionReport),
    value: "BAO_CAO_HOA_HONG",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "BAO_CAO.BAO_CAO_HOA_HONG.XEM",
      },
      {
        title: i18n.t(languageKeys.in_pdf),
        value: "BAO_CAO.BAO_CAO_HOA_HONG.IN_PDF",
      },
      {
        title: i18n.t(languageKeys.button_Xuat_excel),
        value: "BAO_CAO.BAO_CAO_HOA_HONG.XUAT_EXCEL",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.pharmacyReport),
    value: "BAO_CAO_DUOC",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),

        value: "BAO_CAO.BAO_CAO_DUOC.XEM",
      },
      {
        title: i18n.t(languageKeys.in_pdf),

        value: "BAO_CAO.BAO_CAO_DUOC.IN_PDF",
      },
      {
        title: i18n.t(languageKeys.button_Xuat_excel),

        value: "BAO_CAO.BAO_CAO_DUOC.XUAT_EXCEL",
      },
    ],
  },
  {
        title: i18n.t(languageKeys.risReport),
    value: "BAO_CAO_RIS",
    children: [
      {
                title: i18n.t(languageKeys.common_xem),
        value: "BAO_CAO.BAO_CAO_RIS.XEM",
      },
      {
                title: i18n.t(languageKeys.in_pdf),
        value: "BAO_CAO.BAO_CAO_RIS.IN_PDF",
      },
      {
                title: i18n.t(languageKeys.button_Xuat_excel),
        value: "BAO_CAO.BAO_CAO_RIS.XUAT_EXCEL",
      },
    ],
  },
  {
                title: i18n.t(languageKeys.lisReport),
    value: "BAO_CAO_LIS",
    children: [
      {
                        title: i18n.t(languageKeys.common_xem),
        value: "BAO_CAO.BAO_CAO_LIS.XEM",
      },
      {
                      title: i18n.t(languageKeys.in_pdf),
        value: "BAO_CAO.BAO_CAO_LIS.IN_PDF",
      },
      {
                      title: i18n.t(languageKeys.button_Xuat_excel),
        value: "BAO_CAO.BAO_CAO_LIS.XUAT_EXCEL",
      },
    ],
  },
];

export const optionSupplyPropTypes = [
  {
    title: i18n.t(languageKeys.ban_thuoc_vat_tu),
    value: "BAN_THUOC_VT",
    children: [
      {
        title: "Xem",
        value: "VAT_TU.BAN_THUOC_VT.XEM",
      },
      {
        title: "Sửa đơn",
        value: "VAT_TU.BAN_THUOC_VT.SUA",
      },
      {
        title: "In phiếu",
        value: "VAT_TU.BAN_THUOC_VT.IN",
      },
      {
        title: "Xuất kho/Duyệt đơn/Thanh toán",
        value: "VAT_TU.BAN_THUOC_VT.DUYET",
      },
      {
        title: "Hủy xuất kho/Hủy duyệt/ Hủy thanh toán",
        value: "VAT_TU.BAN_THUOC_VT.HUY",
      },
      {
        title: "Bán cho khách vãng lai",
        value: "VAT_TU.BAN_THUOC_VT.BAN_VANG_LAI",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.ton_kho),
    value: "TON_KHO_VT",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "VAT_TU.TON_KHO_VT.XEM",
      },
      {
        title: i18n.t(languageKeys.them_moi_kho),
        value: "VAT_TU.TON_KHO_VT.THEM",
      },
      {
        title: i18n.t(languageKeys.sua_kho),
        value: "VAT_TU.TON_KHO_VT.SUA_KHO",
      },
      {
        title: i18n.t(languageKeys.sua_thong_tin_thuoc),
        value: "VAT_TU.TON_KHO_VT.SUA_THUOC",
      },
      {
        title: i18n.t(languageKeys.duyet_khi_chuyen_cac_kho),
        value: "VAT_TU.TON_KHO_VT.DUYET",
      },
      {
        title: i18n.t(languageKeys.field_Hien_thi) + " " + i18n.t(languageKeys.menu_Kho_thuoc).toLowerCase(),
        value: "VAT_TU.TON_KHO_VT.KHOA",
      },
    ],
  },
  {
        title: i18n.t(languageKeys.kiem_ke),
        value: "KIEM_KE",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "VAT_TU.KIEM_KE.XEM",
      },
      {
        title: i18n.t(languageKeys.tao_phieu_kiem_ke),
        value: "VAT_TU.KIEM_KE.THEM",
      },
      {
        title: i18n.t(languageKeys.duyet_phieu),
        value: "VAT_TU.KIEM_KE.DUYET_PHIEU",
      },
      {
        title: i18n.t(languageKeys.common_Chinh_sua),
        value: "VAT_TU.KIEM_KE.SUA",
      },
      {
        title: i18n.t(languageKeys.common_Xoa),
        value: "VAT_TU.KIEM_KE.XOA",
      },
      {
        title: i18n.t(languageKeys.common_Xuat_file),
        value: "VAT_TU.KIEM_KE.XUAT_EXCEL",
      },
      {
        title: i18n.t(languageKeys.in_file),
        value: "VAT_TU.KIEM_KE.IN_PHIEU",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
    value: "PHIEU_XUAT_NHAP_VT",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.XEM",
      },
      {
        title: i18n.t(languageKeys.field_tao_phieu_nhap),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.THEM_NHAP",
      },
      {
        title: i18n.t(languageKeys.tao_phieu_xuat),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.THEM_XUAT",
      },
      {
        title: i18n.t(languageKeys.sua_phieu_nhap),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.SUA",
      },
      {
        title: i18n.t(languageKeys.duyet_phieu),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.DUYET_PHIEU",
      },
      {
        title: i18n.t(languageKeys.duoc_huy_duyet),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.HUY_DUYET",
      },
      {
        title: i18n.t(languageKeys.common_Xoa),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.XOA",
      },
      {
        title: i18n.t(languageKeys.field_In_phieu),
        value: "VAT_TU.PHIEU_XUAT_NHAP_VT.IN_PHIEU",
      },
    ],
  },
  {
    title: i18n.t(languageKeys.loai_phieu_dieu_chinh),
    value: "PHIEU_DIEU_CHINH",
    children: [
      {
        title: i18n.t(languageKeys.common_xem),
        value: "VAT_TU.PHIEU_DIEU_CHINH.XEM",
      },
      {
        title: i18n.t(languageKeys.tao_phieu_dieu_chinh_tang),
        value: "VAT_TU.PHIEU_DIEU_CHINH.THEM_TANG",
      },
      {
        title: i18n.t(languageKeys.tao_phieu_dieu_chinh_giam),
        value: "VAT_TU.PHIEU_DIEU_CHINH.THEM_GIAM",
      },
      {
        title: i18n.t(languageKeys.duyet),
        value: "VAT_TU.PHIEU_DIEU_CHINH.DUYET",
      },
      {
        title: i18n.t(languageKeys.duoc_huy_duyet),
        value: "VAT_TU.PHIEU_DIEU_CHINH.HUY_DUYET",
      },
      {
        title: i18n.t(languageKeys.common_Chinh_sua),
        value: "VAT_TU.PHIEU_DIEU_CHINH.SUA",
      },
      {
        title: i18n.t(languageKeys.in_file),
        value: "VAT_TU.PHIEU_DIEU_CHINH.IN_PHIEU",
      },
      {
        title: i18n.t(languageKeys.common_Xoa),
        value: "VAT_TU.PHIEU_DIEU_CHINH.XOA",
      },
    ],
  },
  // {
  //   title: i18n.t(languageKeys.nha_cung_cap),
  //   value: "NHA_CUNG_CAP_VT",
  //   children: [],
  // },
];

export const initPermissionPRM = (function () {
  let result = [];

  Object.values(prmPermission).forEach((key) => {
    optionChildrenPRM.forEach((childKey) => {
      result.push(`${key}.${childKey.value}`);
    });
  });
  return result;
})();

export const initPermissionDM = (function () {
  let result = [];

  Object.values(danhMucPermission).forEach((key) => {
    if ([danhMucPermission["LICH_LAM_VIEC"]].includes(key)) {
      optionChildrenDM_LLV.forEach((childKey) => {
        result.push(`${key}.${childKey.value}`);
      });
    } else {
      optionChildrenDM.forEach((childKey) => {
        result.push(`${key}.${childKey.value}`);
      });
    }
  });
  return result;
})();

export const allPermissions = [
  "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.XEM",
  "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.THEM",
  "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.SUA",
  "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.XUAT_EXCEL",
  "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.KHOA",
  "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.XOA",
  "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS.XEM",
  "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS.THEM",
  "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS.SUA",
  "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS.XUAT_EXCEL",
  "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS.KHOA",
  "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS.XOA",
  "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
  "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.THEM",
  "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.SUA",
  "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.CAI_DAT_LLV",
  "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.KHOA",
  "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XOA",
  "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS.XEM",
  "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS.THEM",
  "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS.SUA",
  "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS.XUAT_EXCEL",
  "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS.KHOA",
  "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS.XOA",
  "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS.XEM",
  "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS.THEM",
  "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS.SUA",
  "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS.XUAT_EXCEL",
  "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS.KHOA",
  "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS.XOA",

  "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS.XEM",
  "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS.THEM",
  "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS.SUA",
  "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS.XUAT_EXCEL",
  "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS.KHOA",
  "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS.XOA",

  "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS.XEM",
  "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS.THEM",
  "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS.SUA",
  "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS.XUAT_EXCEL",
  "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS.KHOA",
  "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS.XOA",
  "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.XEM",
  "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.THEM",
  "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.SUA",
  "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.XUAT_EXCEL",
  "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.KHOA",
  "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.XOA",
  "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.XEM",
  "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.THEM",
  "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.SUA",
  // "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.XUAT_EXCEL",
  "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.KHOA",
  "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.XOA",
  "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.XEM",
  "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.THEM",
  "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.SUA",
  "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.XUAT_EXCEL",
  "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.KHOA",
  "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.XOA",
  "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB.XEM",
  "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB.THEM",
  "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB.SUA",
  "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB.XUAT_EXCEL",
  "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB.KHOA",
  "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB.XOA",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG.XEM",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG.THEM",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG.SUA",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG.XUAT_EXCEL",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG.KHOA",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG.XOA",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.XEM",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.THEM",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.SUA",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.XUAT_EXCEL",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.KHOA",
  "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.XOA",
  "MANAGEMENT.DUOC_NGOAI_TRU.XEM",
  "MANAGEMENT.DUOC_NGOAI_TRU.THEM",
  "MANAGEMENT.DUOC_NGOAI_TRU.SUA",
  "MANAGEMENT.DUOC_NGOAI_TRU.XOA",
  "MANAGEMENT.DUOC_NGOAI_TRU.KHOA",
  "MANAGEMENT.DUOC_NGOAI_TRU.XUAT_EXCEL",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.IN_PDF",
  "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.HUY",
  "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.HUY",

  "CIS.DASHBOARD",
  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.XEM",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.SUA",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.XEM",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.XEM",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.THEM_MOI",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.SUA_DICH_VU",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.SUA_BENH_NHAN",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.XAC_NHAN",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.HUY",
  "CIS.QL_TIEP_DON.DON_TIEP.XEM",
  "CIS.QL_TIEP_DON.DON_TIEP.THEM_MOI",
  "CIS.QL_TIEP_DON.DON_TIEP.SUA_DICH_VU",
  "CIS.QL_TIEP_DON.DON_TIEP.SUA_BENH_NHAN",
  "CIS.QL_TIEP_DON.DON_TIEP.HUY",
  "CIS.QL_TIEP_DON.DON_TIEP.TAO_PHIEU_THU",
  "CIS.QL_TIEP_DON.DON_TIEP.HUY_PHIEU_THU",

  // "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.XEM",
  "CIS.QL_KHAM_BENH.KE_DON_THUOC.XEM",
  "CIS.QL_KHAM_BENH.CHI_DINH_CLS.SUA",
  "CIS.QL_KHAM_BENH.CHI_DINH_CLS.XOA",
  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.THEM_MOI",
  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.XOA",
  "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.XEM",
  "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.IN_PDF",
  "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.XUAT_EXCEL",
  "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI.XEM",
  "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI.IN_PDF",
  "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI.XUAT_EXCEL",
  "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT.XEM",
  "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT.IN_PDF",
  "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT.XUAT_EXCEL",
  "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.XEM",
  "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.THANH_TOAN",
  "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.HOAN_TIEN",
  "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.XOA_DICH_VU",
  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.SUA",
  "CIS.QL_BENH_NHAN.XEM",
  "CIS.QL_BENH_NHAN.SUA",
  "CIS.QL_BENH_NHAN.TAI_XUONG",
  "CIS.QL_BENH_NHAN.IN_PDF",
  "CIS.QL_KHAM_BENH.CHI_DINH_CLS.XEM",
  "CIS.QL_KHAM_BENH.CHI_DINH_CLS.THEM_MOI",
  "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.SUA",
  "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.XEM",
  "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.THUC_HIEN",
  "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.HUY",
  "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.THUC_HIEN_TRA_KQ",
  "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT.XEM_TT",
  "CIS.QL_KHAM_BENH.KE_DON_THUOC.THEM_MOI",
  "CIS.QL_KHAM_BENH.KE_DON_THUOC.SUA",
  "CIS.QL_KHAM_BENH.KE_DON_THUOC.HUY",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.THEM",

  "LIS.QUAN_LY_DANH_MUC.XEM",
  "LIS.QUAN_LY_DANH_MUC.THEM_MOI",
  "LIS.QUAN_LY_DANH_MUC.SUA",
  "LIS.QUAN_LY_DANH_MUC.XOA",
  "LIS.QUAN_LY_DANH_MUC.KHOA",
  "LIS.QUAN_LY_BENH_PHAM.XEM",
  "LIS.QUAN_LY_BENH_PHAM.TAO_BARCODE",
  "LIS.QUAN_LY_BENH_PHAM.HUY_BARCODE",
  "LIS.QUAN_LY_BENH_PHAM.IN_BARCODE",
  "LIS.QUAN_LY_BENH_PHAM.GUI_SANG_LIS",
  "LIS.QUAN_LY_BENH_PHAM.NHAN_MAU",
  "LIS.QUAN_LY_BENH_PHAM.DUYET_MAU",
  "LIS.QUAN_LY_BENH_PHAM.LUU_TT",
  "LIS.QUAN_LY_XET_NGHIEM.XEM",
  "LIS.QUAN_LY_XET_NGHIEM.NHAP_KQ",
  "LIS.QUAN_LY_XET_NGHIEM.SUA_KQ",
  "LIS.QUAN_LY_XET_NGHIEM.XAC_NHAN_KQ",
  "LIS.QUAN_LY_XET_NGHIEM.DUYET_KQ",
  "LIS.QUAN_LY_XET_NGHIEM.IN_KQ",
  "LIS.QUAN_LY_XET_NGHIEM.THEM_SUA_FILE",
  "LIS.QUAN_LY_XET_NGHIEM.GUI_CHAY_LAI",
  "LIS.QUAN_LY_XET_NGHIEM.TRA_SAU",
  "LIS.QUAN_LY_XET_NGHIEM.HUY_DUYET",
  "LIS.QUAN_LY_MAU_GUI.XEM",
  "LIS.QUAN_LY_MAU_GUI.DUYET_GUI_MAU",
  "LIS.BAO_CAO.XEM",
  "LIS.BAO_CAO.IN_PDF",
  "LIS.BAO_CAO.XUAT_EXCEL",

  "RIS.RIS_NHAP_TAY.MAU_MO_TA.XEM",
  "RIS.RIS_NHAP_TAY.MAU_MO_TA.THEM_MOI",
  "RIS.RIS_NHAP_TAY.MAU_MO_TA.SUA",
  "RIS.RIS_NHAP_TAY.MAU_MO_TA.XOA",
  "RIS.RIS_NHAP_TAY.MAU_MO_TA.KHOA",
  "RIS.RIS_NHAP_TAY.TRA_KQ.XEM",
  "RIS.RIS_NHAP_TAY.TRA_KQ.THUC_HIEN_TRA_KQ",
  "RIS.RIS_NHAP_TAY.TRA_KQ.HUY",
  "RIS.RIS_NHAP_TAY.TRA_KQ.IN_KQ",
  "RIS.RIS_NHAP_TAY.BAO_CAO_CDHA.XEM",
  "RIS.RIS_NHAP_TAY.BAO_CAO_CDHA.IN_PDF",
  "RIS.RIS_NHAP_TAY.BAO_CAO_CDHA.XUAT_EXCEL",

  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.XEM",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.TAO_PHIEU_NHAP",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.TAO_PHIEU_XUAT",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.SUA_PHIEU_XUAT",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.SUA_PHIEU_NHAP",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.DUYET_PHIEU",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.HUY_DUYET",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.XOA",
  "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.IN_PHIEU",
  "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.XEM",
  "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.SUA",
  "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.IN",
  "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.DUYET_XUAT_THANH_TOAN",
  "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.HUY",
  "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.BAN_VANG_LAI",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.XEM",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.IN_THE",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.XUAT_THE",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.THEM",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.XEM",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.XUAT_EXCEL",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.DIEU_CHINH",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.THANH_TOAN",
  "DUOC.QL_DUOC_NGOAITRU.BAO_CAO.XEM",
  "DUOC.QL_DUOC_NGOAITRU.BAO_CAO.IN_PDF",
  "DUOC.QL_DUOC_NGOAITRU.BAO_CAO.XUAT_EXCEL",

  "PRM.BAO_CAO_DOANH_THU.XEM",
  "PRM.BAO_CAO_DOANH_THU.THEM",
  "PRM.BAO_CAO_DOANH_THU.SUA",
  "PRM.BAO_CAO_DOANH_THU.IN_PDF",
  "PRM.BAO_CAO_DOANH_THU.XOA",
  "PRM.BAO_CAO_KH_CA_NHAN.XEM",
  "PRM.BAO_CAO_KH_CA_NHAN.THEM",
  "PRM.BAO_CAO_KH_CA_NHAN.SUA",
  "PRM.BAO_CAO_KH_CA_NHAN.IN_PDF",
  "PRM.BAO_CAO_KH_CA_NHAN.XOA",
  "PRM.BAO_CAO_KH_DOANH_NGHIEP.XEM",
  "PRM.BAO_CAO_KH_DOANH_NGHIEP.THEM",
  "PRM.BAO_CAO_KH_DOANH_NGHIEP.SUA",
  "PRM.BAO_CAO_KH_DOANH_NGHIEP.IN_PDF",
  "PRM.BAO_CAO_KH_DOANH_NGHIEP.XOA",
  "PRM.BAO_CAO_CUOC_GOI.XEM",
  "PRM.BAO_CAO_CUOC_GOI.THEM",
  "PRM.BAO_CAO_CUOC_GOI.SUA",
  "PRM.BAO_CAO_CUOC_GOI.IN_PDF",
  "PRM.BAO_CAO_CUOC_GOI.XOA",
  "PRM.BAO_CAO_HIEU_QUA_VOUCHER.XEM",
  "PRM.BAO_CAO_HIEU_QUA_VOUCHER.THEM",
  "PRM.BAO_CAO_HIEU_QUA_VOUCHER.SUA",
  "PRM.BAO_CAO_HIEU_QUA_VOUCHER.IN_PDF",
  "PRM.BAO_CAO_HIEU_QUA_VOUCHER.XOA",
  "PRM.DASHBOARD.XEM",
  "PRM.DASHBOARD.THEM",
  "PRM.DASHBOARD.SUA",
  "PRM.DASHBOARD.XOA",
  "PRM.DASHBOARD.IN_PDF",
  "PRM.QUAN_LY_CUOC_GOI.XEM",
  "PRM.QUAN_LY_CUOC_GOI.THEM",
  "PRM.QUAN_LY_CUOC_GOI.SUA",
  "PRM.QUAN_LY_CUOC_GOI.IN_PDF",
  "PRM.QUAN_LY_CUOC_GOI.XOA",
  "PRM.QUAN_LY_DAT_HEN.XEM",
  "PRM.QUAN_LY_DAT_HEN.THEM",
  "PRM.QUAN_LY_DAT_HEN.SUA",
  "PRM.QUAN_LY_DAT_HEN.IN_PDF",
  "PRM.QUAN_LY_DAT_HEN.XOA",
  "PRM.QUAN_LY_TIN_NHAN.XEM",
  "PRM.QUAN_LY_TIN_NHAN.THEM",
  "PRM.QUAN_LY_TIN_NHAN.SUA",
  "PRM.QUAN_LY_TIN_NHAN.IN_PDF",
  "PRM.QUAN_LY_TIN_NHAN.XOA",
  "PRM.QUAN_LY_DON_HANG.XEM",
  "PRM.QUAN_LY_DON_HANG.THEM",
  "PRM.QUAN_LY_DON_HANG.SUA",
  "PRM.QUAN_LY_DON_HANG.IN_PDF",
  "PRM.QUAN_LY_DON_HANG.XOA",
  "PRM.QUAN_LY_KPI.XEM",
  "PRM.QUAN_LY_KPI.THEM",
  "PRM.QUAN_LY_KPI.SUA",
  "PRM.QUAN_LY_KPI.IN_PDF",
  "PRM.QUAN_LY_KPI.XOA",
  "PRM.QUAN_LY_KHACH_HANG_TIEM_NANG.XEM",
  "PRM.QUAN_LY_KHACH_HANG_TIEM_NANG.THEM",
  "PRM.QUAN_LY_KHACH_HANG_TIEM_NANG.SUA",
  "PRM.QUAN_LY_KHACH_HANG_TIEM_NANG.IN_PDF",
  "PRM.QUAN_LY_KHACH_HANG_TIEM_NANG.XOA",
  "PRM.QUAN_LY_KHACH_HANG_CA_NHAN.XEM",
  "PRM.QUAN_LY_KHACH_HANG_CA_NHAN.THEM",
  "PRM.QUAN_LY_KHACH_HANG_CA_NHAN.SUA",
  "PRM.QUAN_LY_KHACH_HANG_CA_NHAN.IN_PDF",
  "PRM.QUAN_LY_KHACH_HANG_CA_NHAN.XOA",
  "PRM.QUAN_LY_KHACH_HANG_DOANH_NGHIEP.XEM",
  "PRM.QUAN_LY_KHACH_HANG_DOANH_NGHIEP.THEM",
  "PRM.QUAN_LY_KHACH_HANG_DOANH_NGHIEP.SUA",
  "PRM.QUAN_LY_KHACH_HANG_DOANH_NGHIEP.IN_PDF",
  "PRM.QUAN_LY_KHACH_HANG_DOANH_NGHIEP.XOA",
  "PRM.EMAIL_MKT.XEM",
  "PRM.EMAIL_MKT.THEM",
  "PRM.EMAIL_MKT.SUA",
  "PRM.EMAIL_MKT.IN_PDF",
  "PRM.EMAIL_MKT.XOA",
  "PRM.SMS_MKT.XEM",
  "PRM.SMS_MKT.THEM",
  "PRM.SMS_MKT.SUA",
  "PRM.SMS_MKT.IN_PDF",
  "PRM.SMS_MKT.XOA",
  "PRM.QUAN_LY_VOUCHER.XEM",
  "PRM.QUAN_LY_VOUCHER.THEM",
  "PRM.QUAN_LY_VOUCHER.SUA",
  "PRM.QUAN_LY_VOUCHER.IN_PDF",
  "PRM.QUAN_LY_VOUCHER.XOA",
  "PRM.QUAN_LY_PHAN_HOI_KHACH_HANG.XEM",
  "PRM.QUAN_LY_PHAN_HOI_KHACH_HANG.THEM",
  "PRM.QUAN_LY_PHAN_HOI_KHACH_HANG.SUA",
  "PRM.QUAN_LY_PHAN_HOI_KHACH_HANG.XOA",
  "PRM.QUAN_LY_PHAN_HOI_KHACH_HANG.IN_PDF",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.SUA",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.KHOA",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.DUYET",
  "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.IN_PDF",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.THEM_MAU",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.XOA_MAU",
  "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.IN_PHIEU",
  "CIS.QL_KHAM_BENH.CHI_DINH_CLS.IN_PDF",
  "CIS.QL_TIEP_DON.DON_TIEP.IN_PDF",
  "CIS.QL_TIEP_DON.DON_TIEP.XUAT_EXCEL",
  "BAO_CAO.BAO_CAO_TIEP_DON.XEM",
  "BAO_CAO.BAO_CAO_TIEP_DON.IN_PDF",
  "BAO_CAO.BAO_CAO_TIEP_DON.XUAT_EXCEL",
  "BAO_CAO.BAO_CAO_DOANH_THU.XEM",
  "BAO_CAO.BAO_CAO_DOANH_THU.IN_PDF",
  "BAO_CAO.BAO_CAO_DOANH_THU.XUAT_EXCEL",
  "BAO_CAO.BAO_CAO_HOA_HONG.XEM",
  "BAO_CAO.BAO_CAO_HOA_HONG.IN_PDF",
  "BAO_CAO.BAO_CAO_HOA_HONG.XUAT_EXCEL",
  "BAO_CAO.BAO_CAO_DUOC.XEM",
  "BAO_CAO.BAO_CAO_DUOC.IN_PDF",
  "BAO_CAO.BAO_CAO_DUOC.XUAT_EXCEL",
  "BAO_CAO.BAO_CAO_RIS.XEM",
  "BAO_CAO.BAO_CAO_RIS.IN_PDF",
  "BAO_CAO.BAO_CAO_RIS.XUAT_EXCEL",
  "BAO_CAO.BAO_CAO_LIS.XEM",
  "BAO_CAO.BAO_CAO_LIS.IN_PDF",
  "BAO_CAO.BAO_CAO_LIS.XUAT_EXCEL",

  "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.XEM",
  "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.THEM_MOI",
  "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.HOAN_TIEN",
  "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.THANH_TOAN",
  "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG.HUY",
  "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.XEM",
  "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.THANH_TOAN",
  "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.IN_PDF",
  "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO.HOAN_TIEN",

  "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.XEM",
  "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.THEM_MOI",
  "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.SUA",
  "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.KHOA",
  "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.COPY",
  "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.XOA",

  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.XEM",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.THEM_MOI",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.XOA",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.KHOA",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.ACTIVE",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.THEM_MOI_LIEU_TRINH",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA_KE_HOACH_SD",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA_LIEU_TRINH",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.IN_FILE",

  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.XEM",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.THEM_MOI",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.SUA",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.ACTIVE",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.COMPLETE",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.KHOA",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.IN_FILE",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.THEM_MOI_LIEU_TRINH",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.TAT_TOAN",
  "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.CHI_DINH_VAT_TU",

  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.IN_PDF",
  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.XUAT_EXCEL",
  "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP.IN_HOA_DON",
  "CIS.QL_KHAM_BENH.CHI_DINH_CLS.THUC_HIEN",
  "CIS.QL_TIEP_DON.DON_TIEP.THANH_TOAN",
  "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI.TIEP_DON",
  "RIS.RIS_NHAP_TAY.TRA_KQ.HUY_THUC_HIEN",
  "DUOC.QL_DUOC_NGOAITRU.TON_KHO.SUA_THUOC",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.THEM_MOI",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.SUA",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.XOA",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.KHOA",
  "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.IN_PDF",
  "LIS.QUAN_LY_BENH_PHAM.GUI_CHAY_LAI",

  "CIS.PHAU_THUAT_THU_THUAT.XEM",
  "CIS.PHAU_THUAT_THU_THUAT.THUC_HIEN",
  "CIS.PHAU_THUAT_THU_THUAT.THUC_HIEN_TRA_KQ",
  "CIS.PHAU_THUAT_THU_THUAT.XEM_TT",
  "CIS.PHAU_THUAT_THU_THUAT.HUY",
  "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.XEM",
  "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.THEM_MOI",
  "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.THUC_HIEN_TRA_KQ",
  "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.XOA",
  "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU.IN_PDF",

  "VAT_TU.PHIEU_XUAT_NHAP_VT.XEM",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.THEM_NHAP",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.THEM_XUAT",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.SUA",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.DUYET_PHIEU",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.HUY_DUYET",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.XOA",
  "VAT_TU.PHIEU_XUAT_NHAP_VT.IN_PHIEU",

  "VAT_TU.TON_KHO_VT.XEM",
  "VAT_TU.TON_KHO_VT.THEM",
  "VAT_TU.TON_KHO_VT.SUA_KHO",
  "VAT_TU.TON_KHO_VT.SUA_THUOC",
  "VAT_TU.TON_KHO_VT.DUYET",
  "VAT_TU.TON_KHO_VT.KHOA",

  "VAT_TU.KIEM_KE.XEM",
  "VAT_TU.KIEM_KE.THEM",
  "VAT_TU.KIEM_KE.DUYET_PHIEU",
  "VAT_TU.KIEM_KE.SUA",
  "VAT_TU.KIEM_KE.XOA",
  "VAT_TU.KIEM_KE.XUAT_EXCEL",
  "VAT_TU.KIEM_KE.IN_PHIEU",

  "VAT_TU.PHIEU_DIEU_CHINH.XEM",
  "VAT_TU.PHIEU_DIEU_CHINH.THEM_TANG",
  "VAT_TU.PHIEU_DIEU_CHINH.THEM_GIAM",
  "VAT_TU.PHIEU_DIEU_CHINH.DUYET",
  "VAT_TU.PHIEU_DIEU_CHINH.HUY_DUYET",
  "VAT_TU.PHIEU_DIEU_CHINH.SUA",
  "VAT_TU.PHIEU_DIEU_CHINH.IN_PHIEU",
  "VAT_TU.PHIEU_DIEU_CHINH.XOA",

  "VAT_TU.BAN_THUOC_VT.XEM",
  "VAT_TU.BAN_THUOC_VT.SUA",
  "VAT_TU.BAN_THUOC_VT.IN",
  "VAT_TU.BAN_THUOC_VT.DUYET",
  "VAT_TU.BAN_THUOC_VT.HUY",
  "VAT_TU.BAN_THUOC_VT.BAN_VANG_LAI",
];

export const allPermissionMapping = Object.assign({}, allPermissions);

// get key permissions based on value
export const mappingKeyPermissions = (arr = []) => {
  let result = arr.map((v) => {
    const foundTuple = Object.entries(allPermissionMapping).find((x) => x[1] === v);
    return foundTuple ? foundTuple[0] : null;
  });

  return result.filter((item) => !!item);
};

export const mappedPermissions = (permissions = []) => {
  let result = [];
  permissions.forEach((item) => {
    if (item in allPermissionMapping) {
      result.push(allPermissionMapping[item]);
    }
  });

  return result;
};

export const defaultStaffPermission = (() => {
  const enhanceFilter = (category = [], filterKey = "") => {
    let result = category.filter((i) => i.includes(filterKey));
    return result;
  };

  return {
    [staffGroup.BAC_SI]: [
      "CIS.QL_BENH_NHAN.XEM",
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.IN_FILE",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.XEM",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.THEM_MOI",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.IN_FILE",
      ...allPermissions.filter((i) => i.includes("CIS.DASHBOARD")),
      ...allPermissions
        .filter((i) => i.includes("CIS.QL_KHAM_BENH"))
        .filter((i) => i !== "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.IN_PHIEU"),
      "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.XEM",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.XEM",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.THEM_MOI",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.ACTIVE",
    ],

    [staffGroup.TIEP_TAN]: [
      ...allPermissions.filter((i) => i.includes("CIS.QL_TIEP_DON")),
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.XEM",
      "CIS.QL_BENH_NHAN.XEM",
      "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.XEM",
      "BAO_CAO.BAO_CAO_TIEP_DON.XEM",

      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.XEM",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.THEM_MOI",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.SUA",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV.ACTIVE",

      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.XEM",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.THEM_MOI",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.SUA",
      "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV.ACTIVE",
      "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.XEM",
      "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.SUA",
      "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.THEM_MOI",
      "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV.COPY",
    ],
    [staffGroup.THU_NGAN]: [
      ...allPermissions.filter((i) => i.includes("CIS.QL_VIEN_PHI") || i.includes("CIS.QUAN_LY_GOI_DV")),
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.XEM",
      "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON.XEM",
      "BAO_CAO.BAO_CAO_DOANH_THU.XEM",
    ],
    [staffGroup.DIEU_DUONG]: [
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.XEM",
      "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON.SUA",
      "CIS.QL_KHAM_BENH.CHI_DINH_CLS.XEM",
      "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.XEM",
      "LIS.QUAN_LY_XET_NGHIEM.HUY_DUYET",
      "CIS.QL_KHAM_BENH.THONG_TIN_KHAM.THEM",
    ],
    [staffGroup.DUOC_SI]: [
      ...enhanceFilter(
        allPermissions.filter((i) => i.includes("DUOC.QL_DUOC_NGOAITRU")),
        "XEM",
      ),
      "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.IN",
      "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.DUYET_XUAT_THANH_TOAN",
      "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.HUY",
      "DUOC.QL_DUOC_NGOAITRU.BAN_THUOC.BAN_VANG_LAI",
      "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.IN_PHIEU",
      "MANAGEMENT.DUOC_NGOAI_TRU.XEM",
      "MANAGEMENT.DUOC_NGOAI_TRU.THEM",
      "MANAGEMENT.DUOC_NGOAI_TRU.SUA",
      "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.XEM",
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "BAO_CAO.BAO_CAO_DUOC.XEM",
    ],
    [staffGroup.THU_KHO]: [
      "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG.XEM",
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "MANAGEMENT.DUOC_NGOAI_TRU.XEM",
      "MANAGEMENT.DUOC_NGOAI_TRU.THEM",
      "MANAGEMENT.DUOC_NGOAI_TRU.SUA",
      "BAO_CAO.BAO_CAO_DUOC.XEM",
      ...allPermissions.filter(
        (i) =>
          i.includes("DUOC.QL_DUOC_NGOAITRU") &&
          (i.includes("DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP") ||
            i.includes("DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP") ||
            i.includes("DUOC.QL_DUOC_NGOAITRU.TON_KHO")) &&
          ![
            "DUOC.QL_DUOC_NGOAITRU.PHIEU_XUAT_NHAP.XOA",
            "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.XUAT_EXCEL",
            "DUOC.QL_DUOC_NGOAITRU.NHA_CUNG_CAP.IN_PDF",
          ].includes(i),
      ),
    ],
    [staffGroup.KY_THUAT_VIEN_CDHA]: [
      ...allPermissions.filter((i) => i.includes("RIS.RIS_NHAP_TAY")),
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS.XEM",
      "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS.XEM",
      "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.XEM",
      "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.THEM",
      "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.SUA",
      "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS.KHOA",
      "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.XEM",
      "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.THEM",
      "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.SUA",
      "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS.KHOA",
      "BAO_CAO.BAO_CAO_RIS.XEM",
    ],
    [staffGroup.KY_THUAT_VIEN_XN]: [
      ...allPermissions.filter((i) => i.includes("LIS.QUAN_LY_BENH_PHAM") || i.includes("LIS.QUAN_LY_XET_NGHIEM")),
      "MANAGEMENT.DM_CIS.LICH_LAM_VIEC.XEM",
      "LIS.BAO_CAO.XEM",
      ...allPermissions.filter(
        (i) => i.includes("MANAGEMENT.DM_LIS") && !i.includes("XOA") && !i.includes("XUAT_EXCEL"),
      ),
      "BAO_CAO.BAO_CAO_LIS.XEM",
    ],
  };
})();

import React, { useState } from "react";
import { checkResponse, validPath } from "../../../../helpers";
import { Row, Button, Col, notification, Popconfirm } from "antd";
import { Delete, Edit, SettingIcon } from "../../../../assets/images/icons";
import { useNavigate, useHistory } from "react-router-dom";
import { paths } from "../../../../utils/constant";
import { useDispatch } from "react-redux";
import { setHospitalInfo } from "../../../../duck/slices/hospitalInformation.slice";
import { common_post } from "../../../../services/apiInstances";
import { Apis } from "../../../../constants";
import AsyncSwitch from "../../../../components/ActionButton/AsyncSwitch";
const ActionClinic = ({ record, onEdit = () => {}, onAfterSubmit = () => {}, onOpenConfirm = () => {} }) => {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { TRANG_THAI } = record;
  const [value, setValue] = useState(
    TRANG_THAI === "DA_KICH_HOAT" ? true : false
  );
  const [loading, setLoading] = useState(false);

  const handleDeleteHospital = async () => {
    try {
      const TRANG_THAI = "DA_HUY";
      const response = await common_post('api/khachHang/capNhatTrangThaiKhachHang', {
        TRANG_THAI,
        ID: record.ID
      })
      if(checkResponse(response)) {
        onAfterSubmit()
      }

      notification.success({message :"Cập nhật thành công"})
    } catch (error) {
      notification.error({message :"Cập nhật thất bại"})
    } finally {
    }
  }

  const handleLockHospital = async (arr_ID, isLock) => {

    try {
      if(!arr_ID?.[0]) {
        arr_ID = [arr_ID];
      }
      let body = {
        list_partner_code:arr_ID,
        khoa:isLock
      };
      let rs = await common_post(Apis.khoaKhachHang,body);
      if(checkResponse(rs)) {
        notification.success({message:"Thao tác thành công"});
        return rs
      } else {
        throw new Error(rs.message);
      }
    } catch (error) {
      console.log(error);
      notification.error({message:error.message});
    }
  }

  const handleToggle = async (record,checked) => {
    try {
      let {partner_code} = record;

      if(partner_code) {
        return onOpenConfirm([partner_code],checked);
      }
    } catch (error) {
      console.log(error);
    }
  } 

  return (
    <Row gutter={10} align={"middle"} style={{width :record.TRANG_THAI === "CHUA_KICH_HOAT" ? 200 : '160px',zIndex:10}} onClick={e => e.stopPropagation()}>
      <Col hidden={['CHUA_KICH_HOAT','DA_HUY'].includes(record.TRANG_THAI)}>
        <AsyncSwitch onToggle={bool => handleToggle(record,bool)} initValue={record?.KHOA} />
      </Col>
      <Col onClick={() => onEdit (record)}>
        <Edit style={{ width: '20px'}} />
      </Col>
      <Col onClick={() => {
        //history('/clinic-management/customer')
      }}>

        {record.TRANG_THAI === "DA_KICH_HOAT" && <SettingIcon style={{width: '20px  '}} onClick={e => {
          e.stopPropagation();
          dispatch(setHospitalInfo(record))
          navigate(validPath(`/${paths.HOME}/${paths.CATALOG}/${record.ID}/${paths.DM_CHUNG}`))}} />}
        
      </Col>
      <Col
        style={{
          cursor: TRANG_THAI === "DA_KICH_HOAT" ? "not-allowed" : "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          //handleDeleteHospital()
        }}
      >
        {TRANG_THAI !== "DA_HUY" && <Popconfirm
          title="Xác nhận"
          description="Bạn có chắc chắn muốn hủy khách hàng?"
          onConfirm={handleDeleteHospital}
          //onCancel={cancel}
          okText="Yes"
          cancelText="No"
          disabled = {TRANG_THAI === "DA_KICH_HOAT"}
        >
          <Delete
            style={{
              fill: TRANG_THAI === "DA_KICH_HOAT" ? "#C7C7C7" : "#E73939",
              width: "20px",
            }}
          />
        </Popconfirm> }
      </Col>
      <Col>
        {record.TRANG_THAI === "CHUA_KICH_HOAT" && (
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(`${paths.REGISTER_MANAGERMENT}/${record.ID}`);
            }}
          >
            Khởi tạo PK
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ActionClinic;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Router from './routes';
import store from './store';
import { setupInterceptor } from './services/apiInstances';
// import { useAuth } from './hooks/useAuth';

setupInterceptor(store);
function App() {
  // useAuth();
  return (
    <ConfigProvider
      autoInsertSpaceInButton={false}
     theme={
        {
          token :{
            fontFamily : "SVN-Gilroy",
              colorPrimary: "#4971ED"
          }
        }
     }
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;

const routeKeys = {
    store: {
        DM_Chung:"DM_Chung",
        LIS:"LIS",
    },
    slices: {
        QuanlyNguoiDung:"QuanlyNguoiDung",
        standardTest:"standardTest",
        phanHe:"phanHe",
        PhongKhamVeTinh:"PhongKhamVeTinh",
    },
};

export default routeKeys;
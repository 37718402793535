import {createSlice} from "@reduxjs/toolkit";

const initState = {
    data: [],
    currentPage: 1,
    totalResult : 0,
};

const DmDoiTuongSlice = createSlice({
    name: "DmDoiTuong",
    initialState: initState,
    reducers: {
        getDmDoiTuong: () => {},
        getDataSuccess: (state, action) => {
            const {current_page, result, total} = action.payload;
            state.data = result
            state.currentPage = current_page
            state.totalResult = total
        },
        addDoiTuong: () => {},
        editDoiTuong: () => {},
        deleteDoiTuong: () => {},
        exportDmDoiTuong: () => {},
        lockDmDoiTuong: () => {},
        updateDoiTuong: (state, action) => {
            const updateLock = state.data.findIndex((id) => id.ID === Number(action.payload.id ) )
            state.data[updateLock].KHOA = action.payload.isLock
        },
        importDmDoiTuong: () => {}
    },
});

export const {getDmDoiTuong, getDataSuccess, addDoiTuong, deleteDoiTuong, editDoiTuong, exportDmDoiTuong, importDmDoiTuong, lockDmDoiTuong, updateDoiTuong} = DmDoiTuongSlice.actions
export const DmDoiTuongData = (state) => state.DM_Chung.DmDoiTuong
export default DmDoiTuongSlice.reducer;
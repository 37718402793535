import { Tooltip } from "antd";
import { subRoutes } from "../../../../../../utils/constant";
import Apis from "../../../../../../constants/apis";
import {
  DataDmNhomDT,
  doCreateNhomDT,
  doDeleteDmNhomDT,
  doGetListNhomDT,
  doUpdateNhomDT,
  lockDmNhomDt,
  doExportDmNhomDT,
  doImportDmNhomDT
} from "../../../../../../duck/slices/DM_Chung/DmNhomDTSlice";
import FormNhomDT from "../../../../../Components/DM_CHUNG/FormNhomDT";
import {
  DataDmKhoa,
  doCreateDmKhoa,
  doDeleteDmKhoa,
  doGetListDmKhoa,
  doUpdateDmKhoa,
  lockDmKhoa,
  doExportDmKhoa,
  doImportDmKhoa
} from "../../../../../../duck/slices/DM_Chung/DmKhoa";
import FormDMKhoa from "../../../../../Components/DM_CHUNG/FormDMKhoa";
import {
  fieldDoiTuong,
  fieldKhoa,
  fieldLoaiDv,
  fieldNhomDoiTuongNguoiDung,
  fieldNhomDv,
  fieldPhong,
} from "./fieldsCatalog";
import {
  DataDmPhong,
  doCreateDmPhong,
  doDeleteDmPhong,
  doGetListDmPhong,
  doUpdateDmPhong,
  exportDmPhong,
  importDmPhong,
  lockDmPhong,
} from "../../../../../../duck/slices/DM_Chung/DmPhong";
import FormDMPhong from "../../../../../Components/DM_CHUNG/FormDMPhong";
import { danhSachLoaiKhoa, danhSachLoaiPhong } from "../../../../../../helpers";
import {
  DataDmNhomDv,
  doCreateDmNhomDv,
  doDeleteDmNhomDv,
  doGetListDmNhomDv,
  doImportNhomDv,
  doUpdateDmNhomDv,
  exportNhomDV,
  lockDmNhomDv,
} from "../../../../../../duck/slices/DM_Chung/DmNhomDv";
import FormDMNhomDv from "../../../../../Components/DM_CHUNG/FormDMNhomDv";
import { DataDmLoaiDv, doCreateDmLoaiDv, doDeleteDmLoaiDv, doExportDmLoaiDv, doGetListDmLoaiDv, doImportDmLoaiDv, doUpdateDmLoaiDv, lockDmLoaiDv } from "../../../../../../duck/slices/DM_Chung/DmLoaiDv";
import FormDMLoaiDv from "../../../../../Components/DM_CHUNG/FormDMLoaiDv";
import { MauDoiTuong, MauKhoa, MauLoaiDv, MauNhom, MauPhong } from "../../../../../../assets/fileTemplate.xlsx";

const ellipsisProps = {
  ellipsis: true,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} zIndex={1}>
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};

// ellipsis chữ với chữ màu xanh dương
const ellipsisBlueProps = {
  ...ellipsisProps,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} className="blue-txt">
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};

const propsInstance = {
  columns: [],
  apiGetData: "",
  apiDeleteData: "",
  apiLockData: "",
  apiImportData: "",
};

const keysCatalog = (props) => ({
  [subRoutes.dm_nhom_doi_tuong]: {
    fieldData: fieldNhomDoiTuongNguoiDung,
    columns: [
      {
        title: "Mã nhóm đối tượng",
        key: "MA_DM_NHOM_NGUON_KHACH",
        dataIndex: "MA_DM_NHOM_NGUON_KHACH",
        width: "20%",
        ...ellipsisProps,
      },
      {
        title: "Tên đối tượng người dùng",
        key: "TEN_DM_NHOM_NGUON_KHACH",
        dataIndex: "TEN_DM_NHOM_NGUON_KHACH",
        width: "25%",
        ...ellipsisProps,
      },
      {
        title: "Ghi chú",
        key: "GHI_CHU",
        dataIndex: "GHI_CHU",
        width: "55%",
        ...ellipsisProps,
      },
    ],
    apiGetData: Apis.layDMNhomDoiTuong,
    apiDeleteData: false,
    apiLockData: '',
    apiExportData:Apis.dm_export_nhom_doi_tuong,
    apiImportData: Apis.nhapDanhMucDoiTuong,
    apiAdd: Apis.themDoiTuong,
    formItem: <FormNhomDT {...props} />,
    filePath:MauDoiTuong,
    add: doCreateNhomDT,
    getList: doGetListNhomDT,
    data: DataDmNhomDT,
    lock: lockDmNhomDt,
    delete: doDeleteDmNhomDT,
    edit: doUpdateNhomDT,
    export:doExportDmNhomDT,
    import: doImportDmNhomDT,
    hiddenImport:true,
  },
  [subRoutes.dm_khoa]: {
    columns: [
      {
        title: "Mã khoa",
        key: "MA_KHOA",
        dataIndex: "MA_KHOA",
        width: "20%",
        ...ellipsisProps,
      },
      {
        title: "Tên khoa",
        key: "TEN_KHOA_PHONG",
        dataIndex: "TEN_KHOA_PHONG",
        width: "25%",
        ...ellipsisProps,
      },
      {
        title: "Loại khoa",
        key: "LOAI_KHOA",
        dataIndex: "LOAI_KHOA",
        width: "55%",
        ...ellipsisProps,
        render:(data,record) => {
          try {
            let dsKhoa = Object.fromEntries(danhSachLoaiKhoa.map(c => [c.value,c.label]))
            return dsKhoa[data.trim()]
          } catch (error) {
            return data
          }
        }
      },
      {
        title: "Ghi chú",
        key: "GHI_CHU",
        dataIndex: "GHI_CHU",
        width: "55%",
        ...ellipsisProps,
      },
    ],
    fieldData: fieldKhoa,
    apiGetData: Apis.layDMKhoa,
    apiDeleteData: "",
    apiLockData: "",
    apiImportData: Apis.nhapDanhMucKhoa,
    apiAdd: Apis.themDMKhoa,
    formItem: <FormDMKhoa {...props} />,

    filePath:MauKhoa,
    getList: doGetListDmKhoa,
    add: doCreateDmKhoa,
    data: DataDmKhoa,
    lock: lockDmKhoa,
    delete: doDeleteDmKhoa,
    edit: doUpdateDmKhoa,
    export:doExportDmKhoa,
    import: doImportDmKhoa,
  },
  [subRoutes.dm_phong]: {
    columns: [
      {
        title: "Mã phòng",
        key: fieldPhong.ma_phong,
        dataIndex: fieldPhong.ma_phong,
        width: "20%",
        ...ellipsisProps,
      },
      {
        title: "Tên phòng",
        key: fieldPhong.ten_phong,
        dataIndex: fieldPhong.ten_phong,
        width: "32%",
        ...ellipsisProps,
      },
      {
        title: "Thời gian",
        key: fieldPhong.gio_bat_dau,
        dataIndex: fieldPhong.gio_ket_thuc,
        width: "20%",
        ...ellipsisProps,
        render: (data, record) => {
          if (
            record[fieldPhong.gio_bat_dau] === null ||
            record[fieldPhong.gio_ket_thuc] === null
          ) {
            return "-";
          } else {
            return (
              record[fieldPhong.gio_bat_dau] +
              " - " +
              record[fieldPhong.gio_ket_thuc]
            );
          }
        },
      },
      {
        title: "Khoa",
        key: fieldPhong.ten_khoa,
        dataIndex: fieldPhong.ten_khoa,
        width: "25%",
        ...ellipsisProps,
      },
      {
        title: "Loại phòng",
        key: fieldPhong.loai_phong,
        dataIndex: fieldPhong.loai_phong,
        width: "25%",
        ...ellipsisProps,
        render: (data) => {
          const filterList = danhSachLoaiPhong.filter(
            (loai_phong) => loai_phong.key === data
          );
          if (filterList.length > 0) {
            return (
              <Tooltip placement="topLeft" title={filterList[0].name}>
                {filterList[0].name}
              </Tooltip>
            );
          }
          return "--";
        },
      },
      {
        title: "Ghi chú",
        key: "GHI_CHU",
        dataIndex: "GHI_CHU",
        width: "25%",
        ...ellipsisProps,
      },
    ],
    fieldData: fieldPhong,
    filePath:MauPhong,
    apiGetData: Apis.layDMPhong,
    apiDeleteData: "",
    apiLockData: "",
    apiImportData: Apis.nhapDanhMucPhong,
    apiAdd: Apis.themDMPhong,
    formItem: <FormDMPhong {...props} />,

    getList: doGetListDmPhong,
    add: doCreateDmPhong,
    data: DataDmPhong,
    lock: lockDmPhong,
    delete: doDeleteDmPhong,
    edit: doUpdateDmPhong,
    export : exportDmPhong,
    import: importDmPhong,
  },
  [subRoutes.dm_nhom_dich_vu]: {
    columns: [
      {
        title: "Mã nhóm",
        key: "MA_NHOM",
        dataIndex: "MA_NHOM",
        width: "20%",
        ...ellipsisProps,
      },
      {
        title: "Tên nhóm",
        key: "TEN_NHOM",
        dataIndex: "TEN_NHOM",
        width: "25%",
        ...ellipsisProps,
      },
      {
        title: "Nhóm chi phí bảo hiểm y tế",
        key: "TEN_NHOM_BHYT",
        dataIndex: "TEN_NHOM_BHYT",
        width: "35%",
        ...ellipsisProps,
      },
    ],
    fieldData: fieldNhomDv,
    apiGetData: Apis.layDMNhomDichVu,
    apiDeleteData: "",
    filePath:MauNhom,
    apiLockData: "",
    apiImportData: Apis.nhapDanhMucNhomDv,
    apiAdd: Apis.themDMNhomDichVu,
    formItem: <FormDMNhomDv {...props} />,

    getList: doGetListDmNhomDv,
    add: doCreateDmNhomDv,
    data: DataDmNhomDv,
    lock: lockDmNhomDv,
    delete: doDeleteDmNhomDv,
    edit: doUpdateDmNhomDv,
    export : exportNhomDV,
    import: doImportNhomDv,
  },
  [subRoutes.dm_loai_dich_vu]: {
    columns: [
      {
        title: "Mã loại dịch vụ",
        key: "MA_LOAI",
        dataIndex: "MA_LOAI",
        width: "20%",
        ...ellipsisProps,
      },
      {
        title: "Tên loại dịch vụ",
        key: "TEN_LOAI",
        dataIndex: "TEN_LOAI",
        width: "25%",
        ...ellipsisProps,
      },
      {
        title: "Nhóm dịch vụ",
        key: "TEN_NHOM_DICH_VU",
        dataIndex: "TEN_NHOM_DICH_VU",
        width: "35%",
        ...ellipsisProps,
      },
      {
        title: "Nhóm chi phí BHYT",
        key: "TEN_NHOM_BHYT",
        dataIndex: "TEN_NHOM_BHYT",
        width: "35%",
        ...ellipsisProps,
      },
    ],
    fieldData: fieldLoaiDv,
    apiGetData: Apis.layDMLoaiDv,
    apiDeleteData: "",
    apiLockData: "",
    apiImportData: Apis.nhapDanhMucLoaiDv,
    apiAdd: Apis.themDMLoaiDv,
    filePath:MauLoaiDv,
    formItem: <FormDMLoaiDv {...props} />,

    getList: doGetListDmLoaiDv,
    add: doCreateDmLoaiDv,
    data: DataDmLoaiDv,
    lock: lockDmLoaiDv,
    delete: doDeleteDmLoaiDv,
    edit: doUpdateDmLoaiDv,
    export:doExportDmLoaiDv,
    import: doImportDmLoaiDv,
  },
});

export default keysCatalog;

/**
 *
 * @param {string} key
 */
export const getUseCatalogHookProps = (key) => {
  switch (key) {
    case subRoutes.dm_nhom_doi_tuong:
      return keysCatalog()[subRoutes.dm_nhom_doi_tuong];
    case subRoutes.dm_khoa:
      return keysCatalog()[subRoutes.dm_khoa];
    case subRoutes.dm_phong:
      return keysCatalog()[subRoutes.dm_phong];
    case subRoutes.dm_nhom_dich_vu:
      return keysCatalog()[subRoutes.dm_nhom_dich_vu];
    case subRoutes.dm_loai_dich_vu:
      return keysCatalog()[subRoutes.dm_loai_dich_vu];
    default:
      return propsInstance;
  }
};

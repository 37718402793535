import React from "react"
import {Col, Form, Input, Row, Space, Switch} from "antd";
import style from "../../../assets/scss/layout/form.module.scss"
// import i18n, {languageKeys} from "../../../i18n/index.js";
// import {fieldDonViTinh} from "../../KeysCatalog/fieldsCatalog.js";

const FormNhomDT = ({isCreateNew, disabled = false}) => {

    return (
        <Space direction="vertical" size={10} style={{width: "100%"}} className={style['form']}>
            <Space direction="vertical" style={{width: "100%"}}>
                <h3>Thông tin cơ bản</h3>
                <div className={style['wrapper']}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label={"Mã nhóm đối tượng"}
                                name={"MA_DM_NHOM_NGUON_KHACH"}
                                rules={[{whitespace: true, message:""}]}
                            >
                                <Input placeholder={"Nhập"} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Tên nhóm đối tượng"}
                                name={"TEN_DM_NHOM_NGUON_KHACH"}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: "Vui lòng nhập !",
                                }]}
                            >
                                <Input placeholder={"Nhập"} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={"Ghi chú"}
                                name={"GHI_CHU"}
                            >
                                <Input placeholder={"Nhập"} disabled={disabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Space>
            {/* {!isCreateNew &&
                <Space>
                    <h3>{"i18n.t(languageKeys.field_Hien_thi)"}</h3>
                    <Form.Item name={"fieldDonViTinh.khoa"} valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                </Space>
            } */}
        </Space>
    )
}

export default FormNhomDT
import React, { useState } from "react";
import { validPath } from "../helpers";
import { Layout, Row, Col, Menu } from "antd";
import logo from "../assets/images/logo_main.png";
import { useSelector } from "react-redux";
import {
  CalendarIcon,
  CategoryIcon,
  DocumentIcon,
  EditIcon,
  EmailIcon,
  FolderUserIcon,
  GroupUserIcon,
  PhoneIcon,
  SettingIcon,
  ZaloIcon,
} from "../assets/images/icons";
import { useNavigate } from "react-router-dom";
import { paths, roleKey } from "../utils/constant";
import { useLogout } from "../hooks/useLogout";
import { LogoApp } from "../assets/images";
import classNames from "classnames";

const MainSider = () => {
  const navigate = useNavigate();
  const showSider = useSelector((state) => state.auth.showSider);
  const currentPath = window.location.pathname;
  const [selectedKey, setSelectedKey] = useState(currentPath);
  const { DefaultBtnLogout } = useLogout();
  const menuItems = [
    {
      title: "Quản lý khách hàng",
      link: validPath(`/${paths.HOME}/${paths.CUSTOMER}`),
      icon: <GroupUserIcon />,
    },
    // {
    //   title: "Quản lý đối tác",
    //   link: validPath(`/${paths.HOME}/${paths.PARTNER}`),
    //   icon: <FolderUserIcon />,
    // },
    // // {
    // //   title: "Quản lý danh mục",
    // //   link: validPath(`/${paths.HOME}/${paths.CATALOG}`),
    // //   icon: <GroupUserIcon />,
    // // },
    // {
    //   title: "Quản lý tài khoản",
    //   link: validPath(`/${paths.HOME}/${paths.ACCOUNT}`),
    //   icon: <SettingIcon />,
    // },
  ];

  function IconActive({ icon }) {
    return (
      <Row align={"middle"} wrap={false}>
        <Col>
          <div className="main__sider__menu__border__active" />
        </Col>
        <Col>
          <div className="main__sider__menu__icon__active">{icon}</div>
        </Col>
      </Row>
    );
  }

  function getItemMenu(arr) {
    return arr.map((item) => ({
      key: item.link,
      icon: currentPath.includes(item.link) ? (
        <IconActive icon={item.icon} />
      ) : (
        item.icon
      ),
      children: null,
      label: showSider ? "" : item.title,
      type: null,
    }));
  }

  return (
    <Layout.Sider
      className={classNames(
        "main__sider",
        showSider ? "main__sider--dark" : ""
      )}
      collapsed={showSider}
      collapsedWidth={100}
      theme={"light"}
      width={250}
    >
      {showSider ? (
        <img
          src={LogoApp}
          className="main__sider__logo"
          style={{ maxWidth: 50 }}
          alt={""}
          onClick={() => navigate(`/${paths.HOME}`)}
        />
      ) : (
        <img
          src={logo}
          className="main__sider__logo"
          alt={""}
          onClick={() => navigate(`/${paths.HOME}`)}
        />
      )}
      <div
        className={
          showSider ? "main__sider__menu--collapse" : "main__sider__menu"
        }
      >
        <Menu
          defaultSelectedKeys={["/clinic-management/customer"]}
          items={getItemMenu(menuItems)}
          onSelect={(item, key) => {
            setSelectedKey(item.key);
            navigate(item.key);
          }}
        />
      </div>
      <div className="main__sider__info__hide"></div>
      <div>
        <DefaultBtnLogout
          text={showSider ? "" : undefined}
          ghost={showSider ? 0 : 1}
        />
      </div>
    </Layout.Sider>
  );
};

export default MainSider;

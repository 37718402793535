import {createSlice} from "@reduxjs/toolkit";

const initState = {
    error: undefined,
};

const errorSlice = createSlice({
    name: "error",
    initialState: initState,
    reducers: {
        errorState: (state, action) => {
            state.error = action.payload
        },
        clearError: (state) => {
            state.error = undefined
        },
    },
});

export const {errorState, clearError} = errorSlice.actions
export const errorStatus = (state) => state.error.error
export default errorSlice.reducer;
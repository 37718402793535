import { validPath } from "../helpers";
import apiServices from "../config/apiServices";

export default class Apis {
  static layDMDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/layDMDichVu"); // lấy danh sách dv kĩ thuật
  static layDMDichVuLis = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/layDMDichVu"); // lấy danh sách dv kĩ thuật
  static dm_them_nhom_doi_tuong = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/themNhomNguonKhach",
  );
  static dm_export_nhom_doi_tuong = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/xuatDmNhomNguon",
  );
  
  //Dm nhom doi tuong
  static layDMNhomDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/layDanhSachNhomNguonKhach")
  static khoaNhieuDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/khoaNhieuNhomNguonKhach")
  static exportNhieuDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/xuatDmNhomNguon")
  static xoaNhieuDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/xoaNhieuNhomNguonKhach")
  static updateDmNhomDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/capNhatNhomNguonKhach")
  static themDmNhomDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/themNhomNguonKhach"); //in phiếu cls
  static dm_import_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/importDoiTuong"); //in phiếu cls
  static dm_export_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/exportDoiTuong"); //in phiếu cls
  static dm_sua_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/suaDoiTuong"); //in phiếu cls
  static dm_them_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/themDoiTuong"); //in phiếu cls
  static dm_khoa_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/capNhatTrangThaiNhieuDoiTuong"); //in phiếu cls
  static dm_xoa_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/xoaNhieuDoiTuong"); //in phiếu cls
  static dm_lay_ds_doi_tuong = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dm_doituong/layDsDoiTuong"); //in phiếu cls

  //Dm Khoa
  static layDMKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/khoa/layDanhSach")
  static suaTrangThaiDMKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/khoa/suaTrangThai")
  static themDMKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/khoa/them")
  static suaDMKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/khoa/suaThongTin"); // cập nhật thông tin khoa
  static xoaDMKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/khoa/xoa");
  static layMaChuyenKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/public/pkvt/layDSChuyenKhoa");

  //Dm phòng
  static layDMPhong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/layDanhSach")
  static themDMPhong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/them")
  static suaTrangThaiDMphong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/suaTrangThai")
  static suaDMphong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/suaThongTin")
  static xoaDMphong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/xoa")
  static exportDMPhong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/exportPhong")
  static nhapDMPhong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/nhapDanhSach")

  // danh mục nhóm nguồn khách
  static dm_lay_ds_nhom_doi_tuong = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/layDanhSachNhomNguonKhach",
  );
  static dm_khoa_ds_nhom_doi_tuong = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/khoaNhieuNhomNguonKhach",
  );

  //Dm Nhóm dịch vụ
  static layDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/layDMNhomDichVuKyThuat")
  static themDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/themDMNhomDichVuKyThuat")
  static suaDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/suaDMNhomDichVuKyThuat")
  static lockDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/khoaDMNhomDichVuTheoDS");
  static xoaDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/xoaDMNhomDichVuTheoDS"); // Xóa (danh sách) nhóm dv kĩ thuật



  static moDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/moDMNhomDichVuTheoDS")
  static khoaDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/khoaDMNhomDichVuTheoDS")
  static exportDMNhomDichVu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/exportNDV")

  //Dm Loại dịch vụ
  static layDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/layDMLoaiDichVu")
  static themDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/themDMLoaiDichVu")
  static suaDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/suaDMLoaiDichVu")
  static xoaDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/xoaDMLoaiDichVuTheoDS")
  static khoaDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/khoaDMLoaiDichVuTheoDS")

  static layDMLoaiDvHL = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/layDMNhomDichVuKyThuatHieuLuc")


  static moDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/moDMLoaiDichVuTheoDS")
  static exportDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/exportLDV")

  //Dm QL phân hệ
  static luuDMLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/public/phanhe/luuPhanHeCSKCB")

  static  khoiTaoPhongKham = validPath(apiServices.URL_CLINIC40_API_1 + "api/khoiTao/khoiTaoPhongKham")
  static  layChiTietKhachHang = validPath(apiServices.URL_CLINIC40_API_1 + "api/khachHang/layChiTietKhachHang")
  static dm_dvkt_lay_ds_co_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dichvu/layDMDichVuHieuLuc",
  ); // lấy danh sách dv kĩ thuật có hiệu lực

  static tiep_don_ds_phong_theo_dv = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/lichkham/layPhongTheoDVID",
  ); // lấy ds phòng theo dịch vụ

  static dm_check_trang_DVHH = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/checkDichVuHoaHongTt");
    // Hoa hồng
    static getNhomNguonKhach = validPath(
      apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/layDanhSachNhomNguonKhach",
    );
    static getCommissionList = validPath(
      apiServices.GATEWAY_CIS + "api/partner/public/dm_nguonkhach/layDanhSachNguonKhach",
    );
    static dm_dvkt_them_moi_v2 = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/themDMDichVuV2"); // Thêm mới dv kĩ thuật

  static xetNghiemTT13 = validPath(apiServices.GATEWAY_LIS + "api/public/xetnghiem/timKiemDvXNTT13");
    
  static khambenh_lay_dm_phong = validPath(
    apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/layDanhMucPhongHieuLuc",
  ); //lấy danh sách phòng mà bác sĩ đang làm việc
  static dm_don_vi_tinh_lay_ds_co_hieu_luc_lis = validPath(
    apiServices.GATEWAY_LIS + "api/partner/public/donvitinh/layDMDonViTinhHieuLuc",
  ); // lấy danh sách đơn vị tính có hiệu lực

  static khoaDVXetNghiem = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/khoaNhieuDichVuXetNghiem");
  static xoaDVXetNghiem = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/xoaNhieuDichVuXetNghiem");
  static themMoiDVXetNghiem = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/themDMDichVu");
  static suaDVXetNghiem = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/suaDichVu");
  static layCauHinhVien = validPath(apiServices.GATEWAY_CLINIC_40 + "/api/partner/public/quyen/layCauHinhBenhVien");  
  static suaCauHinhBenhVien = validPath(apiServices.GATEWAY_CLINIC_40 + "/api/partner/public/quyen/suaCauHinhBenhVien");  
  static dm_loai_DV_XN = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/layDMLoaiDichVu"); 
  static layDsChiSoKemTheoDv = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/layDsChiSoKemTheoDv");
  static laychitietDMDV_XN = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/layChiTietDMDichVu");
  
  static exportPhong = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/exportPhong");
  static exportNDV = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/exportNDV");
  static exportLDV = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/exportLDV");
  static layDsChiSo_v2 = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/layDsChiSo_v2");
  static luu_chi_so_kem_theo = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/luuChiSoKemTheo");
  static tim_kiem_chi_so_xet_nghiem_kem_theo = validPath(
    apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/timKiemChiSoXetNghiem",
  );
  static dm_lay_ds_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/layDMThuoc");
  static dm_lay_ds_thuoc_v2 = validPath(apiServices.GATEWAY_CIS + "	api/partner/public/thuoc/layChiTietThuocV2");
  static dm_them_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/themDMThuoc");
  static dm_them_thuoc_v2 = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/themDMThuocV2");
  static dm_them_thuoc_v3 = validPath(apiServices.GATEWAY_CIS + "	api/partner/public/thuoc/themDMThuocV3");
  static dm_sua_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/suaDMThuoc");
  static dm_sua_thuoc_v2 = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/suaDMThuocV2");
  static dm_xoa_ds_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/xoaNhieuDMThuoc");
  static dm_khoa_ds_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/khoaNhieuDMThuoc");

  static dm_quocgia_lay_ds_co_hieu_luc = validPath(apiServices.GATEWAY_CIS + "api/public/quoc-gia/layDanhSachHieuLuc"); // lấy danh sách quốc gia co hieu luc

  static dm_lay_ds_hang_sx_co_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/hangsx/layDMHangSanXuatHieuLuc",
  );


  static dm_lay_ds_hoat_chat_co_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/hoatchat/layDMHoatChatHieuLuc",
  );

  static dm_nhom_bhyt_lay_ds = validPath(apiServices.GATEWAY_CIS + "api/public/bhyt/layDMNHomBHYT"); // lấy danh sách nhóm bhyt có hiệu lực


  static lay_ds_nhom_thuoc_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/nhomThuoc/layDanhSachNhomThuocHieuLuc",
  ); // lấy danh sách nhóm thuốc có hiệu lực

  static gen_Ma_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/genMaThuoc");

  static dm_dvkt_xoa = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/xoaDMDichVuTheoDS"); // xoa (danh sách) dv kĩ thuật
  static layDanhSachKhoThuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/layDanhSachKhoThuoc");

  static dm_don_vi_tinh_lay_ds_co_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/donvitinh/layDMDonViTinhHieuLuc",
  ); // lấy danh sách đơn vị tính có hiệu lực

  static dm_dvkt_lay_chi_tiet_v2 = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/chiTietDichVuV2");
  static nhapDanhMucDoiTuong = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucDoiTuong");
  static nhapDanhMucKhoa = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucKhoa");
  static nhapDanhMucPhong = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucPhong");
  static nhapDanhMucNhomDv = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucNhomDv");
  static nhapDanhMucDv = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucDv");
  static nhapDanhMucLoaiDv = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucLoaiDv");

  static lay_ds_kho_thuoc_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/layDanhSachKhoThuocHieuLuc",
  );
  static dm_khoa_ds_hoat_chat = validPath(apiServices.GATEWAY_CIS + "api/partner/public/hoatchat/khoaNhieuDMHoatChat");
  static dm_lay_ds_hoat_chat = validPath(apiServices.GATEWAY_CIS + "api/partner/public/hoatchat/layDMHoatChat");
  static dm_xoa_ds_hoat_chat = validPath(apiServices.GATEWAY_CIS + "api/partner/public/hoatchat/xoaNhieuDMHoatChat");
  static them_kho_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/themKhoThuoc");
  static dm_phong_nhap_ds = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/nhapDanhSach"); // import danh sách phòng
  static dm_dvkt_cap_nhat_v2 = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/suaDMDichVuV2"); // Cập nhật dv kĩ thuật
  static lay_chi_tiet_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/layChiTietThuocV2"); //api lấy chi tiết thuốc

  static dm_thuoc_export = validPath(apiServices.GATEWAY_CIS + "api/partner/public/thuoc/exportThuoc");

  // danh mục nguồn khách
  static dm_lay_ds_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/layDanhSachCachDungThuoc",
  );
  static dm_lay_ds_cach_dung_hieu_luc = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/layDanhSachCachDungThuocCoHieuLuc",
  );
  static dm_them_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/themCachDungThuoc",
  );
  static dm_sua_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/capNhatCachDungThuoc",
  );
  static dm_xoa_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/xoaNhieuCachDungThuoc",
  );
  static dm_khoa_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/khoaNhieuCachDungThuoc",
  );
  static dm_import_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/importCachDungThuoc",
  );
  static dm_export_cach_dung = validPath(
    apiServices.GATEWAY_CIS + "api/partner/public/dm_cachdungthuoc/exportCachDungThuoc",
  );

//====== apis nhóm nhà cung cấp
static them_moi_nhom_ncc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhomNhaCungCap/themMoi"); // thêm mới nhóm nhà cung cáp
static lay_ds_nhom_ncc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhomNhaCungCap/layDanhSach"); //  lấy danh sách nhóm nhà cung cấp
static sua_thong_tin_nhom_ncc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhomNhaCungCap/suaThongTin"); // sửa nhóm nhà cung cấp

// ===== apis nhà cung cấp
static them_moi_ncc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhaCungCap/themMoi"); // thêm mới nhóm nhà cung cáp
static lay_ds_ncc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhaCungCap/layDanhSach"); //  lấy danh sách nhóm nhà cung cấp
static lay_ds_ncc_hieu_luc = validPath(
  apiServices.GATEWAY_CIS + "api/partner/public/nhaCungCap/layDanhSachNCCHieuLuc",
); //  lấy danh sách nhà cung cấp hiệu lực
static sua_thong_tin_ncc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhaCungCap/suaThongTin"); // sửa nhóm nhà cung cấp
static dm_dvkt_lock_ds = validPath(apiServices.GATEWAY_CIS + "api/partner/public/dichvu/khoaDMDichVuTheoDS"); // lock ds dịch vụ
static moDMDichVuTheoDS = validPath(apiServices.GATEWAY_CIS + "/api/partner/public/dichvu/moDMDichVuTheoDS"); // mở ds dịch vụ

static lay_chi_tiet_kho_thuoc = validPath(
  apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/layChiTietKhoThuoc",
);

static cap_nhat_thong_tin_kho_thuoc = validPath(
  apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/capNhatKhoThuoc",
);
static lay_ds_kho_thuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/layDanhSachKhoThuoc");
static khoaKhoThuocTheoDsID = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/khoaKhoThuocTheoDsID");
static xoaNhieuKhoThuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/xoaNhieuKhoThuoc");
static nhapKhoThuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/nhapKhoThuoc");
static xuatKhoThuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/public/kho_thuoc/xuatKhoThuoc");
static getMe = validPath(apiServices.URL_CLINIC40_API_1 + "api/me");
static nhapDanhMucXetNghiem = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucXetNghiem");
static nhapDanhMucThuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucThuoc");
static nhapDanhMucKhoThuoc = validPath(apiServices.GATEWAY_CIS + "api/partner/backoffice/danhmuc/nhapDanhMucKhoThuoc");
static khoaKhachHang = validPath(apiServices.GATEWAY_CLINIC_40 + "api/khachHang/khoaKhachHang");
static sua_chi_so_xet_nghiem = validPath(apiServices.GATEWAY_LIS + "api/partner/public/xetnghiem/suaDichVu");

static layDsQuyen = validPath(apiServices.GATEWAY_CIS + "api/partner/public/quyen/layTatCaQuyen");

static dm_lay_ds_user = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/layDsNhanSu"); // lấy danh sách user
static dm_them_moi_user = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/themNhanSu"); // thêm mới user
static dm_cap_nhat_user = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/capNhatNhanSu"); // cập nhật user
static dm_xoa_ds_user = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/xoaNhanSuTheoDs"); // xóa user
static dm_khoa_ds_user = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/khoaNhanSuTheoDs"); // khóa user
static dm_lay_phong_theo_khoa = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/phong/layPhongTheoKhoa"); // lấy phòng theo khoa
static dm_user_lay_ds_quyen = validPath(apiServices.GATEWAY_CIS + "api/partner/public/quyen/layDSTinhNang"); // lấy danh sách quyền

static dm_khoa_lay_ds = validPath(apiServices.GATEWAY_CIS + "api/partner/danh-muc/khoa/layDanhSach"); // lấy danh sách khoa
static lay_nhom_nhan_su = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/layNhomNhanSuBenhVien");
static doiMatKhauNhanSu = validPath(apiServices.GATEWAY_CIS + "api/partner/public/nhansu/doiMatKhauNhanSu");
static chinhSuaPhanHeAdmin = validPath(apiServices.URL_CLINIC40_API_1 + "api/khachHang/chinhSuaPhanHeAdmin");


static kiemTraTonTaiPartner = validPath(apiServices.URL_CLINIC40_API_1 + "api/khachHang/kiemTraTonTaiPartner");
}




import { combineReducers } from "@reduxjs/toolkit";
import DmNhomDTSlice from "./DmNhomDTSlice.js";
import DmKhoaSlice from "./DmKhoa";
import DmMainSlice from "./DmMain.js";
import DmPhongSlice from "./DmPhong.js";
import DmNhomDvSlice from "./DmNhomDv.js";
import DmLoaiDvSlice from "./DmLoaiDv.js";
import QuanlyNguoiDungSlice from "./QuanlyNguoiDungSlice.js";
import DmDoiTuongSlice from "./DmDoiTuongSlice.js";

const DM_ChungReducer = combineReducers({
  DmMain: DmMainSlice,
  DmNhomDT: DmNhomDTSlice,
  DmKhoa: DmKhoaSlice,
  DmPhong: DmPhongSlice,
  DmNhomDv: DmNhomDvSlice,
  DmLoaiDv: DmLoaiDvSlice,
  QuanlyNguoiDung: QuanlyNguoiDungSlice,
  DmDoiTuong: DmDoiTuongSlice,
});

export default DM_ChungReducer;

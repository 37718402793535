export const permissionActionKey = {
  XEM: "XEM",
  THEM_MOI: "THEM_MOI",
  THEM: "THEM",
  SUA: "SUA",
  XOA: "XOA",
  HUY: "HUY",
  IN_PDF: "IN_PDF",
  XUAT_EXCEL: "XUAT_EXCEL",
  XAC_NHAN: "XAC_NHAN",
  THUC_HIEN: "THUC_HIEN",
  TAI_XUONG: "TAI_XUONG",
  KHOA: "KHOA",
  CAI_DAT_LLV: "CAI_DAT_LLV",
};

// Quyền lớp 2
export const cisPermission = {
  DASHBOARD: "CIS.DASHBOARD",
  QUAN_LY_DICH_VU: "CIS.QUAN_LY_DICH_VU",
  LICH_LAM_VIEC: "CIS.LICH_LAM_VIEC",
  QUAN_LY_CACH_THUC_THU_THUAT: "CIS.QUAN_LY_CACH_THUC_THU_THUAT",
  QUAN_LY_PHUONG_PHAP_THU_THUAT: "CIS.QUAN_LY_PHUONG_PHAP_THU_THUAT",
  BAO_CAO_TIEP_DON: "CIS.QL_BAO_CAO.BAO_CAO_TIEP_DON",
  BAO_CAO_VIEN_PHI: "CIS.QL_BAO_CAO.BAO_CAO_VIEN_PHI",
  BAO_CAO_THU_THUAT: "CIS.QL_BAO_CAO.BAO_CAO_THU_THUAT",
  TIEP_DON: "CIS.QL_TIEP_DON.DON_TIEP",
  QUAN_LY_LICH_HEN: "CIS.QL_TIEP_DON.CUOC_HEN_SAP_TOI",
  DICH_VU_CAN_THANH_TOAN: "CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN",
  QUAN_LY_SO_TONG_HOP: "CIS.QL_VIEN_PHI.QUAN_LY_SO_TONG_HOP",
  QUAN_LY_TAM_UNG: "CIS.QL_VIEN_PHI.QUAN_LY_TAM_UNG",
  QUAN_LY_CONG_NO: "CIS.QL_VIEN_PHI.QUAN_LY_CONG_NO",
  QL_BENH_NHAN: "CIS.QL_BENH_NHAN",
  CHI_SO_SINH_TON: "CIS.QL_KHAM_BENH.CHI_SO_SINH_TON",
  THONG_TIN_KHAM: "CIS.QL_KHAM_BENH.THONG_TIN_KHAM",
  CHI_DINH_CLS: "CIS.QL_KHAM_BENH.CHI_DINH_CLS",
  KE_DON_THUOC: "CIS.QL_KHAM_BENH.KE_DON_THUOC",
  THUC_HIEN_THU_THUAT: "CIS.QL_KHAM_BENH.THUC_HIEN_THU_THUAT",
  DANH_SACH_GOI_DV: "CIS.QUAN_LY_GOI_DV.DANH_SACH_GOI_DV",
  QUAN_LY_DK_GOI_DV: "CIS.QUAN_LY_GOI_DV.QUAN_LY_DK_GOI_DV",
  QUAN_LY_SU_DUNG_GOI_DV: "CIS.QUAN_LY_GOI_DV.QUAN_LY_SU_DUNG_GOI_DV",
  PHAU_THUAT_THU_THUAT: "CIS.PHAU_THUAT_THU_THUAT",
  QUAN_LY_CHI_DINH_DICH_VU: "CIS.QL_KHAM_BENH.QUAN_LY_CHI_DINH_DICH_VU",
};

export const cisPermissionTemplate = {
  QUAN_LY_TIEP_DON: [cisPermission.QUAN_LY_LICH_HEN, cisPermission.TIEP_DON],
  QUAN_LY_VIEN_PHI: [
    cisPermission.DICH_VU_CAN_THANH_TOAN,
    cisPermission.QUAN_LY_SO_TONG_HOP,
    cisPermission.QUAN_LY_CONG_NO,
    cisPermission.QUAN_LY_TAM_UNG,
  ],
  QUAN_LY_KHAM_BENH: [
    cisPermission.CHI_DINH_CLS,
    cisPermission.CHI_SO_SINH_TON,
    cisPermission.THONG_TIN_KHAM,
    cisPermission.THUC_HIEN_THU_THUAT,
    cisPermission.KE_DON_THUOC,
    cisPermission.QUAN_LY_CHI_DINH_DICH_VU,
  ],
  QL_BENH_NHAN: [
    `${cisPermission.QL_BENH_NHAN}.${permissionActionKey.XEM}`,
    `${cisPermission.QL_BENH_NHAN}.${permissionActionKey.TAI_XUONG}`,
    `${cisPermission.QL_BENH_NHAN}.${permissionActionKey.IN_PDF}`,
  ],
  BAO_CAO: [
    cisPermission.BAO_CAO_TIEP_DON,
    cisPermission.BAO_CAO_VIEN_PHI,
    cisPermission.BAO_CAO_THU_THUAT,
  ],
  QUAN_LY_GOI_DV: [
    cisPermission.DANH_SACH_GOI_DV,
    cisPermission.QUAN_LY_DK_GOI_DV,
    cisPermission.QUAN_LY_SU_DUNG_GOI_DV,
  ],
  PHAU_THUAT_THU_THUAT: [
    `${cisPermission.PHAU_THUAT_THU_THUAT}.XEM`,
    `${cisPermission.PHAU_THUAT_THU_THUAT}.THUC_HIEN`,
    `${cisPermission.PHAU_THUAT_THU_THUAT}.THUC_HIEN_TRA_KQ`,
    `${cisPermission.PHAU_THUAT_THU_THUAT}.XEM_TT`,
    `${cisPermission.PHAU_THUAT_THU_THUAT}.HUY`,
  ],
};

export const prmPermission = {
  DASHBOARD: "PRM.DASHBOARD",
  BAO_CAO_DOANH_THU: "PRM.BAO_CAO_DOANH_THU",
  BAO_CAO_KH_CA_NHAN: "PRM.BAO_CAO_KH_CA_NHAN",
  BAO_CAO_KH_DOANH_NGHIEP: "PRM.BAO_CAO_KH_DOANH_NGHIEP",
  BAO_CAO_CUOC_GOI: "PRM.BAO_CAO_CUOC_GOI",
  BAO_CAO_HIEU_QUA_VOUCHER: "PRM.BAO_CAO_HIEU_QUA_VOUCHER",
  QUAN_LY_CUOC_GOI: "PRM.QUAN_LY_CUOC_GOI",
  QUAN_LY_DAT_HEN: "PRM.QUAN_LY_DAT_HEN",
  QUAN_LY_TIN_NHAN: "PRM.QUAN_LY_TIN_NHAN",
  QUAN_LY_DON_HANG: "PRM.QUAN_LY_DON_HANG",
  QUAN_LY_KPI: "PRM.QUAN_LY_KPI",
  QUAN_LY_KHACH_HANG_TIEM_NANG: "PRM.QUAN_LY_KHACH_HANG_TIEM_NANG",
  QUAN_LY_KHACH_HANG_CA_NHAN: "PRM.QUAN_LY_KHACH_HANG_CA_NHAN",
  QUAN_LY_KHACH_HANG_DOANH_NGHIEP: "PRM.QUAN_LY_KHACH_HANG_DOANH_NGHIEP",
  QUAN_LY_PHAN_HOI_KHACH_HANG: "PRM.QUAN_LY_PHAN_HOI_KHACH_HANG",
  EMAIL_MKT: "PRM.EMAIL_MKT",
  SMS_MKT: "PRM.SMS_MKT",
  QUAN_LY_VOUCHER: "PRM.QUAN_LY_VOUCHER",
};

export const danhMucPermission = {
  THONG_TIN_CSKCB: "MANAGEMENT.DM_CHUNG.THONG_TIN_CSKCB",
  QUAN_LY_NGUOI_DUNG: "MANAGEMENT.DM_CHUNG.QUAN_LY_NGUOI_DUNG",
  QUAN_LY_DV_CIS: "MANAGEMENT.DM_CIS.QUAN_LY_DV_CIS",
  QUAN_LY_DV_RIS: "MANAGEMENT.DM_RIS.QUAN_LY_DV_RIS",
  QUAN_LY_DV_LIS: "MANAGEMENT.DM_LIS.QUAN_LY_DV_LIS",
  QUAN_LY_DANH_MUC_CHUNG: "MANAGEMENT.DM_CHUNG.QUAN_LY_DANH_MUC_CHUNG",
  QUAN_LY_DANH_MUC_CIS: "MANAGEMENT.DM_CIS.QUAN_LY_DANH_MUC_CIS",
  QUAN_LY_DANH_MUC_LIS: "MANAGEMENT.DM_LIS.QUAN_LY_DANH_MUC_LIS",
  QUAN_LY_DANH_MUC_RIS: "MANAGEMENT.DM_RIS.QUAN_LY_DANH_MUC_RIS",
  MAY_THUC_HIEN_RIS: "MANAGEMENT.DM_RIS.MAY_THUC_HIEN_RIS",
  MAU_MO_TA_RIS: "MANAGEMENT.DM_RIS.MAU_MO_TA_RIS",
  THIET_BI_MAY_LIS: "MANAGEMENT.DM_LIS.THIET_BI_MAY_LIS",
  LOAI_BENH_PHAM_LIS: "MANAGEMENT.DM_LIS.LOAI_BENH_PHAM_LIS",
  DU_LIEU_DIEU_KHIEN_MAY_LIS: "MANAGEMENT.DM_LIS.DU_LIEU_DIEU_KHIEN_MAY_LIS",
  DUOC_NGOAI_TRU: "MANAGEMENT.DUOC_NGOAI_TRU",
  LICH_LAM_VIEC: "MANAGEMENT.DM_CIS.LICH_LAM_VIEC",

  DM_CHUNG: "MANAGEMENT.DM_CHUNG",
  QUAN_LY_CIS: "MANAGEMENT.DM_CIS",
  QUAN_LY_LIS: "MANAGEMENT.DM_LIS",
  QUAN_LY_RIS: "MANAGEMENT.DM_RIS",

  PRM: "MANAGEMENT.PRM",
};

export const danhMucPermissionTemplate = {
  DUOC_NGOAI_TRU: [
    `${danhMucPermission.DUOC_NGOAI_TRU}.${permissionActionKey.XEM}`,
    `${danhMucPermission.DUOC_NGOAI_TRU}.${permissionActionKey.THEM}`,
    `${danhMucPermission.DUOC_NGOAI_TRU}.${permissionActionKey.SUA}`,
    `${danhMucPermission.DUOC_NGOAI_TRU}.${permissionActionKey.XOA}`,
    `${danhMucPermission.DUOC_NGOAI_TRU}.${permissionActionKey.KHOA}`,
    `${danhMucPermission.DUOC_NGOAI_TRU}.${permissionActionKey.XUAT_EXCEL}`,
  ],
  DM_CHUNG: [
    danhMucPermission.THONG_TIN_CSKCB,
    danhMucPermission.QUAN_LY_NGUOI_DUNG,
    danhMucPermission.QUAN_LY_DANH_MUC_CHUNG,
  ],
  CIS: [
    danhMucPermission.QUAN_LY_DV_CIS,
    danhMucPermission.QUAN_LY_DANH_MUC_CIS,
    danhMucPermission.LICH_LAM_VIEC,
  ],
  RIS: [
    danhMucPermission.QUAN_LY_DV_RIS,
    danhMucPermission.MAU_MO_TA_RIS,
    danhMucPermission.MAY_THUC_HIEN_RIS,
  ],
  LIS: [
    danhMucPermission.QUAN_LY_DV_LIS,
    danhMucPermission.THIET_BI_MAY_LIS,
    danhMucPermission.DU_LIEU_DIEU_KHIEN_MAY_LIS,
  ],
};

export const prmPermissionTemplate = {
    DASHBOARD: [
      `${prmPermission.DASHBOARD}.${permissionActionKey.XEM}`,
      `${prmPermission.DASHBOARD}.${permissionActionKey.THEM}`,
      `${prmPermission.DASHBOARD}.${permissionActionKey.SUA}`,
      `${prmPermission.DASHBOARD}.${permissionActionKey.XOA}`,
      `${prmPermission.DASHBOARD}.${permissionActionKey.IN_PDF}`,
    ],
    BAO_CAO: Object.entries(prmPermission).map((objArr) => objArr[0].includes("BAO_CAO") && objArr[1]),
    QUAN_LY_CONG_VIEC: [
      prmPermission.QUAN_LY_CUOC_GOI,
      prmPermission.QUAN_LY_DAT_HEN,
      prmPermission.QUAN_LY_TIN_NHAN,
      prmPermission.QUAN_LY_DON_HANG,
      prmPermission.QUAN_LY_KPI,
    ],
    QUAN_LY_KHACH_HANG: [
      prmPermission.QUAN_LY_KHACH_HANG_TIEM_NANG,
      prmPermission.QUAN_LY_KHACH_HANG_CA_NHAN,
      prmPermission.QUAN_LY_KHACH_HANG_DOANH_NGHIEP,
    ],
    MARKETING: [prmPermission.EMAIL_MKT, prmPermission.SMS_MKT, prmPermission.QUAN_LY_VOUCHER],
    QUAN_LY_PHAN_HOI_KHACH_HANG: [
      `${prmPermission.QUAN_LY_PHAN_HOI_KHACH_HANG}.${permissionActionKey.XEM}`,
      `${prmPermission.QUAN_LY_PHAN_HOI_KHACH_HANG}.${permissionActionKey.THEM}`,
      `${prmPermission.QUAN_LY_PHAN_HOI_KHACH_HANG}.${permissionActionKey.SUA}`,
      `${prmPermission.QUAN_LY_PHAN_HOI_KHACH_HANG}.${permissionActionKey.XOA}`,
      `${prmPermission.QUAN_LY_PHAN_HOI_KHACH_HANG}.${permissionActionKey.IN_PDF}`,
    ],
  };
  
import { notification } from "antd";
import { call, put, putResolve, takeLatest } from "redux-saga/effects";
import { allPermissions } from "../../../components/QuanLyNguoiDung/Components/phanQuyenKey";
import { getErrorMessage } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import { UserActions } from "../../slices/DM_Chung/QuanlyNguoiDungSlice";
import { setTableLoading } from "../../slices/loadingSlice";
import { postAddUser, postDelUsers, postGetUser, postUpdateUser } from "./apiDM_Chung";

export function* watchDoUsers() {
  yield takeLatest(UserActions.doGetUsers, handleGetData);
  yield takeLatest(UserActions.doDeleteUsers, handleDeleteUsers);
  yield takeLatest(UserActions.doUpdateUsers, handleUpdateUsers);
  yield takeLatest(UserActions.doGetPermissions, handleGetPermissions);
  yield takeLatest(UserActions.doAddUsers, handleAddUsers);
}

export function* handleGetData(action) {
  try {

    yield put(setTableLoading(true));
    console.log("💕💕💕💕💕💕💕💕")
    const res = yield postGetUser(action.payload);

    if (!!res) {
      if (res.status === "OK") {
        yield put(UserActions.getUsersSucess(res));
      } else {
        let error_msg = getErrorMessage(
          res.error_code,
          i18n.t(languageKeys.noti_lay_danh_sach_that_bai)
        );
        notification.error({
          message: error_msg,
          placement: "bottomLeft",
        });
        yield put(UserActions.getUsersError(res));
      }
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
        placement: "bottomLeft",
      })
    }
    yield put(setTableLoading(false))
  } catch (error) {
    yield put(setTableLoading(false))
    console.log(error);
  }
}


export function* handleDeleteUsers({ payload }) {
  try {
    yield put(UserActions.setLoadingDelete(true));
    let { delRequest, dataReload } = payload;
    const res = yield call(postDelUsers, delRequest);

    if (!!res) {
      if (res.status === "OK") {
        yield putResolve(UserActions.deleteUsersSucess(false));
        yield putResolve(UserActions.doGetUsers(dataReload));
      } else {
        let error_msg = getErrorMessage(
          res.error_code,
          i18n.t(languageKeys.noti_Xoa_that_bai)
        );
        notification.error({
          message: error_msg,
          placement: "bottomLeft",
        });
        yield put(UserActions.deleteUsersError(res));
      }
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_Xoa_that_bai),
        placement: "bottomLeft",
      });
    }
    yield putResolve(UserActions.setLoadingDelete(false));
  } catch (error) {
    yield putResolve(UserActions.setLoadingDelete(false));
    console.log(error);
  }

}



export function* handleGetPermissions() {
  try {
    yield putResolve(UserActions.setLoading(true));
    yield putResolve(UserActions.getPermissionSuccess({ result: allPermissions }));

    yield putResolve(UserActions.setLoading(false));
  } catch (err) {
    yield putResolve(UserActions.setLoading(false));
  }
}

function* handleAddUsers(action) {
  try {
    yield putResolve(UserActions.setLoading(true));
    let { dataAdd, dataReload } = action.payload;


    const handleQuyenModule = (item) => {
      try {
        return item.QUYEN_MODULE.filter(i => item.MODULE_QL.includes(i.split(".")[0]));
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    
    const res = yield call(postAddUser, {
      ...dataAdd,
      data: dataAdd.data?.map(item => ({
        ...item,
        QUYEN_MODULE:handleQuyenModule(item)
      }))
  });

  if (!!res) {
    if (res && res.status === 'OK') {
      // yield onUpdateParmaGateUser(action.payload?.dataAdd?.[0]);
      yield putResolve(UserActions.setLoading(false));
      yield putResolve(UserActions.addUserSuccess(res));
      yield putResolve(UserActions.doGetUsers(dataReload));
    } else {
      if (res.error_code === "001" && res.message === "SDT đã được sử dụng") {
        notification.error({ message: i18n.t(languageKeys.trung_sdt), placement: "bottomLeft" });
      } else {
        yield putResolve(UserActions.handleErrorMessage(res));
      }
    }
  } else {
    yield putResolve(UserActions.handleErrorMessage(res));
  }
  yield putResolve(UserActions.setLoading(false));
} catch (error) {
  console.log(error)
  yield putResolve(UserActions.setLoading(false));

}
};


function* handleUpdateUsers(action) {
  try {
    yield putResolve(UserActions.setLoading(true));
    let { dataUpdate, dataReload } = action.payload;

    const res = yield call(postUpdateUser, {
      ...dataUpdate,
      QUYEN_MODULE: dataUpdate.QUYEN_MODULE.filter(i => dataUpdate.MODULE_QL.includes(i.split(".")[0]))
    });

    if (!!res && res.status === 'OK') {
      // yield onUpdateParmaGateUser(action.payload?.dataUpdate);
      yield putResolve(UserActions.setLoading(false));
      yield putResolve(UserActions.updateUserSuccess(res));
      yield putResolve(UserActions.doGetUsers(dataReload));
    } else {
      if (res.error_code === "001" && res.message === "exist_USERNAME") {
        notification.error({ message: i18n.t(languageKeys.ton_tai_username_sdt), placement: "bottomLeft" });
      } else {
        yield putResolve(UserActions.handleErrorMessage(res));
      }
    }
    yield putResolve(UserActions.setLoading(false));
    

  } catch (error) {
    yield putResolve(UserActions.setLoading(false));

  }
};

// function* onUpdateParmaGateUser(payload) {
//   try {
//     let userProfile = yield select(state => state.auth.user);
//     let { NHANSU_ID, BENH_VIEN_ID, MA_CONG_DUOC_BS, MAT_KHAU_CONG_DUOC_BS } = payload;

//     return yield call(postLuuThongTinBacSiCongDuoc, {
//       partner_code:userProfile.partner_code,
//       NHANSU_ID,
//       BENH_VIEN_ID,
//       MA_CONG_DUOC_BS,
//       MAT_KHAU_CONG_DUOC_BS,
//     });

//   } catch (error) {
//     throw error
//   }
// }

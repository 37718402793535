import moment from "moment";
import i18n, { languageKeys } from "../i18n";

export const paths = {
  REGISTER_MANAGERMENT: "/register",
  LOGIN: "login",
  HOME: "clinic-management",
  CUSTOMER: "customer",
  ACCOUNT: "account",
  PARTNER: "partner",
  authenticated: "/authenticated",
  LANDING_PAGE: "home",
  CATALOG: "catalog",
  DM_CHUNG: "dm_chung",
  DM_DICHVU: "dm_dich_vu",
  DM_THUOC: "dm_thuoc",
  CAU_HINH: "cau_hinh",
  DENY:"permission-deny"
};

export const commonConfig = {
  limitData: 15, //giới hạn số phần tử mỗi trang khi gọi api
  ENV_NAME: "DEEPCARE",
};

export const formatDateBE = "YYYYMMDD";

export const LOADING_STATUS = {
  idle: "idle",
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed",
};

export const clinicType = {
  DA_KHOA: "DA_KHOA",
  CHUYEN_KHOA: "CHUYEN_KHOA",
  NHA_KHOA: "NHA_KHOA",
  THAM_MY: "THAM_MY",
  YHCT: "YHCT",
};
export const serviceProvide = {
  CNTT: "CNTT",
  SETUP_VAN_HANH: "SETUP_VAN_HANH",
  TU_VAN_PHAP_LY: "TU_VAN_PHAP_LY",
  THUONG_HIEU_BS: "THUONG_HIEU_BS",
};

export const provideService = {
  OPEN_CLINIC: "MO_PHONG_KHAM",
  OPTIMAL_CLINIC: "TOI_UU_HOAT_DONG",
  CHAIN_CLINIC: "MO_CHUOI_PHONG_KHAM",
};

export const stepProgressList = {
  START_PROGRESS: "START_PROGRESS", // màn bắt đầu
  TYPE_CLINIC: "TYPE_CLINIC", // màn chọn loại phòng khám
  SERVICE_CLINIC: "SERVICE_CLINIC", // màn cung cấp thông tin dịch vụ, chuyên khoa
  SERVICE_ADVISE: "SERVICE_ADVISE", // màn khảo sát dịch vụ phòng khám
  CLIENT_INFOMATION: "CLIENT_INFOMATION", // màn thông tin liên hệ
  OTP: "OTP", // màn xác nhận OTP
  END_PROGRESS: "END_PROGRESS", // kết thúc
};

export const arrWeek = ["CN", "Th2", "Th3", "Th4", "Th5", "Th6", "Th7"];

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USER_PROFILE = "USER_PROFILE";

export const FILTER_TIME_OPTION = [
  {
    title: "Hôm nay",
    key: "TODAY",
  },
  {
    title: "Hôm qua",
    key: "YESTERDAY",
  },
  {
    title: "Ngày mai",
    key: "NEXT_DAY",
  },
  {
    title: "Tuần này",
    key: "CURRENT_WEEK",
  },
  {
    title: "30 ngày gần nhất",
    key: "NEXT_MOTH",
  },
];

export const solutions = {
  SET_UP: "SETUP_VAN_HANH",
  CNTT: "CNTT",
  TU_VAN: "TU_VAN_PHAP_LY",
  THUONG_HIEU: "THUONG_HIEU_BS",
};

export const clientStatus = {
  DA_DANG_KY: {
    title: "Đã đăng ký",
    value: "DA_DANG_KY",
    key: "DA_DANG_KY",
  },
  DANG_TU_VAN: {
    title: "Đang tư vấn",
    value: "DANG_TU_VAN",
    key: "DANG_TU_VAN",
  },
  DANG_THAM_GIA_CHUONG_TRINH: {
    title: "Đang tham gia chương trình",
    value: "DANG_THAM_GIA_CHUONG_TRINH",
    key: "DANG_THAM_GIA_CHUONG_TRINH",
  },
  KET_THUC_CHUONG_TRINH: {
    title: "Kết thúc chương trình",
    value: "KET_THUC_CHUONG_TRINH",
    key: "KET_THUC_CHUONG_TRINH",
  },
};

export const roleKey = {
  ADMIN: "ADMIN",
  USER: "USER",
};

export const typeDemand = [
  {
    title: "Mở phòng khám",
    key: provideService.OPEN_CLINIC,
    value: provideService.OPEN_CLINIC,
  },
  {
    title: "Tối ưu hoạt động của phòng khám",
    key: provideService.OPTIMAL_CLINIC,
    value: provideService.OPTIMAL_CLINIC,
  },
  {
    title: "Mở chuỗi phòng khám",
    key: provideService.CHAIN_CLINIC,
    value: provideService.CHAIN_CLINIC,
  },
];

export const OTP_DURATION = 300;

export const ROLE_USERS = [
  {
    TEN_VAI_TRO: "Nhân viên",
    MA_VAI_TRO: "NHAN_VIEN",
  },
  {
    TEN_VAI_TRO: "Chuyên viên",
    MA_VAI_TRO: "CHUYEN_VIEN",
  },
  {
    TEN_VAI_TRO: "Quản lý",
    MA_VAI_TRO: "QUAN_LY",
  },
];

export const listDateFilter = [
  {
    title: "Hôm qua",
    key: "HOM_QUA",
  },
  {
    title: "Hôm nay",
    key: "HOM_NAY",
  },
  {
    title: "Ngày mai",
    key: "NGAY_MAI",
  },

  {
    title: "Tuần này",
    key: "TUAN_NAY",
  },
  {
    title: "30 ngày mới",
    key: "30_NGAY_MOI",
  },
];

export const timeCaseListDate = (value) => {
  let dateString = ["", ""];
  let startDate = "";
  let endDate = "";
  switch (value) {
    case listDateFilter[0].key:
      startDate = moment().subtract(1, "days").format(formatDateBE);
      endDate = moment().subtract().format(formatDateBE);
      break;
    case listDateFilter[1].key:
      startDate = moment().format(formatDateBE);
      endDate = moment().format(formatDateBE);
      break;
    case listDateFilter[2].key:
      startDate = moment().format(formatDateBE);
      endDate = moment().add(1, "days").format(formatDateBE);
      break;
    case listDateFilter[3].key:
      startDate = moment().clone().startOf("isoWeek").format(formatDateBE);
      endDate = moment().clone().endOf("isoWeek").format(formatDateBE);
      dateString = [startDate, endDate];

      break;
    case listDateFilter[4].key:
      startDate = moment().format(formatDateBE);
      endDate = moment().add(30, "days").format(formatDateBE);
      dateString = [startDate, endDate];
      break;

    default:
      startDate = "";
      endDate = "";
      break;
  }

  return [startDate, endDate];
};

export const dataXetNghiem = [
  {
    value: "VI_SINH",
    title: "Vi sinh",
  },
  {
    value: "SINH_HOC_PHAN_TU",
    title: "Sinh học phân tử",
  },
  {
    value: "TE_BAO",
    title: "Tế bào",
  },
  {
    value: "NUOC_TIEU",
    title: "Nước tiểu",
  },
  {
    value: "HUYET_HOC",
    title: "Huyết học",
  },
  {
    value: "SINH_HOA",
    title: "Sinh hóa",
  },
  {
    value: "GIAI_PHAU_BENH",
    title: "Giải phẫu bệnh",
  },
];

export const dataDiagnose = [
  {
    value: "SIEU_AM",
    title: "Siêu âm",
  },
  {
    value: "CT",
    title: "CT",
  },
  {
    value: "X_QUANG",
    title: "Xquang",
  },
  {
    value: "CHUP_MRI",
    title: "Chụp MRI (Cộng hưởng từ)",
  },
  {
    value: "MAMO",
    title: "Mamo",
  },
  {
    value: "DO_LOANG_XUONG",
    title: "Loãng xương",
  },
];

export const formPattern = {
  numberOnly: /^[0-9]*$/,
  email: /\S+@\S+\.\S+/,
  // phone: /^[0-9]*$/,
  phone: /^[0]?[35789]\d{8}$/,
  fullName: /[a-zA-Z]+\s+[a-zA-Z]+/g,
  numberDecimalPositive: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
  huyet_ap: /^\d{1,3}\/\d{1,3}$/,
};

export const chuyenKhoaBoXN = {
  CK_DINH_DUONG: "K45",
  CK_THAM_MY: "K52",
  CK_TAM_THAN: "K14",
  CK_TAI_MUI_HONG: "K28",
  CK_MAT: "K30",
  CK_RANG_HAM_MAT: "K29",
};

// Điện não 	DIEN_NAO_DO
// Phục hồi chức năng	PHUC_HOI_CHUC_NANG
export const thamDoChucNang = [
  {
    title: "Nội soi tiêu hóa",
    value: "NOI_SOI_TIEU_HOA",
  },
  {
    title: "Điện tim",
    value: "DIEN_TIM",
  },
  {
    title: "Nội soi tai mũi họng",
    value: "NOI_SOI_TAI_MUI_HONG",
  },
  {
    value: "DIEN_NAO_DO",
    title: "Điện não",
  },
  {
    title: "Soi cổ tử cung",
    value: "SOI_CO_TU_CUNG",
  },
  {
    value: "PHUC_HOI_CHUC_NANG",
    title: "Phục hồi chức năng",
  },
];

export const headerHeight = 65;

export const subRoutes = {
  dm_nhom_doi_tuong: "dm_nhom_doi_tuong",
  dm_khoa: "dm_khoa",
  dm_phong: "dm_phong",
  dm_nhom_dich_vu: "dm_nhom_dich_vu",
  dm_loai_dich_vu: "dm_loai_dich_vu",
  dm_quan_ly_phan_he: "dm_quan_ly_phan_he",
  dm_quan_ly_nhan_su: "dm_quan_ly_nhan_su",
};

export const keysDanhMuc = {
  dm_noi_tru_phong: "dm_noi_tru_phong", // danh mục phòng nội trú
  dm_benh_vien: "dm_benh_vien", // danh mục bệnh viện
  dm_khoa: "dm_khoa", // danh mục khoa
  dm_phong: "DM_PHONG", // danh mục phòng
  dm_dich_vu_ki_thuat: "DM_DICHVU_KYTHUAT", // danh mục dịch vụ kĩ thuật
  dm_nhom_dv_ki_thuat: "dm_nhom_dv_ki_thuat", // danh mục nhóm dịch vụ kĩ thuật
  dm_loai_dv_ki_thuat: "DM_LOAI_DICHVU_KYTHUAT", // danh mục loại dịch vụ kĩ thuật
  dm_nhom_hoa_don: "dm_nhom_hoa_don", // danh mục nhóm hóa đơn
  dm_thuoc: "DM_THUOC", // danh mục thuốc
  dm_nha_thuoc: "dm_nha_thuoc", // danh mục nhà thuốc
  dm_hang_san_xuat: "dm_hang_san_xuat", // danh mục hãng sản xuất
  dm_duong_dung: "dm_duong_dung", // danh mục đường dùng
  dm_kho_tu_truc: "dm_kho_tu_truc", // danh mục kho, tủ trực
  dm_don_vi_tinh: "dm_don_vi_tinh", // danh mục đơn vị tính
  dm_hoat_chat: "DM_HOAT_CHAT", // danh mục kho, tủ trực
  dm_tinh: "dm_tinh", // danh mục tỉnh
  dm_huyen: "dm_huyen", // danh mục huyện
  dm_xa: "dm_xa", // danh mục xã
  dm_icd10: "dm_icd10", // danh mục ICD10
  dm_nhom_icd10: "dm_nhom_icd10", // danh mục nhóm ICD10
  dm_nghe_nghiep: "dm_nghe_nghiep", // danh mục nghề nghiệp
  dm_dan_toc: "dm_dan_toc", // danh mục dân tộc
  dm_quoc_gia: "dm_quoc_gia", // danh mục quốc gia
  //dm_kho_tu_truc:"dm_kho_tu_truc",
  dm_giuong: "dm_giuong",
  dm_pp_vo_cam: "dm_pp_vo_cam",
  dm_xuat_khac: "dm_xuat_khac",
  dm_nguon: "dm_nguon",
  dm_kho_thuoc: "dm_kho_thuoc",
  dm_may_thuc_hien: "dm_may_thuc_hien",
  dm_nguon_khach: "dm_nguon_khach",
  dm_doi_tuong: "dm_doi_tuong",
  dm_nhom_doi_tuong_nguoi_dung: "dm_nhom_doi_tuong_nguoi_dung",
  dm_cach_dung: "dm_cach_dung",
  dm_hinh_thuc_thanh_toan: "dm_hinh_thuc_thanh_toan",
  dm_cachthuc_thuthuat: "dm_cachthuc_thuthuat",
  dm_phuongphap_thu_thuat: "dm_phuongphap_thuthuat",
  dm_nha_cung_cap: "dm_nha_cung_cap",
  dm_quan_ly_pk_ve_tinh: "dm_quan_ly_pk_ve_tinh",
  dm_loai_goi_dv: "dm_loai_goi_dv",
};

export const hospitalLines = [
  {
    ID: "tu_nhan_BV_da_khoa",
    TEN: "Tư nhân bệnh viện đa khoa",
    MA_TUYEN: "tu_nhan_BV_da_khoa",
  },
  {
    ID: "tu_nhan_PK_da_khoa",
    TEN: "Tư nhân Phòng khám đa khoa",
    MA_TUYEN: "tu_nhan_PK_da_khoa",
  },
  { ID: "tuyen_huyen", TEN: "Tuyến huyện", MA_TUYEN: "tuyen_huyen" },
  { ID: "tuyen_tinh", TEN: "Tuyến tỉnh", MA_TUYEN: "tuyen_tinh" },
  { ID: "tuyen_tw", TEN: "Tuyến trung ương", MA_TUYEN: "tuyen_tw" },
];

export const MO_HINH_CSKCB_VALUE = {
  chuoi: "CHUOI",
  hub: "HUB",
  mot_phong_kham: "MOT_PHONG_KHAM",
};

export const moHinhCSKCBoptions = [
  {
    TEN:'Mô hình chuỗi',
    ID:MO_HINH_CSKCB_VALUE.chuoi
  },
  {
    TEN:'Mô hình Hub',
    ID:MO_HINH_CSKCB_VALUE.hub
  },
  {
    TEN:'Mô hình một phòng khám',
    ID:"MOT_THANH_VIEN"
  },
]
export const routeKeyServices = {
  dm_kham_chua_benh:"dm_kham_chua_benh",
  dm_xet_nghiem:"dm_xet_nghiem",
  dm_chi_so_xet_nghiem:"dm_chi_so_xet_nghiem"
}

export const routeKeyMed = {
  dm_kho_thuoc:"dm_kho_thuoc",
  dm_thuoc:"dm_thuoc",
  nhap_dau_ky:"nhap_dau_ky"
}

export const keyNhomDoiTuongNguoiDung = {
  BSFulltime: 'BACSI_FULLTIME',
  BSParttime: 'BACSI_PARTTIME',
  chuyen_gia: 'CHUYEN_GIA',
  CSKCB: 'CSKCB',
  nhan_vien: 'NHANVIEN',
  sales: 'SALES',
  khach_hang: 'KHACH_HANG',
  CTV: 'CTV',
  bacsi_ctv: 'BACSI_CTV',
};


export const keys = {
  gioi_tinh_NAM: "nam",
  gioi_tinh_NU: "nu",
}

export const specialistCatalog = {
  DA_KHOA : {
    value: "DA_KHOA",
    label:"Đa khoa",
    groups: ["CIS","RIS","DUOC","BAO_CAO","MANAGEMENT","LIS"]
  },
  KHAM_SAN : {
    value: "KHAM_SAN",
    label:"Khám sản",
    groups: ["CIS","RIS","DUOC","BAO_CAO","MANAGEMENT","LIS",]
  },
  TMH : {
    value: "TMH",
    label:"TMH",
    groups: ["CIS","RIS","DUOC","BAO_CAO","MANAGEMENT",]
  },
  CO_XUONG_KHOP : {
    value: "CO_XUONG_KHOP",
    label:"Cơ xương khớp",
    groups: ["CIS","DUOC","BAO_CAO","MANAGEMENT"]
  },
  PHUC_HOI_CHUC_NANG : {
    value: "PHUC_HOI_CHUC_NANG",
    label:"Phục hồi chức năng",
    groups: ["CIS","DUOC","BAO_CAO","MANAGEMENT"]
  },
  KHAM_NOI : {
    value: "KHAM_NOI",
    label:"Khám nội",
    groups: ["CIS","RIS","DUOC","BAO_CAO","MANAGEMENT","LIS",]
  },
}

export const CHUYEN_KHOA_HANH_CHINH = [
  {
    name: i18n.t(languageKeys.DCCK03),
    code: "DCCK03",
  },
  {
    name: i18n.t(languageKeys.DCCK10),
    code: "DCCK10",
  },
  {
    name: i18n.t(languageKeys.DCCK11),
    code: "DCCK11",
  },
  {
    name: i18n.t(languageKeys.DCCK31),
    code: "DCCK31",
  },
  {
    name: i18n.t(languageKeys.DCCK34),
    code: "DCCK34",
  },
  {
    name: i18n.t(languageKeys.DCCK36),
    code: "DCCK36",
  },
  {
    name: i18n.t(languageKeys.DCCK40),
    code: "DCCK40",
  },
  {
    name: i18n.t(languageKeys.DCCK20),
    code: "DCCK20",
  },
  {
    name: i18n.t(languageKeys.DCCK28),
    code: "DCCK28",
  },
  {
    name: i18n.t(languageKeys.DCCK01),
    code: "DCCK01",
  },
  {
    name: i18n.t(languageKeys.DCCK18),
    code: "DCCK18",
  },
  {
    name: i18n.t(languageKeys.DCCK06),
    code: "DCCK06",
  },
  {
    name: i18n.t(languageKeys.DCCK24),
    code: "DCCK24",
  },
  {
    name: i18n.t(languageKeys.DCCK15),
    code: "DCCK15",
  },
  {
    name: i18n.t(languageKeys.DCCK22),
    code: "DCCK22",
  },
  {
    name: i18n.t(languageKeys.DCCK14),
    code: "DCCK14",
  },
  {
    name: i18n.t(languageKeys.DCCK16),
    code: "DCCK16",
  },
  {
    name: i18n.t(languageKeys.DCCK21),
    code: "DCCK21",
  },
  {
    name: i18n.t(languageKeys.DCCK23),
    code: "DCCK23",
  },
  {
    name: i18n.t(languageKeys.DCCK27),
    code: "DCCK27",
  },
  {
    name: i18n.t(languageKeys.DCCK35),
    code: "DCCK35",
  },
  {
    name: i18n.t(languageKeys.DCCK37),
    code: "DCCK37",
  },
  {
    name: i18n.t(languageKeys.DCCK39),
    code: "DCCK39",
  },
  {
    name: i18n.t(languageKeys.DCCK42),
    code: "DCCK42",
  },
  {
    name: i18n.t(languageKeys.DCCK41),
    code: "DCCK41",
  },
  {
    name: i18n.t(languageKeys.DCCK43),
    code: "DCCK43",
  },
  {
    name: i18n.t(languageKeys.DCCK46),
    code: "DCCK46",
  },
  {
    name: i18n.t(languageKeys.DCCK47),
    code: "DCCK47"
  },
  {
    name: i18n.t(languageKeys.DCCK48),
    code: "DCCK48"
  },
  {
    name: i18n.t(languageKeys.DCCK49),
    code: "DCCK49"
  },
  {
    name: i18n.t(languageKeys.DCCK50),
    code: "DCCK50"
  },
  {
    name: i18n.t(languageKeys.DCCK51),
    code: "DCCK51"
  },
  {
    name: i18n.t(languageKeys.DCCK52),
    code: "DCCK52"
  },
  {
    name: i18n.t(languageKeys.DCCK53),
    code: "DCCK53"
  },
  {
    name: i18n.t(languageKeys.DCCK54),
    code: "DCCK54"
  },
  {
    name: i18n.t(languageKeys.DCCK55),
    code: "DCCK55"
  },
  {
    name: i18n.t(languageKeys.DCCK56),
    code: "DCCK56"
  },
  {
    name: i18n.t(languageKeys.DCCK57),
    code: "DCCK57"
  },
  {
    name: i18n.t(languageKeys.DCCK58),
    code: "DCCK58"
  },
  {
    name: i18n.t(languageKeys.DCCK59),
    code: "DCCK59"
  },
  {
    name: i18n.t(languageKeys.DCCK60),
    code: "DCCK60"
  },
  {
    name: i18n.t(languageKeys.DCCK61),
    code: "DCCK61"
  },
  {
    name: i18n.t(languageKeys.DCCK62),
    code: "DCCK62"
  },
  {
    name: i18n.t(languageKeys.DCCK63),
    code: "DCCK63"
  },
  {
    name: i18n.t(languageKeys.DCCK64),
    code: "DCCK64"
  },
  {
    name: i18n.t(languageKeys.DCCK65),
    code: "DCCK65"
  },
];
import { takeLatest } from "redux-saga/effects";
import {
  addDoiTuong,
  deleteDoiTuong,
  editDoiTuong,
  exportDmDoiTuong,
  getDataSuccess,
  getDmDoiTuong,
  importDmDoiTuong,
  lockDmDoiTuong,
  updateDoiTuong,
} from "../../slices/DM_Chung/DmDoiTuongSlice";
import {
  handleCommonAdd,
  handleCommonDelete,
  handleCommonEdit,
  handleCommonExport,
  handleCommonGet,
  handleCommonImport,
  handleCommonLock,
} from "../commonSaga/commonSaga";
import { fieldDoiTuong } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog";
import {
  dispatchAddDoiTuong,
  dispatchDeleteDoiTuong,
  dispatchEditDoiTuong,
  dispatchExportDmDoiTuong,
  dispatchGetDmDoiTuong,
  dispatchImportDmDoiTuong,
  dispatchLockDoiTuong,
} from "./apiDM_Chung";

export function* watchDoDmDoiTuong() {
  yield takeLatest(getDmDoiTuong, handleGetDmDoiTuong);
  yield takeLatest(addDoiTuong, handleAddDoiTuong);
  yield takeLatest(editDoiTuong, handleEditDoiTuong);
  yield takeLatest(exportDmDoiTuong, handleExportDmDoiTuong);
  yield takeLatest(lockDmDoiTuong, handleLockDoiTuong);
  yield takeLatest(importDmDoiTuong, handleImportDmDoiTuong);
  yield takeLatest(deleteDoiTuong, handleDeleteDoiTuong);
}

function* handleGetDmDoiTuong(action) {
  const info = action.payload;
  yield handleCommonGet(
    {
      page: info.page,
      search_string: info.search_string,
      limit: info.limit,
      partner_code:info.partner_code,
      BENH_VIEN_ID: info.BENH_VIEN_ID,
    },
    dispatchGetDmDoiTuong,
    getDataSuccess,
    info.isTableLoading
  );
}

function* handleAddDoiTuong(action) {
  const info = action.payload;
  yield handleCommonAdd(
    {
      add: {
        ...info.add.data,
      },
      get: info.get,
    },
    dispatchAddDoiTuong,
    getDmDoiTuong
  );
}

function* handleEditDoiTuong(action) {
  const info = action.payload;
  yield handleCommonEdit(
    {
      edit: {
        ...info.edit.data,
      },
      get: info.get,
    },
    dispatchEditDoiTuong,
    getDmDoiTuong
  );
}

function* handleExportDmDoiTuong(action) {
  const info = action.payload;
  yield handleCommonExport(
    {
      template: 0,
    },
    dispatchExportDmDoiTuong,
    info.name
  );
}

function* handleLockDoiTuong(action) {
  const info = action.payload;
  yield handleCommonLock(
    {
      arr_ID: info.data,
      KHOA: info.lockVal,
      get: info.get,
    },
    dispatchLockDoiTuong,
    getDmDoiTuong,
    updateDoiTuong
  );
}

function* handleImportDmDoiTuong(action) {
  const info = action.payload;
  yield handleCommonImport(
    {
      import: { data: info.import.data },
      get: info.get,
    },
    dispatchImportDmDoiTuong,
    getDmDoiTuong
  );
}

function* handleDeleteDoiTuong(action) {
  const info = action.payload;
  yield handleCommonDelete(
    {
      delete: {
        arr_ID: Array.isArray(info.delete.data)
          ? info.delete.data.map((item) => item[fieldDoiTuong.id])
          : [info.delete.data[fieldDoiTuong.id]],
      },
      get: info.get,
    },
    dispatchDeleteDoiTuong,
    getDmDoiTuong
  );
}

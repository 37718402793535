import { createSlice } from "@reduxjs/toolkit";
import { notification } from "../../../components";
import { getErrorMessage } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import routeKeys from "../routeKeys";

const initialState = {
  error: {},
  message: "",
  loadingDelete: false,
  loading: false,
  listUsers: [],
  selectedUser: undefined,
  permissionProvider: [],
  permissions: [],
  paginations: {
    page: 1,
    total: 0,
  },

  stateSuccess: undefined,
};
const sliceName = "QuanlyNguoiDung";
const QuanlyNguoiDungSlice = createSlice({
  initialState,
  name: sliceName,
  reducers: {
    doDeleteUsers: () => {},
    doUpdateUsers: () => {},
    doAddUsers: () => {},
    doGetPermissions: () => {},
    getPermissionSuccess: (state, { payload }) => {
      state.permissionProvider = payload.result;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    doGetUsers: (state, { payload }) => {
      const { page, total } = payload;
      state.paginations = {
        page,
        total,
      };
    },
    getUsersSucess: (state, { payload }) => {
      state.listUsers = payload.result;
      const { total } = payload;
      state.paginations = {
        ...state.paginations,
        total,
      };

      // state.listUsers = payload
    },
    getUsersError: (state, { payload }) => {},
    addUserSuccess: (state, { payload }) => {
      notification.success_add_new();
      state.stateSuccess = payload;
    },
    updateUserSuccess: (state, { payload }) => {
      notification.success_edit();
      state.stateSuccess = payload;
    },
    handleErrorMessage: (state, { payload }) => {
      if (payload) {
        notification.error(
          getErrorMessage(
            payload.error_code,
            i18n.t(languageKeys.thao_tac_that_bai),
            payload
          )
        );
      } else {
        notification.error_edit();
      }
    },
    deleteUsersSucess: (state, { payload }) => {
      notification.success_delete();
    },

    deleteUsersError: (state, { payload }) => {},

    setLoadingDelete: (state, { payload }) => {
      state.loadingDelete = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setCurrentPermission: (state, { payload }) => {
      state.permissions = payload;
    },
    resetPermissions: (state, { payload }) => {
      state.permissions = [];
    },
    resetMessage: (state, { payload }) => {
      state.message = "";
      state.error = {};
      state.stateSuccess = undefined;
    },
  },
});

export const { actions: UserActions } = QuanlyNguoiDungSlice;
export const User = {
  listUsers: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung].listUsers,
  loadingDelete: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung]
      .loadingDelete,
  loading: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung].loading,
  paginations: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung]
      .paginations,
  selectedUser: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung]
      .selectedUser,
  permissions: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung]
      .permissions,
  permissionProvider: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung]
      .permissionProvider,
  stateSuccess: (state) =>
    state[routeKeys.store.DM_Chung][routeKeys.slices.QuanlyNguoiDung]
      .stateSuccess,
};
export default QuanlyNguoiDungSlice.reducer;

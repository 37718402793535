import _ from "lodash";
import {
  provideService,
  stepProgressList,
  clinicType,
} from "./utils/constant";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import cryptoRandomString from "crypto-random-string";
import sha256 from "sha256";

export const formPattern = {
  numberOnly: /^[0-9]*$/,
  email: /\S+@\S+\.\S+/,
  // phone: /^[0-9]*$/,
  phone: /^[0]?[35789]\d{8}$/,
  fulllabel: /[a-zA-Z]+\s+[a-zA-Z]+/g,
  numberDecimalPositive: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
  huyet_ap: /^\d{1,3}\/\d{1,3}$/,
};

/**
 *
 * @param {*} currentStep Bước hiện tại
 * @returns trạng thái bước kế tiếp
 */
export const handleGoToStepWithType = (
  currentStep,
  demand,
  typeClinic,
  to = 1
) => {
  let incomingStep = null;
  const arr_step = Object.values(stepProgressList);
  const chain_step = [
    "START_PROGRESS",
    "CLIENT_INFOMATION",
    "OTP",
    "END_PROGRESS",
  ];
  const handleGoStepWithTypeClinic = (typeClinic) => {
    switch (typeClinic) {
      case clinicType.DA_KHOA:
      case clinicType.CHUYEN_KHOA:
        return arr_step[arr_step.findIndex((ele) => ele === currentStep) + to];
      case clinicType.NHA_KHOA:
      case clinicType.THAM_MY:
        const typeStep = arr_step.filter(
          (ele) => ele !== stepProgressList.SERVICE_CLINIC
        );
        return typeStep[typeStep.findIndex((ele) => ele === currentStep) + to];
      case clinicType.YHCT:
        const stepYhct = arr_step.filter(
          (ele) => ele !== stepProgressList.SERVICE_ADVISE
        );
        return stepYhct[stepYhct.findIndex((ele) => ele === currentStep) + to];
      default:
        break;
    }
  };

  switch (demand) {
    case provideService.OPEN_CLINIC:
    case provideService.OPTIMAL_CLINIC:
      incomingStep = handleGoStepWithTypeClinic(typeClinic);
      break;
    case provideService.CHAIN_CLINIC:
      incomingStep =
        chain_step[chain_step.findIndex((ele) => ele === currentStep) + to];
      break;
    default:
      break;
  }
  // const typeService = Object.values(provideService)

  return incomingStep;
};

export const convertDay = (day) => {
  switch (day) {
    case 0:
      return "Chủ nhật";
    case 1:
      return "Thứ 2";
    case 2:
      return "Thứ 3";
    case 3:
      return "Thứ 4";
    case 4:
      return "Thứ 5";
    case 5:
      return "Thứ 6";
    case 6:
      return "Thứ 7";
  }
};

export const convertJson = async (jsonString) => {
  try {
    return await JSON.parse(jsonString);
  } catch (error) {
    return await jsonString;
  }
};

/**
 * @name validFragment
 * @description pick properties for insert input data into database
 * @param {Array<Object>} data raw data
 * @param {Object} columnStandard Object
 * param {* required require} {removeAfterValid}
 */
// chú ý với data[value] là json
export const validFragment = async (data, columnStandard) => {
  try {
    for (let i = 0; i < data.length; i++) {
      data[i] = _.pick(data[i], Object.values(columnStandard));
      // console.log(data[i]);
    }
    return data;
  } catch (error) {
    console.log("[ERROR] function validFragment()", error?.message);
  }
};

export function getDiaChi(record) {
  let result = "";
  if (!record) return "";
  if (!!record.DIA_CHI_CHI_TIET) result += `${record.DIA_CHI_CHI_TIET}`;
  if (!!record.TEN_PHUONG_XA) result += `, ${record.TEN_PHUONG_XA}`;
  if (!!record.TEN_QUAN_HUYEN) result += `, ${record.TEN_QUAN_HUYEN}`;
  if (!!record.TEN_TINH_THANH) result += `, ${record.TEN_TINH_THANH}`;

  return result;
}

export const convertBooleanText = (bool) => {
  if (!_.isNil(bool)) {
    if (bool) {
      return "Có";
    } else {
      return "Không";
    }
  } else {
    return "";
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// lọc dấu
export function locdau(str) {
  if (!str) {
    return "";
  } else {
    str = str.toLocaleLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    return str;
  }
}

export function formatCurrency(num, currency = "", isFull = false) {
  if (!num || num === "") {
    let response = "0 " + currency;
    return response;
  }
  num = Math.round(Number(num));
  if (num === 0) {
    return "0 " + currency;
  }

  if (num.length === 2) {
    if (num === "00") {
      num = num.replace("00", "0");
    }
  }
  if (num.length > 1) {
    let first = num.substring(0, 1);
    if (first === "0") {
      num = num.substring(1, num.length);
    }
  }
  let result = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  if (!isFull) {
    result = result + ".000 ";
  }
  return result + currency;
}

//format định dạng giá khi thao tác ô input giá
export const formatNumberToPrice = (x) => {
  if (!x || x === "" || x === 0) {
    return "";
  }
  x = x.toString();
  x = x.replace(/ /g, "");
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1.$2");
  return x;
};

export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = null;
    }
    if (val != null) {
      // detecting and parsing between comma and dot
      var group = new Intl.NumberFormat("vi-VN").format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat("vi-VN")
        .format(1.1)
        .replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

      // removing everything except the digits and dot
      // reversedVal = reversedVal.replace(/[^0-9.]/g, "");
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }
  } catch (error) {
    console.error(error);
  }
};

export const formatPhoneNumber = (phoneNumber = "") => {
  if (!_.isString(phoneNumber)) return phoneNumber;

  if (phoneNumber.length === 8) {
    return phoneNumber.replace(/\D*(\d{4})\D*(\d{4})\D*/, "$1 $2");
  }

  return phoneNumber.replace(/\D*(\d{4})\D*(\d{3})\D*(\d{3})\D*/, "$1 $2 $3");
};

export function formatDate(date) {
  let result = "";
  if (date) {
    result = moment(date).format("DD/MM/YYYY");
  }
  return result;
}

export const findLink = (string) => {
  if (typeof string === "string") {
    if (string.includes("http")) {
      return `<a href=${string}>` + string + "</a>";
    } else {
      return string;
    }
  } else {
    return string;
  }
};

/**
 *
 * @param {string} path
 * @returns {string}
 */
export const validPath = (path) => {
  try {
    let pathInstance = path;

    if (!pathInstance.includes("http")) {
      pathInstance = pathInstance.replaceAll("//", "/");
    } else {
      let memoOrigin;
      let cpPath = pathInstance.split("://");
      memoOrigin = cpPath.shift();
      cpPath = cpPath.join("://").replaceAll("//", "/");
      pathInstance = [memoOrigin, cpPath].join("://");
    }
    return pathInstance;
  } catch (error) {
    return path;
  }
};

export const handleRedirect = (url) => {
  window.open(url, "_blank");
};

export const getErrorMessage = (
  error_code,
  fallback = `ERROR: ${error_code}`,
  res = {}
) => {
  if (!error_code) return fallback;

  switch (error_code.toString()) {
    case "001":
      return "Giá trị đã tồn tại!";
    case "002":
      return "Không nhập đủ giá trị!";
    case "003":
      return "Giá trị không hợp lệ!";
    case "004":
      return "Giá trị không trùng khớp!";
    case "paid_service":
    case "009":
      return "Không thể xóa phiếu có dịch vụ đã thanh toán";
    case "007":
      return "Không thể xóa/khóa đối tượng này vì có thể ảnh hưởng đến đối tượng khác";
    case "008":
      return "Không sửa được lịch khám do đã tồn tại cuộc hẹn";
    case "012":
      return (
        "Giá trị đã tồn tại!" +
        ` ở cột ${res.field} và giá trị trùng lặp ${res.Dupllicate_label}`
      );
    case "015":
      return "Không thể khóa phòng do có bệnh nhân đã đặt hẹn ";

    case "KHONG_CO_DU_LIEU":
      return "Không có dữ liệu ";
    default:
      return fallback;
  }
};

export const exportToCSV = async (
  csvData = [],
  fileName = "data",
  offsetRow = 0
) => {
  try {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);

    let offColumns = [];

    if (csvData.length > 0) {
      const firstData = csvData[0];
      const valueLength = Object.values(firstData).length;
      offColumns = new Array(valueLength).fill(null).map(() => [""]);
    }

    XLSX.utils.sheet_add_aoa(
      ws,
      new Array(offsetRow).fill(null).map(() => offColumns),
      { origin: 0 }
    );

    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = await XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (error) {
    console.log(`error`, error);
  }
};

export const danhSachLoaiKhoa = [
  {
    value: "KHAM_BENH",
    label: "Khám bệnh",
  },
  {
    value: "NOI_KHOA",
    label: "Nội khoa",
  },
  {
    value: "DUOC",
    label: "Dược",
  },
  {
    value: "TAI_CHINH",
    label: "Tài chính",
  },
  {
    value: "XET_NGHIEM",
    label: "Xét nghiệm",
  },
  {
    value: "CDHA",
    label: "Chẩn đoán hình ảnh",
  },
  {
    value: "NOI_TRU",
    label: "Nội trú",
  },
  {
    value: "KHAC",
    label: "Khác",
  },
];

export const danhSachLoaiPhong = [
  {
    key: "KHAM_BENH",
    name: "Khám bệnh",
  },
  {
    key: "VIEN_PHI",
    name: "Viện phí",
  },
  {
    key: "BHYT",
    name: "Bảo hiểm y tế",
  },
  {
    key: "LAY_MAU",
    name: "Lấy mẫu",
  },
  {
    key: "XET_NGHIEM",
    name: "Xét nghiệm",
  },
  {
    key: "CDHA",
    name: "Chuẩn đoán hình ảnh",
  },
  {
    key: "THU_THUAT",
    name: "Thủ thuật",
  },
  {
    key: "TDCN",
    name: "Thăm dò chức năng",
  },
  {
    key: "CAN_DO",
    name: "Cân đo",
  },
];

export const danhSachNhomBhyt = [
  {
    value: 1,
    MA_NHOM: "KHAM_BENH",
    label: "Khám bệnh",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "Áp dụng cho ngoại trú",
    STT: "1",
    TRANG_THAI: "1",
  },
  {
    value: 2,
    MA_NHOM: "XET_NGHIEM",
    label: "Xét nghiệm",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "2",
    TRANG_THAI: "1",
  },
  {
    value: 3,
    MA_NHOM: "CDHA",
    label: "Chẩn đoán hình ảnh",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "3",
    TRANG_THAI: "1",
  },
  {
    value: 4,
    MA_NHOM: "TDCN",
    label: "Thăm dò chức năng",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "4",
    TRANG_THAI: "1",
  },
  {
    value: 5,
    MA_NHOM: "THUOC",
    label: "Thuốc",
    PHAN_LOAI: "THUOC",
    GHI_CHU: "",
    STT: "5",
    TRANG_THAI: "1",
  },
  {
    value: 6,
    MA_NHOM: "MAU",
    label: "Máu",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "7",
    TRANG_THAI: "1",
  },
  {
    value: 7,
    MA_NHOM: "PHAU_THUAT",
    label: "Phẫu thuật",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "8",
    TRANG_THAI: "1",
  },
  {
    value: 8,
    MA_NHOM: "VTYT",
    label: "Vật tư y tế",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "10",
    TRANG_THAI: "1",
  },
  {
    value: 9,
    MA_NHOM: "VAN_CHUYEN",
    label: "Vận chuyển",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "12",
    TRANG_THAI: "1",
  },
  {
    value: 10,
    MA_NHOM: "GIUONG_NGOAITRU",
    label: "Ngày giường bệnh ban ngày",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "14",
    TRANG_THAI: "1",
  },
  {
    value: 11,
    MA_NHOM: "GIUONG_NOITRU",
    label: "Ngày giường bệnh điều trị nội trú",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "15",
    TRANG_THAI: "1",
  },
  {
    value: 12,
    MA_NHOM: "NGAY_GIUONG_LUU",
    label: "Ngày giường lưu",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "16",
    TRANG_THAI: "1",
  },
  {
    value: 13,
    MA_NHOM: "CHE_PHAM_MAU",
    label: "Chế phẩm máu",
    PHAN_LOAI: "DICH_VU",
    GHI_CHU: "",
    STT: "17",
    TRANG_THAI: "1",
  },
  {
    value: 14,
    MA_NHOM: "THU_THUAT",
    label: "Thủ thuật",
    PHAN_LOAI: "phẫu thuật",
    GHI_CHU: "DICH_VU",
    STT: "",
    TRANG_THAI: "18",
  },
  {
    value: 15,
    MA_NHOM: "COMBO_DV",
    label: "Combo dịch vụ",
    PHAN_LOAI: "COMBO_DV",
    GHI_CHU: "",
    STT: "19",
    TRANG_THAI: "1",
  },
];
export const rid = (length = 5, type) =>
  cryptoRandomString({ length: length, type });


  // hàm đọc file excel
export const readExcel = (file, onOk = () => {}) => {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws);
      resolve(data);
      console.log(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  promise.then((d) => {
    console.log("excel data", d);
    onOk(d);
  });
};

  // hàm đọc file excel
  export const readMultipleExcel = (file, onOk = () => {}) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
  
        const wb = XLSX.read(bufferArray, { type: "buffer" });
  
        const result = {};

        const ws = wb.SheetNames;
        ws.forEach(sheetName => {
          const sheet = wb.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet);
          result[sheetName] = data;
        });

        resolve(result);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  
    promise.then((d) => {
      console.log("excel data", d);
      onOk(d);
    });
  };

export const checkResponse = (rs) => {
  try {
    return (rs && rs.status === 'OK')
  } catch (error) {
    return false
  }
}
// Function bỏ whitespace đầu cuối
export const trimObjectValues = (data) => {
  // Check if the input is an object
  if (typeof data === 'object' && data !== null) {
    let obj = { ...data }
    // Iterate over the object keys
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Check if the value is a string
        if (typeof obj[key] === 'string') {
          // Trim the string value
          obj[key] = obj[key].trim();
        }
        else if (typeof obj[key] === 'object') {
          // Recursively trim values if the value is another object
          obj[key] = trimObjectValues(obj[key]);
        }
      }
    }
    return obj;
  }
  return data;
}


//kiểm tra đối tượng object có rỗng hay không
export function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}

/**
 * 
 * @param {Array<Object>} data 
 * @param {Object} keyData
 * @param {Array<string>} selectList
 */
export const convertKeys = (data,keyData, selectList) => {
  try {
    const convertter = (item) => {
      // let arrValueTemp = Object.values(keyData);
    let keyTransform = {};
      for (const [k,v] of Object.entries(item)) {
        let newK = k.replace('(*)','').trim()
        newK = newK[0].toUpperCase() + newK.substring(1);
        if (newK in keyData) {
          keyTransform[keyData[newK]] = selectList
            ? selectList.includes(newK)
              ? keyData[v] ?? v
              : v
            : keyData[v] ?? v;
        } else {
          keyTransform[newK] = v;
        }
      }
      return keyTransform
    }

    return data.map(i => {
      return convertter(i)
    })
  } catch (error) {
    throw error
  }
}

export const encrypt256 = async (key) => {
  console.log("my key: " + key);
  try {
    const encrypted = await sha256(key);
    return encrypted;
  } catch (error) {
    console.error("cant encrypt key...", error);
    return null;
  }
};

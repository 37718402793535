// import {
//     handleCommonAdd,
//     handleCommonExport,
//     handleCommonGet,
// } from "../commonSaga.js";
// import {
//     dispatchGetNhomDmDoiTuong, dispatchAddNhomDoiTuong, dispatchLockNhomDmDoiTuong, dispatchExportNhomDoiTuong, postLockNguonKhach
// } from "./apiDM_Chung.js";

// import { getDmNhomDoiTuong, addNhomDoiTuong, getDataSuccess, lockDmNhomDoiTuong, exportDmNhomDoiTuong, updateNhomDoiTuong } from "../../slices/DM_Chung/DmNhomDoiTuongNguoiDung.js";
import {put, takeLatest} from "@redux-saga/core/effects";
// import { setFormLoading } from "../../slices/loadingSlice.js";
import { notification } from "antd";
import { handleCommonAdd, handleCommonExport, handleCommonGet } from "../commonSaga/commonSaga";
import { getDataSuccess } from "../../slices/DM_Chung/DmDoiTuongSlice";
import { setFormLoading } from "../../slices/loadingSlice";
import i18n, { languageKeys } from "../../../i18n";
import { getErrorMessage } from "../../../helpers";
import keysCatalog from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/catalogriesProps";
import { addNhomDoiTuong, exportDmNhomDoiTuong, getDmNhomDoiTuong, lockDmNhomDoiTuong, updateNhomDoiTuong } from "../../slices/DM_Chung/DmNhomDoiTuongNguoiDung";
import { dispatchAddNhomDoiTuong, dispatchExportNhomDoiTuong, dispatchGetNhomDmDoiTuong, dispatchLockNhomDmDoiTuong, postLockNguonKhach } from "./apiDM_Chung";
import { errorState } from "../../slices/errorSlice";
// import i18n, {languageKeys} from "../../../i18n";
// import { getErrorMessage } from "../../../helpers.js";
// import keysCatalog from "../../../pages/KeysCatalog/keysCatalog.jsx";
// import { errorState } from "../../slices/errorSlice.js";

export function* watchDoDmNhomDoiTuong() {
    yield takeLatest(getDmNhomDoiTuong, handleGetDmDoiTuong)
    yield takeLatest(addNhomDoiTuong, handleAddDoiTuong)
    yield takeLatest(exportDmNhomDoiTuong, handleExportDmDoiTuong)
    yield takeLatest(lockDmNhomDoiTuong, handleLockDoiTuong)
}

function* handleGetDmDoiTuong(action) {
    const info = action.payload;
    yield handleCommonGet(
        {
            page: info?.page,
            search_string: info?.search_string,
            limit: info?.limit,
            KHOA: info?.KHOA,
        },
        dispatchGetNhomDmDoiTuong,
        getDataSuccess,
        info?.tableLoading,
    )
}

function* handleAddDoiTuong(action) {
    const info = action.payload
    yield handleCommonAdd(
        {
            add: {
                ...info.add.data
            },
            get: info.get
        },
        dispatchAddNhomDoiTuong,
        getDmNhomDoiTuong,
    )
}

function* handleExportDmDoiTuong(action) {
    const info = action.payload
    yield handleCommonExport(
        {
            template: 0,
        },
        dispatchExportNhomDoiTuong,
        info.name,
    )
}

function* handleLockDoiTuong(action) {
    const info = action.payload
    const payload = {
        arr_ID: info.data,
        KHOA: info.lockVal,
        get: info.get
    }
    try {
        yield put(setFormLoading(true))
        const res = yield dispatchLockNhomDmDoiTuong(payload)
        if (!!res) {
            if (res.status === "OK") {
                notification.success({
                    message: i18n.t(languageKeys.cap_nhat_thanh_cong),
                    placement: "bottomLeft",
                });
                payload.get
                    ? yield put(getDmNhomDoiTuong(payload.get))
                    : yield put(updateNhomDoiTuong({
                        isLock: payload.KHOA,
                        id: payload.arr_ID
                    }))

                const subSourceID = res?.result?.DM_NGUON_KHACH_ID;
                yield postLockNguonKhach({
                    arr_ID: subSourceID,
                    KHOA: info.lockVal
                })
            } else {
                let error_msg = getErrorMessage(
                    res.error_code,
                    i18n.t(languageKeys.cap_nhat_that_bai)
                );
                if (!!res.field) {
                    res.field.toUpperCase().includes("DM")
                    ? error_msg += `: ${i18n.t(languageKeys.cate_Danh_muc)} ${i18n.t(keysCatalog()[res.field].i18nKeyLower)}`
                    : error_msg += `: ${i18n.t(keysCatalog()[res.field].i18nKeyLower)}`
                }
                notification.error({
                    message: error_msg,
                    placement: "bottomLeft",
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.cap_nhat_that_bai),
                placement: "bottomLeft",
            })
        }
    } catch (e) {
        yield put(errorState(e.message))
    } finally {
        yield put(setFormLoading(false))
    }
}

import { Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import style from "../../../assets/scss/layout/form.module.scss";
import { danhSachLoaiKhoa, danhSachNhomBhyt } from "../../../helpers";

const FormDMNhomDv = ({ isCreateNew, disabled = false }) => {
  
  return (
    <Space
      direction="vertical"
      size={10}
      style={{ width: "100%" }}
      className={style["form"]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <h3>Thông tin cơ bản</h3>
        <div className={style["wrapper"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={"Mã nhóm"}
                name={"MA_NHOM"}
                rules={[{ whitespace: false }]}
              >
                <Input placeholder={"Nhập"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Tên nhóm"}
                name={"TEN_NHOM"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                <Input placeholder={"Nhập"} disabled={disabled} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={"Nhóm chi phí BHYT"}
                name={"NHOM_BHYT"}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      
                      message: "Vui lòng chọn !",
                    },
                  ]}
                  options={danhSachNhomBhyt}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item label={"Ghi chú"} name={"GHI_CHU"}>
                <Input placeholder={"Nhập"} disabled={disabled} />
              </Form.Item>
            </Col> */}
          </Row>
        </div>
      </Space>
    </Space>
  );
};

export default FormDMNhomDv;

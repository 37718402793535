import Apis from "./apis";
export const PAGE_SIZE_OPTIONS = ["10", "20", "30"];
export const DEFAULT_SIZE = 10;
export const keys = {
  access_token: "access_token",
  refresh_token: "refresh_token",

  DURATION_CLOSE_NOTI: 3,

  limit: 20, // giới hạn số data trả về
  limit_debs: 10,
  maxLimit: 50, //giới hạn tối đa số phần tử gọi api
  limit_hospital_fee: 15,
  limit_min_length: 3, // giới hạn dưới kí tự search
  DEFAULT_PASSWORD: "123312",

};

export const featureKeys = {
  quan_ly_tiep_don: "QL_TIEP_DON",
  quan_ly_danh_muc: "QUAN_LY_DANH_MUC_CHUNG",
  quan_ly_nguoi_dung: "QUAN_LY_NGUOI_DUNG",
  thong_tin_cskcb: "THONG_TIN_CSKCB",
  quan_ly_vien_phi: "QL_VIEN_PHI",
  quan_ly_kham_benh: "QL_KHAM_BENH",
  quan_ly_chan_doan_hinh_anh_ris: "RIS_NHAP_TAY",
  quan_ly_bhyt: "quan_ly_bhyt",
  quan_ly_xep_hang_qms: "quan_ly_xep_hang_qms",
  quan_ly_dich_vu_can_thanh_toan: "DV_CAN_THANH_TOAN",
  quan_ly_so_hoa_don: "QL_SO_TONG_HOP",
  quan_ly_xet_nghiem_lis: "quan_ly_xet_nghiem_lis",
  quan_ly_phong_kham_ve_tinh: "quan_ly_phong_kham_ve_tinh",
  lich_lam_viec: "LICH_LAM_VIEC",
  quan_ly_he_thong: "QL_HE_THONG",
  tiep_don: "DON_TIEP",
  bang_dieu_khien: "BANG_DIEU_KHIEN",
  cuoc_hen_sap_toi: "CUOC_HEN_SAP_TOI",
  duoc_ngoai_tru: "DUOC_NGOAI_TRU",
  ban_thuoc: "BAN_THUOC",
  kho_thuoc: "KHO_THUOC",
  phieu_xuat_nhap: "PHIEU_NHAP_XUAT",
  nha_cung_cap: "NHA_CUNG_CAP",
  quan_ly_noi_tru: "QUAN_LY_NOI_TRU",
  quan_ly_benh_nhan: "QL_BENH_NHAN",
  bao_cao_duoc_ngoai_tru: "BAO_CAO_DUOC_NGOAI_TRU",
  bao_cao_duoc: "BAO_CAO_DUOC",
  bao_cao: "QL_BAO_CAO",
  bao_cao_vien_phi: "BAO_CAO_VIEN_PHI",
  bao_cao_phieu_thu: "BAO_CAO_PHIEU_THU",
  bao_cao_tiep_don: "BAO_CAO_TIEP_DON",
  ton_kho: "TON_KHO",
  kho_duoc_ngoai_tru: "KHO_THUOC",
  quan_ly_xuat_khac: "QUAN_LY_XUAT_KHAC",
  thong_ke: "THONG_KE",
  so_luong_ton_kho: "SO_LUONG_TON_KHO",
  phieu_nhap_kho: "PHIEU_NHAP_KHO",
  phieu_xuat_kho: "PHIEU_XUAT_KHO",
  van_chuyen_kho: "VAN_CHUYEN_KHO",
  the_kho: "THE_KHO",
  quyen_duyet_phieu: "QUYEN_DUYET_PHIEU",
  mau_mo_ta_cdha: "MAU_MO_TA_CDHA",
  tra_ket_qua_cdha: "TRA_KET_QUA_CDHA",
  tao_mau_mo_ta_cdha: "TAO_MAU_MO_TA_CDHA",
  sua_mau_mo_ta_cdha: "SUA_MAU_MO_TA_CDHA",
  xoa_mau_mo_ta_cdha: "XOA_MAU_MO_TA_CDHA",
  khoa_mau_mo_ta_cdha: "KHOA_MAU_MO_TA_CDHA",
  tra_ket_qua: "TRA_KET_QUA",
  huy_tra_phieu_cdha: "HUY_TRA_PHIEU",
  ho_tro_khach_hang: "HO_TRO_KHACH_HANG",
  bao_cao_cdha: "BAO_CAO_CDHA",
  quan_ly_phan_he: "QL_PHAN_HE",

  quan_ly_dv_cis: "QUAN_LY_DV_CIS",
  may_thuc_hien_ris: "MAY_THUC_HIEN_RIS",
  mau_mo_ta_ris: "MAU_MO_TA_RIS",
  thiet_bi_may_lis: "THIET_BI_MAY_LIS",
  du_lieu_dk_may_lis: "DU_LIEU_DIEU_KHIEN_MAY_LIS",
  quan_ly_dm_cis: "QUAN_LY_DANH_MUC_CIS",
  quan_ly_dv_lis: "QUAN_LY_DV_LIS",
  loai_benh_pham_lis: "LOAI_BENH_PHAM_LIS",
  quan_ly_doi_tac: "QUAN_LY_DANH_MUC_DOI_TAC",
  cau_hinh_ket_noi: "CAU_HINH_KET_NOI",

  quan_ly_ket_noi: "QUAN_LY_KET_NOI",
  ket_noi_zalo_oa: "KET_NOI_ZALO_OA",
};

export const staffGroup = {
  THU_KHO: "THU_KHO",
  THU_NGAN: "THU_NGAN",
  TIEP_TAN: "TIEP_TAN",
  KY_THUAT_VIEN_XN: "KY_THUAT_VIEN_XN",
  KY_THUAT_VIEN_CDHA: "KY_THUAT_VIEN_CDHA",
  DIEU_DUONG: "DIEU_DUONG",
  DUOC_SI: "DUOC_SI",
  SALE: "SALE",
  TELESALES: "TELESALES",
  CSKH: "CSKH",
  CSKH_LEADER: "CSKH_LEADER",
  MKT_LEADER: "MKT_LEADER",
  MARKETING: "MARKETING",
  BAC_SI: "BAC_SI",
  QUAN_TRI: "QUAN_TRI",
};

export const decentralization = {
  CIS: "CIS",
  LIS: "LIS",
  RIS: "RIS",
  PRM: "PRM",
  DUOC_NGOAI_TRU: "DUOC",
  BAO_CAO: "BAO_CAO",
  DANH_MUC: "MANAGEMENT",
  APP_MOBICARE: "APP_MOBICARE",
  AI: "AI",
  VAT_TU: "VAT_TU",
  KHAM_DOAN: "KHAM_DOAN",
};

export const staffGroupPermission = {
  [staffGroup.THU_KHO]: [
    decentralization.DUOC_NGOAI_TRU,
    decentralization.DANH_MUC,
    decentralization.BAO_CAO,
  ],
  [staffGroup.THU_NGAN]: [
    decentralization.CIS,
    decentralization.DANH_MUC,
    decentralization.BAO_CAO,
  ],
  [staffGroup.TIEP_TAN]: [
    decentralization.CIS,
    decentralization.DANH_MUC,
    decentralization.BAO_CAO,
  ],
  [staffGroup.KY_THUAT_VIEN_XN]: [
    decentralization.LIS,
    decentralization.DANH_MUC,
    decentralization.BAO_CAO,
  ],
  [staffGroup.KY_THUAT_VIEN_CDHA]: [
    decentralization.RIS,
    decentralization.DANH_MUC,
    decentralization.BAO_CAO,
  ],
  [staffGroup.DIEU_DUONG]: [decentralization.CIS, decentralization.DANH_MUC],
  [staffGroup.DUOC_SI]: [
    decentralization.DUOC_NGOAI_TRU,
    decentralization.DANH_MUC,
    decentralization.BAO_CAO,
  ],
  [staffGroup.SALE]: [decentralization.PRM, decentralization.DANH_MUC],
  [staffGroup.TELESALES]: [decentralization.PRM, decentralization.DANH_MUC],
  [staffGroup.CSKH]: [decentralization.PRM, decentralization.DANH_MUC],
  [staffGroup.CSKH_LEADER]: [decentralization.PRM, decentralization.DANH_MUC],
  [staffGroup.MKT_LEADER]: [decentralization.PRM, decentralization.DANH_MUC],
  [staffGroup.MARKETING]: [decentralization.PRM, decentralization.DANH_MUC],
  [staffGroup.BAC_SI]: [decentralization.CIS, decentralization.DANH_MUC],
};

export { Apis };

export const keyLoaiNhanVien = {
  bac_si: "BAC_SI",
  thu_ngan: "THU_NGAN",
  ky_thuat_vien: "KY_THUAT_VIEN",
  dieu_duong: "DIEU_DUONG",
  thu_kho: "THU_KHO",
  quan_tri: "QUAN_TRI",
  tiep_tan: "TIEP_TAN",
  nguoi_lay_mau: "NGUOI_LAY_MAU",
};

import { Col, Form, Input, Row, Select, Space } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import style from "../../../assets/scss/layout/form.module.scss";
import SelectCustom from "../../../components/Select/Select";
import { dispatchGetListDmLoaiDvHL } from "../../../duck/saga/DM_Chung/apiDM_Chung";
import { danhSachNhomBhyt } from "../../../helpers";
import { fieldNhomDv } from "../../ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog";

const FormDMLoaiDv = ({ isCreateNew, disabled = false, form }) => {

  // const { data: DsNhomDv } = useSelector(DataDmNhomDv);
  const [DsNhomDv, setDsNhomDv] = useState([])

  const dataHos = useSelector(
    (state) => state.hospitalInformation.hospitalInfo
  );


  const fetchDsKhoa = async(searchString = "") => {
    try {
      const res = await dispatchGetListDmLoaiDvHL({
        page: 1,
        search_string: searchString,
        limit: 40,
        KHOA: false,
        partner_code: dataHos?.["partner_code"] || "",
        BENH_VIEN_ID: dataHos?.["BENH_VIEN_ID"] || "",
      })
      if (res && res?.status =="OK") {
        setDsNhomDv(res?.result || [])
      }else{
        setDsNhomDv([])
      }

    } catch (error) {
      setDsNhomDv([])
    }
  
   
  };

  const searchKhoa = useCallback(
    debounce((value) => fetchDsKhoa(value), 1000),
    []
  );

  
  return (
    <Space
      direction="vertical"
      size={10}
      style={{ width: "100%" }}
      className={style["form"]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <h3>Thông tin cơ bản</h3>
        <div className={style["wrapper"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={"Mã loại dịch vụ"}
                name={"MA_LOAI"}
                rules={[{ whitespace: false }]}
              >
                <Input placeholder={"Nhập"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Tên loại dịch vụ"}
                name={"TEN_LOAI"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                <Input placeholder={"Nhập"} disabled={disabled} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={"Nhóm dịch vụ"}
                name={"NHOM_DV_ID"}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                 <SelectCustom
                  placeholder={"Vui lòng Chọn"}
                  dataSource={DsNhomDv || []}
                  valueKey={fieldNhomDv.id}
                  titleKey={fieldNhomDv.ten_nhom}
                  showSearch
                  onSearch={searchKhoa}
                  loading={false}
                  onDropdownVisibleChange={(bool) => bool && fetchDsKhoa()}
                  // onSelect={handleSelect}
                  disabled={disabled}
                />
              </Form.Item>
            </Col><Col span={12}>
              <Form.Item
                label={"Nhóm chi phí BHYT"}
                name={"NHOM_BHYT"}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập !",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      
                      message: "Vui lòng chọn !",
                    },
                  ]}
                  options={danhSachNhomBhyt}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item label={"Ghi chú"} name={"GHI_CHU"}>
                <Input placeholder={"Nhập"} disabled={disabled} />
              </Form.Item>
            </Col> */}
          </Row>
        </div>
      </Space>
    </Space>
  );
};

export default FormDMLoaiDv;

import { createSlice } from "@reduxjs/toolkit";

const initState = {
  tableLoading: false,
  formLoading: false,
  selectLoading: false,
  siderLoading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: initState,
  reducers: {
    setTableLoading: (state, action) => {
      state.tableLoading = action.payload;
    },
    setFormLoading: (state, action) => {
      state.formLoading = action.payload;
    },
    setSelectLoading: (state, action) => {
      state.selectLoading = action.payload;
    },
    setSiderLoading: (state, action) => {
      state.siderLoading = action.payload;
    },
  },
});

export const {
  setTableLoading,
  setFormLoading,
  setSelectLoading,
  setSiderLoading,
} = loadingSlice.actions;
export const tableLoadingState = (state) => state.loading.tableLoading;
export const formLoadingState = (state) => state.loading.formLoading;
export const selectLoadingState = (state) => state.loading.selectLoading;
export const siderLoadingState = (state) => state.loading.siderLoading;
export default loadingSlice.reducer;

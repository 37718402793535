import { ArrowRightOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Space, TimePicker } from "antd";
import { debounce } from "lodash";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DataDmKhoa,
  doGetListDmKhoa,
} from "../../../duck/slices/DM_Chung/DmKhoa";
import { danhSachLoaiPhong } from "../../../helpers";
import style from "../../../assets/scss/layout/form.module.scss";
import {
  fieldKhoa,
  fieldPhong,
} from "../../ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog";
import SelectCustom from "../../../components/Select/Select";

const FormDMPhong = ({ isCreateNew, form, disabled = false }) => {
  const { data: DsKhoa } = useSelector(DataDmKhoa);

  const dataHos = useSelector(
    (state) => state.hospitalInformation.hospitalInfo
  );
  const dispatch = useDispatch();

  const fetchDsKhoa = (searchString = "") => {
    dispatch(
      doGetListDmKhoa({
        page: 1,
        search_string: searchString,
        limit: 40,
        KHOA: true,
        partner_code: dataHos?.["partner_code"] || "",
        BENH_VIEN_ID: dataHos?.["BENH_VIEN_ID"] || "",
      })
    );
  };

  const onChangeTime = (value, type = "start") => {
    if (value) {
      value = value.format("HH:MM");
      if (type === "start") {
        let timeEnd = !!form.getFieldValue(fieldPhong.gio_ket_thuc)
          ? form.getFieldValue(fieldPhong.gio_ket_thuc).format("HH:MM")
          : "";
        form.setFieldsValue({
          [`${fieldPhong.gio_lam_viec}`]: value + " - " + timeEnd,
        });
      } else {
        let timeStart = !!form.getFieldValue(fieldPhong.gio_bat_dau)
          ? form.getFieldValue(fieldPhong.gio_bat_dau).format("HH:MM")
          : "";
        form.setFieldsValue({
          [`${fieldPhong.gio_lam_viec}`]: timeStart + " - " + value,
        });
      }
    }
  };

  const searchKhoa = useCallback(
    debounce((value) => fetchDsKhoa(value), 1000),
    []
  );

  const handleSelect = (id) => {
    form.setFields([
      {
        name: fieldPhong.khoa,
        value: id,
      },
    ]);
  };
  return (
    <Space
      direction="vertical"
      size={10}
      style={{ width: "100%" }}
      className={style["form"]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <h3>Thông tin cơ bản</h3>
        <div className={style["wrapper"]}>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                label={"Mã phòng"}
                name={fieldPhong.ma_phong}
                rules={[{ whitespace: true }]}
              >
                <Input placeholder={"Nhập"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Tên phòng"}
                name={fieldPhong.ten_phong}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Vui lòng nhập",
                  },
                  {
                    max: 30,
                    message: "Kí tự quá nhiều",
                  },
                ]}
              >
                <Input placeholder={"Nhập"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Khoa"}
                name={fieldPhong.ten_khoa}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng Chọn",
                  },
                ]}
              >
                <SelectCustom
                  placeholder={"Vui lòng Chọn"}
                  dataSource={DsKhoa?.result || []}
                  valueKey={fieldKhoa.id}
                  titleKey={fieldKhoa.ten_khoa}
                  showSearch
                  onSearch={searchKhoa}
                  loading={false}
                  onDropdownVisibleChange={(bool) => bool && fetchDsKhoa()}
                  onSelect={handleSelect}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Loại phòng"}
                name={fieldPhong.loai_phong}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng Chọn",
                  },
                ]}
              >
                <SelectCustom
                  placeholder={"Chọn"}
                  dataSource={danhSachLoaiPhong}
                  valueKey="key"
                  titleKey="name"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Thời gian làm việc"}
                name={fieldPhong.gio_lam_viec}
              >
                <Row align="middle" className={style["container"]}>
                  <Col span={10}>
                    <Form.Item name={fieldPhong.gio_bat_dau}>
                      <TimePicker
                        format="HH:mm"
                        placeholder={"Giờ bắt đầu"}
                        className={style["time-picker"]}
                        suffixIcon={null}
                        minuteStep={5}
                        onChange={(value) => onChangeTime(value, "start")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className={style["addon-container"]}>
                    <ArrowRightOutlined
                      style={{ color: "#999999", fontSize: "15px" }}
                    />
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      dependencies={[fieldPhong.gio_bat_dau]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            console.log(
                              value,
                              getFieldValue(fieldPhong.gio_bat_dau)
                            );
                            if (
                              !value ||
                              !getFieldValue(fieldPhong.gio_bat_dau) ||
                              Number(
                                getFieldValue(fieldPhong.gio_bat_dau).format(
                                  "HHMM"
                                )
                              ) < Number(value.format("HHMM"))
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Thời gian không hợp lệ")
                            );
                          },
                        }),
                      ]}
                      name={fieldPhong.gio_ket_thuc}
                    >
                      <TimePicker
                        format="HH:mm"
                        placeholder={"Giờ kết thúc"}
                        className={style["time-picker"]}
                        suffixIcon={null}
                        minuteStep={5}
                        onChange={(value) => onChangeTime(value, "end")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className={style["addon-container"]}>
                    <ClockCircleOutlined
                      style={{ color: "#999999", fontSize: "15px" }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <h3>Ghi chú</h3>
        <Form.Item name={fieldPhong.ghi_chu}>
          <Input.TextArea placeholder={"Nhập"} />
        </Form.Item>
      </Space>
    </Space>
  );
};

export default FormDMPhong;

import { notification as notiAntd } from "antd";

import { keys } from "../constants";
import i18n, { languageKeys } from "../i18n";

const SUCCESS_ADD_MSG = i18n.t(languageKeys.them_moi_thanh_cong);
const SUCCESS_EDIT_MSG = i18n.t(languageKeys.cap_nhat_thanh_cong);
const SUCCESS_DELETE_MSG = i18n.t(languageKeys.noti_Xoa_thanh_cong);
const SUCCESS_DEFAULT_MSG = i18n.t(languageKeys.thao_tac_thanh_cong);

const ERROR_ADD_MSG = i18n.t(languageKeys.them_moi_that_bai);
const ERROR_EDIT_MSG = i18n.t(languageKeys.thao_tac_that_bai);
const ERROR_DELETE_MSG = i18n.t(languageKeys.noti_Xoa_that_bai);
const ERROR_DEFAULT_MSG = i18n.t(languageKeys.thao_tac_that_bai);

export const notification = {
  // default
  success: (message = SUCCESS_DEFAULT_MSG) =>
    notiAntd.success({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message }),
  error: (message = ERROR_DEFAULT_MSG) =>
    notiAntd.error({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message }),

  // add new
  success_add_new: () =>
    notiAntd.success({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message: SUCCESS_ADD_MSG }),
  error_add_new: () =>
    notiAntd.error({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message: ERROR_ADD_MSG }),

  // edit
  success_edit: () =>
    notiAntd.success({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message: SUCCESS_EDIT_MSG }),
  error_edit: () =>
    notiAntd.error({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message: ERROR_EDIT_MSG }),

  // delete
  success_delete: () =>
    notiAntd.success({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message: SUCCESS_DELETE_MSG }),
  error_delete: () =>
    notiAntd.error({ placement: "bottomLeft", duration: keys.DURATION_CLOSE_NOTI, message: ERROR_DELETE_MSG }),
};

import {createSlice} from "@reduxjs/toolkit";

const initState = {
    data: [],
    currentPage: 1,
    totalResult : 0,
};

const DmNhomDoiTuongSlice = createSlice({
    name: "DmNhomDoiTuong",
    initialState: initState,
    reducers: {
        getDmNhomDoiTuong: () => {},
        getDataSuccess: (state, action) => {
            const {current_page, result, total} = action.payload;
            console.log("🚀 ~ DmNhomDoiTuongSlice ~ getDataSuccess ~ result:", result);
            state.data = result
            state.currentPage = current_page
            state.totalResult = total
        },
        addNhomDoiTuong: () => {},
        // editNhomDoiTuong: () => {},
        // deleteNhomDoiTuong: () => {},
        exportDmNhomDoiTuong: () => {},
        lockDmNhomDoiTuong: () => {},
        updateNhomDoiTuong: (state, action) => {
            const updateLock = state.data.findIndex((id) => id.ID === Number(action.payload.id ) )
            state.data[updateLock].KHOA = action.payload.isLock
        },
        // importDmNhomDoiTuong: () => {}
    },
});

export const {getDmNhomDoiTuong, getDataSuccess, addNhomDoiTuong, exportDmNhomDoiTuong, lockDmNhomDoiTuong, updateNhomDoiTuong} = DmNhomDoiTuongSlice.actions
export const DmNhomDoiTuongData = (state) => state.DM_Chung.DmNhomDoiTuongNguoiDungSlice
export default DmNhomDoiTuongSlice.reducer;
